import React, { useEffect, useMemo, useState } from "react";

import AuthCode from "react-auth-code-input";

import { useForm } from "react-hook-form";
import { useAuth } from "../../../../providers/auth";
import {
  sendVerificationCode,
  verifyCode,
} from "../../../../callable-cloud-functions/cloudFunctions";
import { friendlyFirebaseError } from "../../../../providers/firebase-errors/friendlyFirebaseError";
import toast from "react-hot-toast";
import ToastAlert from "../../../../components/custom-ui/toast-alert/ToastAlert";

function EmployerRegistrationStepThree({
  onDone,
  employerInfo,
}: {
  onDone: Function;
  employerInfo: any;
}) {
  const auth = useAuth();
  const [verificationCode, setVerificationCode] = useState();
  const [timer, setTimer] = useState(0); // Timer state
  const [codeError, setCodeError] = useState(false);
  const [isCodeComplete, setIsCodeComplete] = useState(false);
  const { user } = auth;
  async function sendEmailVerfication(val: any) {
    try {
      await sendVerificationCode({ email: val });
      setTimer(60);
      toast.success("Send verification code to your mail");
    } catch (verificationError: any) {
      toast.error("Failed send verification code to your mail");
      console.error(
        "Error sending email verification code:",
        verificationError,
      );
      throw new Error(
        friendlyFirebaseError(verificationError) ||
          "Failed to send email verification code.",
      );
    }
  }

  async function sendEmailVerficationN(val: any) {
    try {
      await sendVerificationCode({ email: val });
    } catch (verificationError: any) {
      toast.error("Failed send verification code to your mail");
      console.error(
        "Error sending email verification code:",
        verificationError,
      );
      throw new Error(
        friendlyFirebaseError(verificationError) ||
          "Failed to send email verification code.",
      );
    }
  }

  useEffect(() => {
    sendEmailVerficationN(employerInfo?.email);
  }, [employerInfo?.email]);

  useEffect(() => {
    // Timer countdown logic
    if (timer > 0) {
      const countdown = setInterval(() => {
        setTimer((prevTimer: any) => prevTimer - 1);
      }, 1000);

      return () => clearInterval(countdown); // Cleanup the interval on component unmount
    }
  }, [timer]);

  const [pending, setPending] = useState(false);
  const { handleSubmit, register, setValue, errors } = useForm();

  async function VerificationCodeFn(verificationCode: any) {
    setCodeError(false);
    setPending(true);

    try {
      const response: any = await verifyCode({
        email: employerInfo.email,
        code: verificationCode?.verificationCode,
        userId: user.id,
      });

      // Check if the response indicates failure
      if (response?.data?.success === false) {
        setCodeError(true);
        toast.error(response?.data?.message || "Failed to verify code.");
        //alert(response?.data?.message || "Failed to verify code.");
      } else {
        onDone(employerInfo);
        localStorage.removeItem("employerEmail");
        toast.success(response?.data?.message || "Failed to verify code.");
      }
    } catch (error: any) {
      toast.error(error.message || "Failed to verify code.");
      setCodeError(true);
    } finally {
      setPending(false);
      //setCodeError(false);
    }
  }

  const handleCodeChange = (code: any) => {
    setValue("verificationCode", code);
    setVerificationCode(code);
    setIsCodeComplete(code.length === 6); // Assuming the code is 6 digits long
  };

  return (
    <>
      <form onSubmit={handleSubmit(VerificationCodeFn)}>
        <h2 className="text-2xl font-bold text-center mb-10 text-[#15415E]">
          Enter Verification Code
        </h2>
        <p className="text-md font-bold text-center mb-2 text-[#A5BDC5]">
          We've sent a verification code to
        </p>
        <p className="text-xl font-bold text-center mb-2 text-[#15415E]">
          {employerInfo?.email}
        </p>
        <div className="flex justify-center h-50 my-20">
          <input
            className="py-1 px-3 w-full leading-8 bg-white rounded border border-gray-300 outline-none focus:border-blue-500 focus:ring-1"
            name="verificationCode"
            type="hidden"
            inputMode="numeric"
            autoComplete="one-time-code"
            ref={register({
              required: "Please enter your verification code",
            })}
          />
          <AuthCode
            containerClassName="flex flex-row"
            inputClassName={`shadow-none m-1 w-14 border-solid border-r-0 border-l-0 border-t-0 border-b-3 ${
              codeError ? "border-red-600" : "border-[#15415E]"
            }`} // Conditional red highlight on error
            //inputClassName="shadow-none m-1 w-14 border-solid border-r-0 border-l-0 border-t-0 border-b-3 border-[#15415E]"
            alphanumeric="numeric"
            ariaLabel="mobile confirmation code"
            // sets code to hidden field "verificationCode"
            onChange={handleCodeChange}
            //onChange={(code: any) => setValue("verificationCode", code)}
          />
        </div>
        <div className="flex justify-center">
          <button
            type="submit"
            className={`py-2 px-8 text-white font-medium rounded-2xl mb-5 focus:outline-none focus:ring-2 ${
              isCodeComplete ? "bg-[#15415E]" : "bg-[#F4F7F8] text-[#15415E]"
            }`}
            style={{ width: "250px" }}
            disabled={pending}
          >
            {pending ? (
              <div className="flex items-center justify-center space-x-2">
                <svg
                  className="animate-spin h-5 w-5 text-white"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <circle
                    className="opacity-25"
                    cx="12"
                    cy="12"
                    r="10"
                    stroke="currentColor"
                    strokeWidth="4"
                  ></circle>
                  <path
                    className="opacity-75"
                    fill="currentColor"
                    d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4z"
                  ></path>
                </svg>
              </div>
            ) : (
              "Confirm Verification Code"
            )}
          </button>
        </div>
      </form>
      {/* Resend Confirmation Code */}
      <div className="flex justify-center">
        <button
          onClick={() => sendEmailVerfication(employerInfo?.email)}
          className="inline-block align-baseline justify-center outline-none mr-1 mb-1 px-6 py-2 bg-transparent text-md font-bold text-instant-teams-blue-Main focus:outline-none"
          type="button"
          disabled={timer > 0}
        >
          {timer > 0 ? (
            `Resend in ${timer}s`
          ) : (
            <>Did not receive verification code? Resend</>
          )}
        </button>
      </div>

      <ToastAlert />
    </>
  );
}

export default EmployerRegistrationStepThree;
