import React, { useEffect, useMemo, useState } from "react";

import AuthCode from "react-auth-code-input";

import { useForm } from "react-hook-form";
import { useAuth } from "../../../../providers/auth";
import { friendlyFirebaseError } from "../../../../providers/firebase-errors/friendlyFirebaseError";
import toast from "react-hot-toast";
import ToastAlert from "../../../../components/custom-ui/toast-alert/ToastAlert";
import BuySubscription from "../Components/BuySubscription";
import EmployerTermsPage from "../Components/EmployerTermsPage";
import BillingPage from "../Components/BillingPage";

function EmployerRegistrationStepFive({
  onDone,
  employerInfo,
}: {
  onDone: Function;
  employerInfo: any;
}) {
  const auth = useAuth();
  const [currentPlan, setCurrentPlan] = useState("");
  const [renderView, setRenderView] = useState();
  const handleContinue = (selection: string) => {
    setCurrentPlan(selection);
    setRenderView(1)
    // onDone(slection);
  };

  const handleTerms = (data: any) => {
    setRenderView(2)
    // onDone(slection);
  };

  const handleBilling = (data: any) => {
    onDone(data);
  }

  const renderSteps = (activeStep: any) => {
    switch (activeStep) {
      case 1:
        return (
          <div className="w-full flex flex-row justify-start my-10">
            <EmployerTermsPage onContinue={handleTerms} currentPlan={currentPlan} employerInfo={employerInfo} />
          </div>
        );
      case 2:
        return (
          <div className="w-full flex flex-row justify-start my-10">
            <BillingPage onContinue={handleBilling} currentPlan={currentPlan} employerInfo={employerInfo} />
          </div>
        );
      default :
        return (
          <div className="w-full flex flex-row justify-start my-10">
            <BuySubscription onContinue={handleContinue} isSignup={true} />
          </div>
        );
    }
  }

  return (
    <>{renderSteps(renderView)}</>
  );
}

export default EmployerRegistrationStepFive;
