// @ts-nocheck
import React from "react";

interface SemiCircularProgressProps {
  usedCredits: number;
  totalCredits: number;
  scale?: number;
}

const radius = 50;
const strokeWidth = 13;
const cx = 60; // Center x-coordinate for SVG
const cy = 60; // Center y-coordinate for SVG
const circumference = Math.PI * radius; // Circumference of the semicircle

const SemiCircularProgress: React.FC<SemiCircularProgressProps> = ({
  usedCredits,
  totalCredits,
  scale = 2,
}) => {
  const progress = Math.min((usedCredits / totalCredits) * 100, 100); // Clamping to 100%
  const strokeDashoffset = circumference * (1 - progress / 100); // Adjust offset for progress

  return (
    <div
      style={{
        position: "relative",
        width: "120px",
        height: "68px",
        transform: `scale(${scale})`,
      }}
    >
      <svg
        width="120"
        height="68"
        viewBox="0 0 120 68"
        preserveAspectRatio="xMidYMid meet"
      >
        <defs>
          <linearGradient id="grad" x1="0" y1="0" x2="100%" y2="0">
            <stop offset="0%" stopColor="#0B619A" />
            <stop offset="100%" stopColor="#4DAAE8" />
          </linearGradient>
        </defs>

        {/* Background semicircle with rounded ends */}
        <path
          d={`M ${cx - radius}, ${cy} A ${radius} ${radius} 0 0 1 ${
            cx + radius
          } ${cy}`}
          stroke="#E2E2E8"
          fill="none"
          strokeWidth={strokeWidth}
          strokeLinecap="round" // Rounded line cap for background
        />

        {/* Progress semicircle with rounded ends */}
        <path
          d={`M ${cx - radius}, ${cy} A ${radius} ${radius} 0 0 1 ${
            cx + radius
          } ${cy}`}
          stroke="url(#grad)"
          fill="none"
          strokeWidth={strokeWidth}
          strokeDasharray={`${circumference} ${circumference}`} // Ensuring correct dash length
          strokeDashoffset={strokeDashoffset || 0} // Adjust offset for progress
          strokeLinecap="round" // Rounded line cap for progress
        />
      </svg>

      {/* Centered text */}
      <div
        style={{
          width: "120px",
          textAlign: "center",
          position: "absolute",
          left: "50%",
          top: "70%",
          transform: "translate(-50%, -40%)",
        }}
      >
        <span
          style={{
            color: "#15415E",
            fontFamily: "Gilroy-Bold",
            fontSize: "36px",
            lineHeight: "42px",
          }}
        >
          {usedCredits}
        </span>
        <div
          style={{
            fontFamily: "Gilroy-Medium",
            fontSize: "10px",
            lineHeight: "12.2px",
            color: "#15415E",
          }}
        >
          Used Credits
        </div>
      </div>
    </div>
  );
};

export default SemiCircularProgress;
