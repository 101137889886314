import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { useAuth } from "../../../providers/auth";
import ButtonWrapper from "../../employer/details/New_Ui/Component-UI/Button";
import { updateUser } from "../../../data/user";

function EditProfileEmp_newUI(props) {
  const auth = useAuth();
  const [pending, setPending] = useState(false);

  const { register, handleSubmit, errors, reset, getValues } = useForm({defaultValues: {
    name: props?.userData?.name || "",
    title:props?.userData?.title || "",
  },});

  const onSubmit = (data) => {
  setPending(true)
    updateUser(auth?.user?.id, { name: data.name, title: data.title }).then(() => {
      setPending(false)
      props?.setOpenEditProfileModal(false)
    })
  
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="mb-6">
      <div className="">
        <div className="relative z-0">
          <input
            name="name"
            type="text"
            ref={register({
              required: "Please enter a full name",
            })}
            id="floating_password"
            className="block py-2.5 outline-none focus:ring-0 focus:outline-none px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 shadow-none border-gray-300 appearance-none dark:text-white dark:border-gray-600 peer"
            placeholder=" "
          />
          <label
            htmlFor="floating_password"
            className="absolute text-sm text-instant-teams-blue-Main dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 peer-focus:text-instant-teams-blue-Main peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto"
          >
            Full Name
          </label>
          <hr className="bg-gray-500" />
        </div>
        {errors.pass && (
          <p className="mt-1 text-sm text-left text-red-600">
            {errors.pass.message}
          </p>
        )}
      </div>
      <div className="mt-3">
        <div className="relative z-0">
          <input
            name="title"
            type="text"
            ref={register({
              required: "Please enter a title",
            })}
            id="floating_confirm_password"
            className="block py-2.5 outline-none focus:ring-0 focus:outline-none px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 shadow-none border-gray-300 appearance-none dark:text-white dark:border-gray-600 peer"
            placeholder=" "
          />
          <label
            htmlFor="floating_confirm_password"
            className="absolute text-sm text-instant-teams-blue-Main dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 peer-focus:text-instant-teams-blue-Main peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto"
          >
           Title
          </label>
          <hr className="bg-gray-500" />
        </div>
        {errors.confirmPass && (
          <p className="mt-1 text-sm text-left text-red-600">
            {errors.confirmPass.message}
          </p>
        )}
      </div>
      <div className="w-full mt-8 flex justify-center items-center">
      <ButtonWrapper
        className="py-2 px-4 mt-9 w-full text-xl uppercase justify-center mx-auto text-white bg-instant-teams-blue-Main rounded-lg border-0 hover:bg-instant-teams-blue-D1 focus:outline-none"
        type="submit"
        disabled={pending}
      >
        {pending ? "..." : "Save"}
      </ButtonWrapper>
      </div>
      
    </form>
  );
}

export default EditProfileEmp_newUI;
