import React, { useEffect, useMemo, useState } from "react";
import AuthCode from "react-auth-code-input";
import "react-phone-number-input/style.css";
import PhoneInputWithCountry from "react-phone-number-input/react-hook-form";
import ButtonWithLoading from "../../custom-ui/button/ButtonWithLoading";
import ForwardArrowButton from "../../custom-ui/button/ForwardArrowButton";
import {
  PhoneAuthProvider,
  PhoneMultiFactorGenerator,
  multiFactor,
} from "firebase/auth";
import { useForm } from "react-hook-form";
import { useAuth } from "../../../providers/auth";
import { friendlyFirebaseError } from "../../../providers/firebase-errors/friendlyFirebaseError";
import { errorLogger } from "../../../providers/clientLogger";
import ButtonWithLoading_empNewUi from "../../custom-ui/button/ButtonWithLoading_EmpNewui";

function MultiFactorEnrollment_empNewUI({
  onStatus,
  callback,
  buttonVariant,
}: {
  onStatus: Function;
  callback: Function;
  buttonVariant?: string;
}) {
  const auth = useAuth();



  const { getMultifactorSession, makePhoneAuthProvider, makeRecaptcha, user } =
    auth;

  const phoneAuthProvider = makePhoneAuthProvider();

  const [pending, setPending] = useState(false);
  const { handleSubmit, register, errors, setValue, getValues, control } =
    useForm();

  useEffect(() => {
    if (user.phone) {
      setValue("mobilePhone", user.phone);
    }
  }, [user?.phone, setValue]);

  const [recaptchaVerifier, setRecaptchaVerifier] = useState();
  const [multifactorSession, setMultifactorSession] = useState();
  const [verificationId, setVerificationId] = useState();

  // keep phone number around in case user needs to ask for verification code again
  const [phoneNumber, setPhoneNumber] = useState();

  const recaptchaConfig = useMemo(
    () => ({
      size: "invisible",
      callback: function (response: any) { },
    }),
    [],
  );

  useEffect(() => {
    // if recaptcha verifier already exists dont try to re-render.
    if (!recaptchaVerifier) {
      setRecaptchaVerifier(
        makeRecaptcha("multifactor-enrollment", recaptchaConfig),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [recaptchaConfig]);

  useEffect(() => {
    const getSession = async () => {
      const session = await getMultifactorSession(user.auth.currentUser);
      setMultifactorSession(session);
    };
    getSession();
  }, [user.auth.currentUser]);

  const getVerificationCode = ({ mobilePhone }: { mobilePhone: string }) => {
    setPending(true);

    setPhoneNumber(mobilePhone); // saving phone number in state in case user re-requests verification code.

    const phoneInfoOptions = {
      phoneNumber: mobilePhone,
      session: multifactorSession,
    };

    phoneAuthProvider
      .verifyPhoneNumber(phoneInfoOptions, recaptchaVerifier)
      .then((verificationId: string) => setVerificationId(verificationId))
      .catch((error: any) => {
        onStatus({
          type: "error",
          code: error.code,
          callback: () => { },
          message: `${friendlyFirebaseError(error)}`,
        });

        errorLogger(error.toString(), {
          component: "MultifactorEnrollment getVerificationCode()",
          flow: "MultiFactor Enrollment Flow",
          userId: user.id,
        });
      })
      .finally(() => setPending(false));
  };

  const sendVerificationCode = ({
    verificationCode,
  }: {
    verificationCode: string;
  }) => {
    setPending(true);
    const credential = PhoneAuthProvider.credential(
      verificationId,
      verificationCode,
    );
    const multiFactorAssertion =
      PhoneMultiFactorGenerator.assertion(credential);
    // multifacor docs: https://firebase.google.com/docs/reference/js/auth.md#multifactor
    multiFactor(user.auth.currentUser)
      .enroll(multiFactorAssertion, "mobile phone number")
      .then((response) => {
        onStatus({
          type: "success",
          message: "Your multi-factor settings have been updated",
        });
        callback();
      })
      .catch((error) => {
        onStatus({
          type: "error",
          message: `${friendlyFirebaseError(error)}`,
        });
        errorLogger(error.toString(), {
          component: "MobilePhoneEntryForm",
          flow: "2FA Enrollment Flow",
          userId: user.id,
        });
      })
      .finally(() => {
        setPending(false);
      });
  };

  // resend verification code
  const reRequestCode = (phoneNumber: string) =>
    getVerificationCode({ mobilePhone: phoneNumber });

  return (
    <>
      <div className="flex w-full h-full ">
        {/* Do Not Delete this div: id "multifactor-enrollment" is a param required initialize the required reCAPTCHA */}
        <div id="multifactor-enrollment"></div>
        {/* Enter Phone Number to get verificationId */}
        {!verificationId && (
          <div className="w-full ">
            <form className="" onSubmit={handleSubmit(getVerificationCode)}>
              <div className=" flex justify-center w-full   mb-2">
                <PhoneInputWithCountry
                  className="peer mt-1 block w-full px-3 py-2 inp sm:text-sm border-none"
                  name="mobilePhone"
                  international
                  defaultCountry="US"
                  countryCallingCodeEditable={false}
                  control={control}
                  rules={{ required: true }}
                />

                {errors.mobilePhone && (
                  <p className="mt-1 text-sm text-left text-red-600">
                    {errors.mobilePhone.message}
                  </p>
                )}
              </div>
              {/* <button
              className="py-2 px-4 w-full text-white bg-instant-teams-blue-Main rounded border-0 hover:bg-instant-teams-blue-L1 focus:outline-none"
              type="submit"
              disabled={pending}
            >
              Send Code
            </button> */}
              <div className="mt-10 mb-4">
                {buttonVariant === "arrowButton" ? (
                  <ForwardArrowButton pending={pending} type="submit" />
                ) : (
                  <ButtonWithLoading_empNewUi pending={pending} value="Send Verification Code" />
                )}
              </div>

            </form>
          </div>
        )}

        {/* Enter Verification Code  */}
        {verificationId && (
          <>
            <form onSubmit={handleSubmit(sendVerificationCode)}>
              {/* the empty div with id="multifactor-enrollment" is a param required initialize the required reCAPTCHA */}
              <div id="multifactor-enrollment"></div>
              <div className="w-full flex flex-col items-center justify-center">
                <p className="text-instant-teams-blue-Main text-xl mt-5 font-bold">Verify Phone Number</p>
                <p className="text-gray-400  my-4 text-center text-[18px] font-thin ">Please enter the verification code sent to your mobile number</p>
              </div>
              <div className="mb-16">
                <input
                  className="py-1 px-3 w-full leading-8 bg-white rounded border border-gray-300 outline-none focus:border-blue-500 focus:ring-1"
                  name="verificationCode"
                  type="hidden"
                  inputMode="numeric"
                  autoComplete="one-time-code"
                  ref={register({
                    required: "Please enter your confirmation code",
                  })}
                />
                <div className=" mb-10 flex justify-center items-center">
                  <PhoneInputWithCountry
                    className="peer mt-1 block px-3 py-2  sm:text-sm border-none"
                    style={{ boxShadow: 'none' }}
                    name="mobilePhone"
                    international
                    defaultCountry="US"
                    defaultValue={phoneNumber}
                    disabled
                    countryCallingCodeEditable={false}
                    control={control}
                    rules={{ required: true }}
                    onChange={(code: any) => setValue("mobilePhone", code)}
                  />
                  <img
                   onClick={() => setVerificationId()}
                  className="w-5 h-5 ml-[-50px] "
                    src="/images/editImage.png">
                  </img>
                
                </div>

                <AuthCode
                  containerClassName="flex flex-row"
                  inputClassName="shadow-none m-1 w-14 border-solid border-r-0 border-l-0 border-t-0 border-b-4 border-gray-300"
                  alphanumeric="numeric"
                  ariaLabel="mobile confirmation code"
                  onChange={(code: number) =>
                    setValue("verificationCode", code)
                  }
                />

                {errors.verificationCode && (
                  <p className="mt-1 text-sm text-left text-red-600">
                    {errors.verificationCode.message}
                  </p>
                )}

              </div>
              {buttonVariant === "arrowButton" ? (
                <ForwardArrowButton pending={pending} type="submit" />
              ) : (
                <ButtonWithLoading_empNewUi pending={pending} value="Confirm Verification Code" />
              )}
              <div className="mt-4">
                <button
                  onClick={() => reRequestCode(phoneNumber)}
                  className="outline-none mr-1 underline text-center w-full mb-1 px-6 py-2 bg-transparent text-sm font-bold text-instant-teams-blue-Main text-lg hover:text-blue-600  focus:outline-none"
                  type="button"
                  disabled={pending}
                >
                  Resend verfication code?
                </button>
                {/* <button
                 
                 
                  className="outline-none mr-1 mb-1 px-6 py-2 bg-transparent text-sm font-bold text-blue-500 hover:text-blue-600 uppercase focus:outline-none"
                  type="button"
                  disabled={pending}
                >
                  Change Number
                </button> */}
              </div>
            </form>
            {/* Resend Confirmation Code */}
          </>
        )}
      </div>
    </>
  );
}

export default MultiFactorEnrollment_empNewUI;
