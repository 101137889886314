import { forwardRef } from "react";
import { cn } from "../../../../../util/cn";

interface InputProps {
  name: string;
  display: string;
  placeholder?: string;
  readOnly?: boolean;
  maxLength?: number;
  required?: boolean;
  error?: string;
  touched?: boolean;
}

const Input_Emp = forwardRef(
  (
    {
      name,
      display,
      placeholder = "",
      readOnly = false,
      maxLength,
      required = false,
      error,
      touched,
      ...props
    }: InputProps,
    ref: any,
  ) => {
    return (
      <div id={name} className="flex-auto">
        <div className="relative mb-4">
          <label className="pl-1 text-instant-teams-teal-Main text-sm absolute duration-300 transform -translate-y-4 scale-75 top-4 origin-[0] left-2.5 peer-focus:text-instant-teams-blue-Main peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-4">
           
           
            {readOnly ? " - LOCKED" : null}
          </label>
          <input
            type="text"
            name={name}
            placeholder={placeholder}
            readOnly={readOnly}
            maxLength={maxLength}
            ref={ref} // forwarding the ref
            // className="form-input shadow-md rounded-md w-full pt-3 pb-3"
            className={cn(
              "form-input bg-gray-100 rounded-full  text-center  py-[14px] text-[1rem] text-gray-800 placeholder-gray-400",
              {
                "cursor-not-allowed": readOnly,
              },
            )}
            {...props}
          />
          {/* {touched && !error && (
            <span className="absolute right-2 top-2 text-xxs">
              <FontAwesomeIcon
                icon={faCircleCheck}
                style={{ color: "green" }}
              />
              {display} looks good!
            </span>
          )} */}

          {error && (
            <p className={"text-[0.8rem] font-medium text-[red] mt-1"}>
              {error}
            </p>
          )}
        </div>
      </div>
    );
  },
);

Input_Emp.displayName = "Input";

export default Input_Emp;
