import { useState } from "react";
import AvatarDropzone from "../../custom-ui/dropzone/AvatarDropzone";
import AvatarDropzone_EmpNewUi from "../../custom-ui/dropzone/AvatartDropzone_EmpNewUi";
import DialogWrapper from "../../custom-ui/dialog/Dialog";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faTrashCan } from '@fortawesome/free-solid-svg-icons';


function Avatar_EmpNewUi(props) {
    return (
        <>
            <DialogWrapper title="" onClose={() => { }}>
                <div className="flex justify-end">
                <div
                    className="flex mt-[-10px] justify-center items-center w-7 h-7 bg-gray-200 rounded-full cursor-pointer"
                    onClick={() => props.toggleModal()}
                >
                    <FontAwesomeIcon icon={faTimes} color="#565656" />
                </div>
                </div>
              
                <p className=" mb-[-15px] text-slate-500  leading-relaxed text-sm flex justify-center">
                    Tap the image to open file explorer or Drop image below
                </p>
                <AvatarDropzone_EmpNewUi closeModal={props.toggleModal} />
            </DialogWrapper>
        </>
    );
}

export default Avatar_EmpNewUi;
