import React from 'react';

const PopupModal = ({
    isOpen,
    onClose,
    children,
    title = "",
}: {
    isOpen: boolean;
    onClose: () => void;
    children: any;
    title?: string;
}) => {
    if (!isOpen) return null;

    return (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
            <div className="relative bg-white max-h-[95vh] w-full max-w-5xl overflow-auto rounded-lg shadow-lg">
                {/* Modal Header */}
                <div
                    className="sticky top-0 flex justify-between items-center p-4 border-b border-gray-300 bg-white z-10"
                >
                    {title && (
                        <h2 className="text-lg font-semibold text-instant-teams-blue-Main">
                            {title}
                        </h2>
                    )}
                    <button
                        onClick={onClose}
                        className="text-gray-600 flex justify-center items-center h-8 w-8 p-4 bg-gray-100 rounded-full hover:text-gray-900 focus:outline-none"
                    >
                        ✕
                    </button>
                </div>

                {/* Modal Content */}
                <div className="pb-2">{children}</div>
            </div>
        </div>
    );
};

export default PopupModal;
