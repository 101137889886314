import { useState } from "react";
import AvatarDropzone from "../../custom-ui/dropzone/AvatarDropzone";
import DialogWrapper from "../../custom-ui/dialog/Dialog";

function Avatar(props) {
  return (
    <>
      <DialogWrapper title="  " onClose={props.toggleModal}>
        <p className="my-6 text-slate-500  leading-relaxed text-sm flex justify-center">
          Tap the image to open file explorer or Drop image below
        </p>
        <AvatarDropzone closeModal={props.toggleModal} />
      </DialogWrapper>
    </>
  );
}

export default Avatar;
