import {
  useQuery,
  hashQueryKey,
  QueryClient,
  QueryClientProvider as QueryClientProviderBase,
} from "react-query";
import {
  getFirestore,
  connectFirestoreEmulator,
  onSnapshot,
  doc,
  collection,
  query,
  where,
  orderBy,
  getDoc,
  getDocs,
  setDoc,
  updateDoc,
  addDoc,
  deleteDoc,
  limit,
  serverTimestamp,
  Timestamp,
} from "firebase/firestore";
import { db, client, createQuery, format } from "../providers/database";

export function updateAutoSave(collection: string, id: string, data: any) {
  console.log(id,data)
  return updateDoc(doc(db, collection, id),{...data, latestUpdate:Timestamp.now()});
}

export function updateAddress(collection: string, id: string, data: any) {
  return updateDoc(doc(db, collection, id),{ address:{state:data}, latestUpdate:Timestamp.now()});
}

export const setAutoSave = (collection: string, id: string, data: object) => {
  console.log("utility.ts, setAutoSave, data: ", data);
  return setDoc(doc(db, collection, id), data);
};

export async function updateStatusHistory(
  parent: string,
  id: string,
  data: object,
) {
  await addDoc(collection(db, `${parent}/${id}/StatusHistory`), data);
}

// This function logs the activity and writes it to the "Activity" collection
export const logActivity = async (
  collectionName: string,
  id: string,
  activityField: string,
  beforeField: string,
  afterField: string,
  who: string,
  activityLabel: string,
) => {
  const params = {
    collection: collectionName,
    id,
    activityField,
    beforeField,
    afterField,
    created_at: Timestamp.now().toDate(),
    who,
    activityLabel,
  };
  await addDoc(collection(db, "Activity"), params);
};

export const analyticsLog = async (
  type: string,
  typeId: string,
  userId: string,
  route: string,
) => {
  const params = {
    type: type,
    typeId: typeId,
    userId: userId,
    route: route,
    createdAt: Timestamp.now(),
  };
  await addDoc(collection(db, "Views"), params);
};

export function useUtilitySearch(
  collectionName: string,
  name: string,
  field: string,
  status: string,
  id: string,
) {
  return useQuery(
    [name, query],
    createQuery(() =>
      query(
        collection(db, collectionName),
        where(field, "==", id),
        where("status", "==", status),
      ),
    ),
    { enabled: !!query },
  );
}
