const ResendEmailSVG = ({ className = "" }: { className?: string }) => (
  <svg
    width="25"
    height="24"
    viewBox="0 0 25 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      d="M12.5 12L4.5 8V18H10.5V20H4.5C3.95 20 3.47917 19.8042 3.0875 19.4125C2.69583 19.0208 2.5 18.55 2.5 18V6C2.5 5.45 2.69583 4.97917 3.0875 4.5875C3.47917 4.19583 3.95 4 4.5 4H20.5C21.05 4 21.5208 4.19583 21.9125 4.5875C22.3042 4.97917 22.5 5.45 22.5 6V12H20.5V8L12.5 12ZM12.5 10L20.5 6H4.5L12.5 10ZM4.5 8V19V13V13.075V6V8Z"
      // fill="#15415E"
      fill="currentColor"
    />
    <path
      d="M17.5 23C16.2833 23 15.2208 22.6208 14.3125 21.8625C13.4042 21.1042 12.8333 20.15 12.6 19H14.15C14.3667 19.7333 14.7792 20.3333 15.3875 20.8C15.9958 21.2667 16.7 21.5 17.5 21.5C18.4667 21.5 19.2917 21.1583 19.975 20.475C20.6583 19.7917 21 18.9667 21 18C21 17.0333 20.6583 16.2083 19.975 15.525C19.2917 14.8417 18.4667 14.5 17.5 14.5C17.0167 14.5 16.5667 14.5875 16.15 14.7625C15.7333 14.9375 15.3667 15.1833 15.05 15.5H16.5V17H12.5V13H14V14.425C14.45 13.9917 14.975 13.6458 15.575 13.3875C16.175 13.1292 16.8167 13 17.5 13C18.8833 13 20.0625 13.4875 21.0375 14.4625C22.0125 15.4375 22.5 16.6167 22.5 18C22.5 19.3833 22.0125 20.5625 21.0375 21.5375C20.0625 22.5125 18.8833 23 17.5 23Z"
      // fill="#15415E"
      fill="currentColor"
    />
  </svg>
);
export default ResendEmailSVG;
