import * as z from "zod";

export const jobFormSchemaStep2 = (isSubmittingJobFirstTime: boolean) => {
  return z.object({
    includeDate: z.boolean().default(false), // New field for "Include Date" selection
    startDate: z
      .union([z.date(), z.string({required_error: "Start Date is required",}).min(1, {
        message: "Start Date is required",
      }).datetime({ offset: true }).optional()])
      .optional(),
  
    applicationFlow: z
      .string({
        required_error: "Application Flow field is required",
      })
      .min(1, {
        message: "Application Flow is required",
      }),
  });
};
