import * as z from "zod";

// const urlRegex = /^(https?:\/\/)?(www\.[a-zA-Z0-9.-]+\.[a-z]{2,}(\.[a-z]{2,})?|[a-zA-Z0-9.-]+\.[a-z]{2,}(\.[a-z]{2,})?)$/;
const urlRegex =
  /^(https?:\/\/)?(www\.)?([a-zA-Z0-9-]{1,63}\.)+[a-zA-Z]{2,3}(\/[^\s]*)?$/;
// const urlRegex = /^(https?:\/\/)?(www\.[a-zA-Z0-9-]{1,63}\.[a-zA-Z]{2,6})(\/[^\s]*)?$|^(https?:\/\/)?([a-zA-Z0-9-]{1,63}\.)+[a-zA-Z]{2,6}(\/[^\s]*)?$/;
// const urlRegex = /((([A-Za-z]{3,9}:(?:\/\/)?)(?:[-;:&=\+\$,\w]+@)?[A-Za-z0-9.-]+|(?:www.|[-;:&=\+\$,\w]+@)[A-Za-z0-9.-]+)((?:\/[\+~%\/.\w-_]*)?\??(?:[-\+=&;%@.\w_]*)#?(?:[\w]*))?)/;

// export const JobsNewSchema = z
export const jobFormSchemaStep3 = (isSubmittingJobFirstTime: boolean) => {
  return z
    .object({
    //   jobName: z
    //     .string({
    //       required_error: "Job Title field is required",
    //     })
    //     .min(1, {
    //       message: "Job Title is required",
    //     }),
      // solutionType: z
      //   .string({
      //     required_error: "Solution Type field is required",
      //   })
      //   .min(1, {
      //       message: "Solution Type is required",
      //     }),
      // .enum(["job_board", "bpo", ""]),
    //   solutionType: z
    //     .union([
    //       z.literal("job_board"),
    //       z.literal("bpo"),
    //       z.literal(""),
    //       z.null(),
    //     ])
    //     .refine((val) => val !== "" || null, {
    //       message: "Solution Type is required",
    //     }),

    //   startDate: z
    //     .date({
    //       required_error: "Start Date field is required",
    //       invalid_type_error: "Start Date is required",
    //     })
    //     .nullable()
    //     .refine((val) => val !== null, {
    //       message: "Date of Birth is required",
    //     }),

      //   openings: z
      //     .number({
      //       required_error: "Openings is required",
      //     })
      //     .min(1, {
      //       message: "Openings is required",
      //     })
      //     .nullable(),

    //   openings: z
    //     .union([
    //       z.string().optional(),
    //       z
    //         .number({
    //           invalid_type_error: "Openings is required",
    //         })
    //         .min(1, {
    //           message: "There should be minimum of 1 Opening",
    //         })
    //         .max(500, {
    //           message: "There should be a maximum of 500 Openings",
    //         }),
    //     ])
    //     .refine((value) => value !== "", {
    //       message: "Openings is required",
    //     }),
    //   applicationFlow: z
    //     .string({
    //       required_error: "Application Flow field is required",
    //     })
    //     .min(1, {
    //       message: "Application Flow is required",
    //     })
    //     .optional(),
      // .regex(urlRegex, "Invalid URL"),
      workSettings: z
        .union([
          z.literal("remote"),
          z.literal("in_person"),
          z.literal("hybrid"),
          z.literal(""),
          z.null(),
        ])
        .refine((val) => val !== "" || null, {
          message: "Work Setting is required",
        }),
      // workSettings: z
      //   .string({
      //     required_error: "Work Setting field is required",
      //   })
      //   .min(1, {
      //     message: "Work Setting is required",
      //   }),
      workType: z
        .union([
          z.literal("full_time"),
          z.literal("part_time"),
          z.literal(""),
          z.null(),
        ])
        .refine((val) => val !== "" || null, {
          message: "Work Type is required",
        }),
      // workType: z
      //   .string({
      //     required_error: "Work Type field is required",
      //   })
      //   .min(1, {
      //     message: "Work Type is required",
      //   }),
      workTerm: z
        .union([
          z.literal("permanent"),
          z.literal("temporary"),
          z.literal("temp_to_perm"),
          z.literal(""),
          z.null(),
        ])
        .refine((val) => val !== "" || null, {
          message: "Work Term is required",
        }),
      // workTerm: z
      //   .string({
      //     required_error: "Work Term field is required",
      //   })
      //   .min(1, {
      //     message: "Work Term is required",
      //   }),
      hoursPerWeekMin: z
        .union([
          z.string().optional(),
          z
            .number({
              invalid_type_error: "Min (Hrs) is required",
            })
            .min(0, {
              message: "There should be a minimum of 0 Hr",
            }),
        ])
        .refine((value) => value !== "", {
          message: "Min (Hrs) is required",
        }),
      hoursPerWeekMax: z
        .union([
          z.string().optional(),
          z
            .number({
              invalid_type_error: "Max (Hrs) is required",
            })
            .max(100, {
              message: "There should be a maximum of 100 Hrs",
            }),
        ])
        .refine((value) => value !== "", {
          message: "Max (Hrs) is required",
        }),
    //   payRateType: z
    //     .union([
    //       z.literal("salary"),
    //       z.literal("hourly"),
    //       z.literal("commission"),
    //       z.literal("unpaid_internship"),
    //       z.literal(""),
    //     ])
    //     .refine((val) => val !== "" || null, {
    //       message: "Pay Rate is required",
    //     }),
    //   salaryMin: z
    //     .string()
    //     // .union([
    //     //   z.string().optional(),
    //     //   z.number({
    //     //     invalid_type_error: "Min is required",
    //     //   }),
    //     // ])
    //     .optional(),
    //   salaryMax: z
    //     .string()
    //     // .union([
    //     //   z.string().optional(),
    //     //   z.number({
    //     //     invalid_type_error: "Max is required",
    //     //   }),
    //     // ])
    //     .optional(),
    //   hourlyMin: z
    //     // .union([
    //     //   z.string().optional(),
    //     //   z.number({
    //     //     invalid_type_error: "Min is required",
    //     //   }),
    //     // ])
    //     .string()
    //     .optional(),
    //   hourlyMax: z
    //     // .union([
    //     //   z.string().optional(),
    //     //   z.number({
    //     //     invalid_type_error: "Max is required",
    //     //   }),
    //     // ])
    //     .string()
    //     .optional(),
    //   isCommission: z
    //     .boolean({
    //       required_error: "isCommission field is required",
    //     })
    //     .optional(),
    //   commission: z
    //     .string({
    //       required_error: "Commission field is required",
    //     })
    //     .min(1, {
    //       message: "Commission is required",
    //     })
    //     .optional(),
    //   unpaidInternship: z
    //     .string({
    //       required_error: "Free Internship field is required",
    //     })
    //     .min(1, {
    //       message: "Free Internship is required",
    //     })
    //     .optional(),
      jobSummary: z
        .string({
          required_error: "Job Summary field is required",
        })
        .min(1, {
          message: "Job Summary is required",
        })
        .optional(),
      whatsInItForYou: z
        .string({
          required_error: "What's in it for you? field is required",
        })
        .min(1, {
          message: "What's in it for you? is required",
        })
        .optional(),
      // .min(1, {z
      //   message: "What's in it for you? is required",
      // }),
      dayInLife: z
        .string({
          required_error: "Day in the Life field is required",
        })
        .min(1, {
          message: "Day in the Life is required",
        })
        .optional(),
      mustHaves: z
        .string({
          required_error:
            "Must Haves/What You Bring field to the Table is required",
        })
        .min(1, {
          message: "Must Haves/What You Bring to the Table is required",
        })
        .optional(),
      ...(isSubmittingJobFirstTime
        ? {
            aspectsOfJob: z
              .array(z.string())
              .min(1, { message: "You must check at least one checkbox." }),
            mustHavesCheckboxes: z
              .array(
                z.string(),
                // z.object({
                //   code: z.string(),
                //   sortOrder: z.number(),
                // }),
              )
              .nonempty("At least one checkbox must be selected")
              .max(5, { message: "You must check at most 5 checkboxes." }),
            aboutThisJob: z
              .array(
                z.object({
                  code: z.string(),
                  labelLeft: z.string(),
                  labelRight: z.string(),
                  value: z.number(),
                }),
              )
              .nonempty("At least one checkbox must be selected")
              .max(5, { message: "You must check at most 5 checkboxes." }),
          }
        : {
            aspectsOfJob: z.array(z.string()).optional(),
            mustHavesCheckboxes: z
              .array(
                z.string(),
                // z.object({
                //   code: z.string(),
                //   sortOrder: z.number(),
                // }),
              )
              .nonempty("At least one checkbox must be selected")
              .optional(),
            aboutThisJob: z
              .array(
                z.object({
                  code: z.string(),
                  labelLeft: z.string(),
                  labelRight: z.string(),
                  value: z.number(),
                }),
              )
              .nonempty("At least one checkbox must be selected")
              .optional(),
          }),
      aspectsOfJobOther: z
        .string({
          required_error: "Other field is required",
        })
        .min(1, {
          message: "Other is required",
        })
        .optional(),
      isAspectsOfJobOther: z
        .boolean({
          required_error: "isAspectsOfJobOther field is required",
        })
        .optional(),

      // isSubmittingJobFirstTime: z.boolean().optional(),
    })
    .superRefine((data, ctx) => {
      console.log(data, "=============>data inside superRefine");
      console.log(
        isSubmittingJobFirstTime,
        "=============>isSubmittingJobFirstTime inside superRefine",
      );
      console.log(
        !data?.whatsInItForYou,
        "=============>!data?.whatsInItForYou inside superRefine",
      );
      console.log(
        data?.aboutThisJob?.length,
        "=============>data?.aboutThisJob?.length inside superRefine",
      );
      console.log(
        data?.aboutThisJob?.length || 10,
        "=============>(data?.aboutThisJob?.length || 10) inside superRefine",
      );

      console.log(
        (isSubmittingJobFirstTime || !data?.whatsInItForYou) &&
          (data?.aboutThisJob?.length || 10) > 5,
        "=========>1111111111111",
      );

      if (
        data?.aspectsOfJob?.length === 0 &&
        !data.aspectsOfJobOther &&
        !data.isAspectsOfJobOther
      ) {
        ctx.addIssue({
          code: "custom",
          path: ["aspectsOfJobRoot"], // Root-level error
          message: "Please check at least one of the above checkboxes",
        });
      }

      if (
        isSubmittingJobFirstTime &&
        // (isSubmittingJobFirstTime || !data?.dayInLife) &&
        data?.aboutThisJob?.length &&
        data?.aboutThisJob?.length > 5
      ) {
        ctx.addIssue({
          code: "custom",
          path: ["aboutThisJobRoot"], // Root-level error
          message: "Please check at most 5 checkboxes",
        });
      }

      if (
        (isSubmittingJobFirstTime || !data?.whatsInItForYou) &&
        data?.mustHavesCheckboxes?.length &&
        data?.mustHavesCheckboxes?.length > 5
      ) {
        ctx.addIssue({
          code: "custom",
          path: ["mustHavesCheckboxesRoot"], // Root-level error
          message: "Please check at most 5 checkboxes",
        });
      }

      const hoursPerWeekMin =
        data.hoursPerWeekMin || data.hoursPerWeekMin === 0
          ? data.hoursPerWeekMin
          : null;
      const hoursPerWeekMax =
        data.hoursPerWeekMax || data.hoursPerWeekMax === 0
          ? data.hoursPerWeekMax
          : null;

      if (hoursPerWeekMin !== null && hoursPerWeekMax !== null) {
        if (hoursPerWeekMin > hoursPerWeekMax) {
          ctx.addIssue({
            code: "custom",
            message: "Min (Hrs) must be less than Max (Hrs)",
            path: ["hoursPerWeekMin"],
          });
          ctx.addIssue({
            code: "custom",
            message: "Max (Hrs) must be greater than Min (Hrs)",
            path: ["hoursPerWeekMax"],
          });
        }
      }

    //   const applicationFlow = data.applicationFlow;

    //   if (!urlRegex.test(applicationFlow)) {
    //     ctx.addIssue({
    //       code: "custom",
    //       message: "Invalid URL",
    //       path: ["applicationFlow"],
    //     });
    //   }

    //   const hoursPerWeekMin =
    //     data.hoursPerWeekMin || data.hoursPerWeekMin === 0
    //       ? data.hoursPerWeekMin
    //       : null;
    //   const hoursPerWeekMax =
    //     data.hoursPerWeekMax || data.hoursPerWeekMax === 0
    //       ? data.hoursPerWeekMax
    //       : null;

    //   if (hoursPerWeekMin !== null && hoursPerWeekMax !== null) {
    //     if (hoursPerWeekMin > hoursPerWeekMax) {
    //       ctx.addIssue({
    //         code: "custom",
    //         message: "Min (Hrs) must be less than Max (Hrs)",
    //         path: ["hoursPerWeekMin"],
    //       });
    //       ctx.addIssue({
    //         code: "custom",
    //         message: "Max (Hrs) must be greater than Min (Hrs)",
    //         path: ["hoursPerWeekMax"],
    //       });
    //     }
    //   }

    //   const payRateType = data.payRateType;

    //   if (payRateType === "salary") {
    //     // const salaryMax = isNaN(Number(data.salaryMax))
    //     //   ? ""
    //     //   : Number(data.salaryMax);
    //     // const salaryMin = isNaN(Number(data.salaryMin))
    //     //   ? ""
    //     //   : Number(data.salaryMin);

    //     // Check if values are present
    //     const salaryMax = data.salaryMax?.trim() || null; // Trim whitespace and assign null if empty
    //     const salaryMin = data.salaryMin?.trim() || null; // Trim whitespace and assign null if empty

    //     if (salaryMax === null) {
    //       ctx.addIssue({
    //         code: "custom",
    //         message: "Max is required",
    //         path: ["salaryMax"],
    //       });
    //     }
    //     if (salaryMin === null) {
    //       ctx.addIssue({
    //         code: "custom",
    //         message: "Min is required",
    //         path: ["salaryMin"],
    //       });
    //     }

    //     // if (
    //     // salaryMax !== "" &&
    //     // salaryMax !== null &&
    //     // salaryMin !== "" &&
    //     // salaryMin !== null &&
    //     // salaryMax !== undefined &&
    //     // salaryMin !== undefined
    //     // ) {
    //     // if (salaryMin >= salaryMax) {

    //     // Parse the salaries to numbers for comparison
    //     const parsedSalaryMax = salaryMax !== null ? Number(salaryMax) : null;
    //     const parsedSalaryMin = salaryMin !== null ? Number(salaryMin) : null;

    //     if (parsedSalaryMin !== null && parsedSalaryMax !== null) {
    //       if (parsedSalaryMin > parsedSalaryMax) {
    //         ctx.addIssue({
    //           code: "custom",
    //           message: "Min must be less than Max",
    //           path: ["salaryMin"],
    //         });
    //         ctx.addIssue({
    //           code: "custom",
    //           message: "Max must be greater than Min",
    //           path: ["salaryMax"],
    //         });
    //       }
    //     }
    //   } else if (payRateType === "hourly") {
    //     // if (data.hourlyMax === "") {
    //     //   ctx.addIssue({
    //     //     code: "custom",
    //     //     message: "Max is required",
    //     //     path: ["hourlyMax"],
    //     //   });
    //     // }
    //     // if (data.hourlyMin === "") {
    //     //   ctx.addIssue({
    //     //     code: "custom",
    //     //     message: "Min is required",
    //     //     path: ["hourlyMin"],
    //     //   });
    //     // }

    //     // Check if values are present
    //     const hourlyMax = data.hourlyMax?.trim() || null; // Trim whitespace and assign null if empty
    //     const hourlyMin = data.hourlyMin?.trim() || null; // Trim whitespace and assign null if empty

    //     if (hourlyMax === null) {
    //       ctx.addIssue({
    //         code: "custom",
    //         message: "Max is required",
    //         path: ["hourlyMax"],
    //       });
    //     }
    //     if (hourlyMin === null) {
    //       ctx.addIssue({
    //         code: "custom",
    //         message: "Min is required",
    //         path: ["hourlyMin"],
    //       });
    //     }

    //     // if (
    //     //   data.hourlyMax !== "" &&
    //     //   data.hourlyMax !== null &&
    //     //   data.hourlyMin !== "" &&
    //     //   data.hourlyMin !== null &&
    //     //   data.hourlyMax !== undefined &&
    //     //   data.hourlyMin !== undefined
    //     // ) {
    //     //   if (data.hourlyMin >= data.hourlyMax) {

    //     // Parse the hourlies to numbers for comparison
    //     const parsedHourlyMax = hourlyMax !== null ? Number(hourlyMax) : null;
    //     const parsedHourlyMin = hourlyMin !== null ? Number(hourlyMin) : null;

    //     if (parsedHourlyMin !== null && parsedHourlyMax !== null) {
    //       if (parsedHourlyMin > parsedHourlyMax) {
    //         ctx.addIssue({
    //           code: "custom",
    //           message: "Min must be less than Max",
    //           path: ["hourlyMin"],
    //         });
    //         ctx.addIssue({
    //           code: "custom",
    //           message: "Max must be greater than Min",
    //           path: ["hourlyMax"],
    //         });
    //       }
    //     }
    //   } else if (payRateType === "commission") {
    //     if (data.commission === "") {
    //       ctx.addIssue({
    //         code: "custom",
    //         message: "Commission is required",
    //         path: ["commission"],
    //       });
    //     }
    //   } else if (payRateType === "unpaid_internship") {
    //     if (data.unpaidInternship === "") {
    //       ctx.addIssue({
    //         code: "custom",
    //         message: "Free Internship is required",
    //         path: ["unpaidInternship"],
    //       });
    //     }
    //   }
    });
};



