import { useState, useEffect, Fragment,useMemo } from "react";
import { Controller, useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import { useLookup } from "../../../../../../data/lookup";
import { getIsSubmittingJobFirstTime } from "../../../../../../data/jobDetailsNewCollection";
import { isLockedJobNew } from "../../../../../../util/jobs/jobUtils";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import { useNavigate } from "react-router-dom";
import { jobFormSchemaStep2 } from "./JobFormSchemaStep2";
import SelectGeneric_emp from "../../Component-UI/AutoSaveGeneric";
import DateGeneric from "../../../../../custom-ui/old-ui/DateGeneric";
import ToastAlert from "../../../../../custom-ui/toast-alert/ToastAlert";
import FloatingLabelInput from "../../Component-UI/FloatPlaceholderInputField";
import { useJobDetailsNewCollection } from "../../../../../../data/jobDetailsNewCollection";
import InfoIcon from "../../../../../dashboard/AdminMetricDashboard/InfoTextWithIcon";

function AddJobStep2({ currStep, setCurrentStep, setFormvalues, formvalues }: any) {
    const params = useParams();
    const jobId = params.id ?? "vUN2qyluYqwzeaEmduqU";
    const navigate = useNavigate();
    const [isInitialized, setIsInitialized] = useState(false);
    const [error,setError] = useState()
    const [formSchema, setFormSchema] = useState(jobFormSchemaStep2(false));
    const { data: job } = useJobDetailsNewCollection(jobId);
    const { data: solutionTypes = [] } = useLookup("solutionTypeNew");
    const isFieldLocked = isLockedJobNew(job?.jobState);

    // New state for radio button selection
    const [includeDate, setIncludeDate] = useState(false);

    type FormValues = z.infer<ReturnType<typeof jobFormSchemaStep2>>;
    const form = useForm<FormValues>({
        resolver: zodResolver(formSchema),
        defaultValues: {
            startDate: formvalues?.startDate || "",
            applicationFlow: formvalues?.applicationFlow || "",
          
        },
        reValidateMode: "onBlur",
    });

    const isSubmittingJobFirstTime = useMemo(
        () => getIsSubmittingJobFirstTime(job),
        [job]
    );

    const {
        handleSubmit,
        setValue,
        control,
        reset,
        formState: { errors, isSubmitting },
    } = form;

    const onSubmit = async ({ ...values }: FormValues) => {
        setFormvalues({
            ...formvalues,
            startDate: includeDate ? values?.startDate || null : null,
            applicationFlow: values?.applicationFlow,
            startDateInclude:includeDate,

          
        });
        if (includeDate) {
            if (values?.startDate) {
                setCurrentStep(currStep + 1); 
            }
            else {
                setError("Start Date is Required")
            }
            
        } else {
             setCurrentStep(currStep + 1); 
        }
      
    };

    const setIntialValuesToForm = (jobData: any) => {
        if (!jobData || (jobData && !Object.keys(jobData).length)) return;

        setValue("applicationFlow", formvalues?.applicationFlow ?? "");
        setValue("startDate", formvalues?.startDate?.toDate?.() ?? undefined);
    };

    useEffect(() => {
        if (job && !isInitialized) {
            setIntialValuesToForm(job);
            setIsInitialized(true);
        }
    }, [job, isInitialized]);

    useEffect(() => {
        if (isSubmittingJobFirstTime) {
            const newSchema = jobFormSchemaStep2(isSubmittingJobFirstTime);
            setFormSchema(newSchema);
            setIntialValuesToForm(job);
        } else {
            const newSchema = jobFormSchemaStep2(false);
            setFormSchema(newSchema);
        }
    }, [isSubmittingJobFirstTime, reset]);

    return (
        <>
            <form onSubmit={handleSubmit(onSubmit)}>
                <div className="w-[60vw] flex flex-col justify-center items-center">
                    <div className="w-[50vw]">
                        <div className="flex items-center">

                      
                        <Controller
                            control={control}
                            name="applicationFlow"
                            render={(field) => (
                                <FloatingLabelInput
                                    {...field}
                                    display="Apply Link"
                                    placeholder="Enter Application Flow"
                                    readOnly={isFieldLocked}
                                    error=""
                                    required
                                />
                            )}
                            />
                             <InfoIcon
                            tooltipText={`Here is where you place the link
                                that will direct the applicant off of
                                Instant Teams and towards filling
                                out your full job application,
                                wherever it is housed (i.e. your
                                company’s ATS).`}
                            />
                              </div>
                        

                        <div className="flex mt-6 w-auto justify-start items-center">
                        <h1 className="text-instant-teams-blue-Main text-lg flex mt-2">
                            Start Date <span className="text-red-500 text-base ml-1">*</span>
                            </h1>
                            <div className="flex items-end mt-2">
                              <InfoIcon
                            tooltipText={"Please select include if you would like to post your desired start date for this(these) candidate(s)."}
                            />   
                      </div>
                           
                          
                        </div>

                        {/* Radio Buttons for Include Date and Not Include Date */}
                        <div className="mt-2 my-3 flex ">
                            <div className="flex items-center mb-2">
                                <input
                                    type="radio"
                                    id="includeDate"
                                    name="dateOption"
                                    value="includeDate"
                                    checked={includeDate}
                                    onChange={() => setIncludeDate(true)}
                                    className="mr-2 "
                                />
                                <label className="mr-5" htmlFor="includeDate">Include Start Date</label>
                            </div>
                            <div className="flex items-center mb-2">
                                <input
                                    type="radio"
                                    id="notIncludeDate"
                                    name="dateOption"
                                    value="notIncludeDate"
                                    checked={!includeDate}
                                    onChange={() => setIncludeDate(false)}
                                    className="mr-2"
                                />
                                <label htmlFor="notIncludeDate"> Do Not Include Start Date</label>
                            </div>
                        </div>

                        {/* Conditionally Render Start Date */}
                        {includeDate && (
                            <Controller
                                control={control}
                                name="startDate"
                                render={(field) => (
                                    <DateGeneric
                                        {...field}
                                        display="Start Date"
                                        timestamp={true}
                                        readOnly={isFieldLocked}
                                        error={errors?.startDate?.message}
                                        required
                                    />
                                )}
                            />
                        )}
                     {  error&& <p className="text-red-500  text-base" >{ error}</p>}
                    </div>
                </div>
                <div className="mt-28 w-full flex flex-row justify-between">
                    <button
                        type="button"
                        disabled={isSubmitting}
                        className="h-[50px] w-[210px] rounded-[70px] border-[1px] border-[#15415E] px-5 py-3 hover:border-[#1d5174] text-white bg-[#15415E] text-[16px] leading-[19.6px] font-[400]"
                        onClick={() => {
                            reset();
                            navigate("/jobs");
                        }}
                    >
                        Cancel
                    </button>
                    <div className="flex justify-center items-center">
                        <button
                            onClick={() => setCurrentStep(currStep - 1)}
                            className="h-[50px] w-[210px] mr-5 rounded-[70px] border-[1px] border-[#15415E] px-5 py-3 hover:border-[#1d5174] text-[#15415E] text-[16px] leading-[19.6px] font-[400]"
                        >
                            Back
                        </button>
                        <button
                            type="submit"
                            disabled={isSubmitting}
                            className="h-[50px] w-[210px] rounded-[70px] bg-[#15415E] px-5 py-3 hover:bg-[#1d5174] text-[#FFFFFF] text-[16px] leading-[19.6px] font-[400]"
                        >
                            {isSubmitting ? "Saving..." : "Next"}
                        </button>
                    </div>
                </div>
            </form>
            <ToastAlert />
        </>
    );
}

export default AddJobStep2;
