import { useState, useMemo } from "react";
import { cn } from "../../../../util/cn";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import { useEffect } from "react";
import PhoneInputWithCountry from "react-phone-number-input/react-hook-form";
import { employerBillingSchema } from "../registration/Schema/registrationSchema";
import { EmployerNew } from "../../../../dataTypes/Employer";
import { updateEmployerNew } from "../../../../data/employer";
import toast from "react-hot-toast";

type BillingProps = {
  onContinue: Function;
  currentPlan: string;
  employerInfo: any;
};

const Options = [
  {
    id: "billing_same",
    title: "Same as point of Contact",
  },
  {
    id: "billing_different",
    title: "New Billing Information",
  },
];

const BillingPage = ({ onContinue, currentPlan, employerInfo }: BillingProps) => {
  const [selectedOption, setSelectedOption] = useState("billing_same");
  const [isFormVisible, setIsFormVisible] = useState(false);
  const [pending, setPending] = useState(false);

  const {
    handleSubmit,
    register,
    errors,
    getValues,
    control,
    trigger,
    watch,
    formState: { dirtyFields },
  } = useForm({
    defaultValues: {
      email: "",
      name: "",
      title: "",
      phone: "",
    },
    resolver: zodResolver(employerBillingSchema),
  });

  useEffect(() => {
    setIsFormVisible(selectedOption === "billing_different");
  }, [selectedOption]);

  const handleSelection = async (data: any) => {
    setPending(true);
    let employerCollectionData: EmployerNew;
    if (!isFormVisible) {
      employerCollectionData = {
        currentPlan: currentPlan,
        billingContact: {
          email: employerInfo?.email ?? "",
          name: employerInfo?.name ?? "",
          title: employerInfo?.title ?? "",
          phone: employerInfo?.phone ?? "",
        },
      };
    } else {
      employerCollectionData = {
        currentPlan: currentPlan,
        billingContact: {
          email: data["email"],
          name: data["name"],
          title: data["title"],
          phone: data["phone"],
        },
      };
    }

    await updateEmployerNew(
      employerInfo?.employerId,
      employerCollectionData,

    )
      .then(() => {
        toast.success("Form Signed");
        onContinue(employerInfo);
      })
      .catch(() => {
        toast.error("There was an error updating Data");
      })
      .finally(() => setPending(false));
  };

  return (
    <div className="max-w-2xl mx-auto p-6 overflow-y-scroll no-scrollbar bg-white">
      {/* Radio Buttons for Billing Options */}
      <div className="relative mb-7">
        <label className="absolute -top-4 mb-4 text-xl text-[#15415E] bg-white px-1 transition-all duration-200">
          Billing Information
        </label>
        <div className="flex flex-row space-x-6 mt-6 pt-6">
          {Options.map((item) => (
            <div key={item.id} className="flex mr-20 items-center">
              <input
                className="h-5 w-5 shadow text-instant-teams-blue-L1 bg-white rounded-full border-instant-teams-blue-Main outline-none focus:border-instant-teams-blue-Main focus:ring-1"
                id={item.id}
                name="billingOptions"
                type="radio"
                value={item.id}
                checked={selectedOption === item.id}
                onChange={(e) => setSelectedOption(e.target.value)}
              />
              <label
                className="ml-3 text-lg font-semibold text-instant-teams-blue-Main"
                htmlFor={item.id}
              >
                {item.title}
              </label>
            </div>
          ))}
        </div>
      </div>

      {isFormVisible && (
        <form
          className="space-y-8"
          onSubmit={handleSubmit(handleSelection)}
          autoComplete="off"
        >
          <div className="relative mb-7">
            <div className="flex items-center">
              <input
                type="text"
                name="email"
                placeholder=" " // Empty placeholder for floating effect
                ref={register({
                  required: "Please enter an email",
                })}
                onBlur={() => {
                  trigger("email");
                }}
                className="peer mt-1 block w-full px-3 inp py-2 rounded-none sm:text-sm"
                style={{ boxShadow: "none", flex: 1 }}
              />
            </div>
            <label
              htmlFor="email"
              className="absolute -top-4 text-sm text-[#15415E] bg-white px-1 transition-all duration-200 peer-placeholder-shown:top-2 peer-placeholder-shown:text-[#15415E] peer-placeholder-shown:text-sm peer-focus:-top-4 peer-focus:text-[#15415E] peer-focus:text-sm"
            >
              Email <span className="text-red-600">*</span>
            </label>
            {errors.email && (
              <p className="mt-1 text-sm text-red-600">
                {errors.email.message}
              </p>
            )}
          </div>
          <div className="relative mb-7">
            <input
              type="text"
              name="name"
              placeholder=""
              ref={register({
                required: "Please enter your full name.",
              })}
              //onChange={async () => await trigger("name")}
              onBlur={async () => await trigger("name")}
              className="peer mt-1 block w-full px-3 inp py-2 rounded-none sm:text-sm"
              style={{ boxShadow: "none" }}
            />
            <label
              htmlFor="name"
              className="absolute -top-4 text-sm text-[#15415E] bg-white px-1 transition-all duration-200 peer-placeholder-shown:top-2 peer-placeholder-shown:text-[#15415E] peer-placeholder-shown:text-sm peer-focus:-top-4 peer-focus:text-[#15415E] peer-focus:text-sm"
            >
              Full Name <span className="text-red-600">*</span>
            </label>
            {errors.name && (
              <p className="mt-1 text-sm text-left text-red-600">
                {errors.name.message}
              </p>
            )}
          </div>
          <div className="relative mb-7">
            <input
              type="text"
              name="title"
              placeholder=" " // Empty placeholder for floating effect
              ref={register()}
              // onChange={(e) => settitleValue(e.target.value)}
              onBlur={async () => await trigger("title")}
              className="peer mt-1 block w-full px-3 inp py-2 rounded-none sm:text-sm"
              style={{ boxShadow: "none" }}
            />
            <label
              htmlFor="title"
              className="absolute -top-4 text-sm text-[#15415E] bg-white px-1 transition-all duration-200 peer-placeholder-shown:top-2 peer-placeholder-shown:text-[#15415E] peer-placeholder-shown:text-sm peer-focus:-top-4 peer-focus:text-[#15415E] peer-focus:text-sm"
            >
              Designation
            </label>
          </div>
          <div className="relative mb-7">
            <PhoneInputWithCountry
              className="peer mt-1 block w-full px-3 py-2 inp sm:text-sm border-none"
              style={{ boxShadow: "none" }}
              name="phone"
              international
              defaultCountry="US"
              countryCallingCodeEditable={false}
              control={control}
              //onChange={async () => await trigger("phone")}
              onBlur={async () => await trigger("phone")}
              rules={{
                required: "Phone number is required",
                pattern: {
                  value: /^\+?[1-9]\d{1,14}$/,
                  message: "Invalid phone number",
                },
              }}
            />
            <label
              htmlFor="phone"
              className="absolute -top-4 text-sm text-[#15415E] bg-white px-1 transition-all duration-200 peer-placeholder-shown:top-2 peer-placeholder-shown:text-[#15415E] peer-placeholder-shown:text-sm peer-focus:-top-4 peer-focus:text-[#15415E] peer-focus:text-sm"
            >
              Phone Number <span className="mr-1 text-red-600">*</span>
            </label>
            {errors.phone && (
              <p className="mt-1 text-sm text-left text-red-600">
                {errors.phone.message}
              </p>
            )}
          </div>

          {/* Submit Button */}
          <div className="flex justify-center">
            <button
              type="submit"
              className={`py-2 px-8 text-white font-medium rounded-2xl focus:outline-none focus:ring-2 bg-[#15415E]`}
              style={{ width: "200px" }}
              disabled={pending}
            >
              {pending ? (
                <div className="flex items-center justify-center space-x-2">
                  <svg
                    className="animate-spin h-5 w-5 text-white"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <circle
                      className="opacity-25"
                      cx="12"
                      cy="12"
                      r="10"
                      stroke="currentColor"
                      strokeWidth="4"
                    ></circle>
                    <path
                      className="opacity-75"
                      fill="currentColor"
                      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4z"
                    ></path>
                  </svg>
                </div>
              ) : (
                "Continue"
              )}
            </button>
          </div>
        </form>
      )}
      {/* Continue Button */}
      {!isFormVisible && (
        <div className="flex justify-center">
          <button
            onClick={() => handleSelection("Done")}
            className="py-2 px-8 text-white font-medium rounded-2xl focus:outline-none focus:ring-2 bg-[#15415E]"
            style={{ width: "200px" }}
          >
            Continue
          </button>
        </div>
      )}
    </div>
  );
};

export default BillingPage;
