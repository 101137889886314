import * as z from "zod";

const prefixRegex = /^[^@]+$/;
const validEmailSuffixRegex = /^[a-zA-Z0-9._%+-]+$/;

export const AddUserEmployerSideSchema = z
  .object({
    fullName: z
      .string({
        required_error: "Full Name field is required",
      })
      .min(1, {
        message: "Full Name is required",
      }),
    email: z
      .string({
        required_error: "Email field is required",
      })
      .min(1, {
        message: "Email is required",
      })
      .regex(prefixRegex, 'Please remove "@" character')
      .regex(validEmailSuffixRegex, "Please enter a valid email"),
    jobTitle: z
      .string({
        required_error: "Job Title field is required",
      })
      .min(1, {
        message: "Job Title is required",
      }),
  })
  .superRefine((data, ctx) => {
    // console.log(data, "=============>data inside superRefine");
  });
