import * as z from "zod";

// const urlRegex = /^(https?:\/\/)?(www\.[a-zA-Z0-9.-]+\.[a-z]{2,}(\.[a-z]{2,})?|[a-zA-Z0-9.-]+\.[a-z]{2,}(\.[a-z]{2,})?)$/;
const urlRegex =
  /^(https?:\/\/)?(www\.)?([a-zA-Z0-9-]{1,63}\.)+[a-zA-Z]{2,3}(\/[^\s]*)?$/;
// const urlRegex = /^(https?:\/\/)?(www\.[a-zA-Z0-9-]{1,63}\.[a-zA-Z]{2,6})(\/[^\s]*)?$|^(https?:\/\/)?([a-zA-Z0-9-]{1,63}\.)+[a-zA-Z]{2,6}(\/[^\s]*)?$/;
// const urlRegex = /((([A-Za-z]{3,9}:(?:\/\/)?)(?:[-;:&=\+\$,\w]+@)?[A-Za-z0-9.-]+|(?:www.|[-;:&=\+\$,\w]+@)[A-Za-z0-9.-]+)((?:\/[\+~%\/.\w-_]*)?\??(?:[-\+=&;%@.\w_]*)#?(?:[\w]*))?)/;

// export const JobsNewSchema = z
export const JobFormSchema5 = (isSubmittingJobFirstTime: boolean) => {
  return z
    .object({



      jobSummary: z
      .string({
        required_error: "Job Summary field is required",
      })
      .min(1, {
        message: "Job Summary is required",
      })
      .optional(),
    whatsInItForYou: z
      .string({
        required_error: "What's in it for you? field is required",
      })
      .min(1, {
        message: "What's in it for you? is required",
      })
      .optional(),
    dayInLife: z
      .string({
        required_error: "Day in the Life field is required",
      })
      .min(1, {
        message: "Day in the Life is required",
      })
      .optional(),
    mustHaves: z
      .string({
        required_error:
          "Must Haves/What You Bring field to the Table is required",
      })
      .min(1, {
        message: "Must Haves/What You Bring to the Table is required",
      })
      .optional(),
    ...(isSubmittingJobFirstTime
      ? {
          aspectsOfJob: z.array(z.string()).optional(),
          mustHavesCheckboxes: z
            .array(z.string())
            .nonempty("At least one checkbox must be selected")
            .max(5, { message: "You must check at most 5 checkboxes." }),
          aboutThisJob: z
            .array(
              z.object({
                code: z.string(),
                labelLeft: z.string(),
                labelRight: z.string(),
                value: z.number(),
              }),
            )
            .nonempty("At least one checkbox must be selected")
            .max(5, { message: "You must check at most 5 checkboxes." }),
        }
      : {
          aspectsOfJob: z.array(z.string()).optional(),
          mustHavesCheckboxes: z
            .array(z.string())
            .nonempty("At least one checkbox must be selected")
            .optional(),
          aboutThisJob: z
            .array(
              z.object({
                code: z.string(),
                labelLeft: z.string(),
                labelRight: z.string(),
                value: z.number(),
              }),
            )
            .nonempty("At least one checkbox must be selected")
            .optional(),
        }),
    aspectsOfJobOther: z
      .string({
        required_error: "Other field is required",
      })
      .min(1, {
        message: "Other is required",
      })
      .optional(),
    isAspectsOfJobOther: z
      .boolean({
        required_error: "isAspectsOfJobOther field is required",
      })
      .optional(),
  })
  .superRefine((data, ctx) => {
    if (
      data?.aspectsOfJob?.length === 0 &&
      (!data?.aspectsOfJobOther || data?.aspectsOfJobOther?.length === 0) &&
      !data.isAspectsOfJobOther
    ) {
      ctx.addIssue({
        code: "custom",
        path: ["aspectsOfJobRoot"], // Root-level error
        message: "Please check at least one of the above checkboxes",
      });
    }

    if (
      isSubmittingJobFirstTime &&
      data?.aboutThisJob?.length &&
      data?.aboutThisJob?.length > 5
    ) {
      ctx.addIssue({
        code: "custom",
        path: ["aboutThisJobRoot"], // Root-level error
        message: "Please check at most 5 checkboxes",
      });
    }

    if (
      (isSubmittingJobFirstTime || !data?.whatsInItForYou) &&
      data?.mustHavesCheckboxes?.length &&
      data?.mustHavesCheckboxes?.length > 5
    ) {
      ctx.addIssue({
        code: "custom",
        path: ["mustHavesCheckboxesRoot"], // Root-level error
        message: "Please check at most 5 checkboxes",
      });
    }

 

   


   

    });
};
