import { useState, useMemo } from "react";
import SubscriptionCard from "./SubscriptionCard";
import { cn } from "../../../../../../util/cn";

type BuyCreditsProps = {
  currentSubscription?: string;
  isSignup?: boolean;
};

const BuyCredits = ({
  currentSubscription = "",
  isSignup = false,
}: BuyCreditsProps) => {
  const [selectedSubscription, setSelectedSubcription] = useState(null);

  const filteredSubscriptions = useMemo(() => {
    if (isSignup) {
      return subscriptions;
    }
    return subscriptions.filter((subscription) => {
      if (currentSubscription === "single") return true;

      return subscription.type !== currentSubscription;
    });
  }, [currentSubscription, isSignup]);

  return (
    <div
      className={cn("flex flex-col items-center overflow-hidden", {
        "px-10": isSignup,
      })}
    >
      <div
        className={cn("flex flex-row gap-[30px] overflow-x-auto w-full", {
          "justify-start pb-5": isSignup,
          "justify-center": !isSignup,
        })}
      >
        {filteredSubscriptions.map((subscription: any) => {
          return (
            <SubscriptionCard
              key={subscription.type}
              type={subscription.type}
              title={subscription.title}
              subTitle={subscription.subTitle}
              price={subscription.price}
              isSelected={selectedSubscription === subscription.type}
              onClick={() => setSelectedSubcription(subscription.type)}
            />
          );
        })}
      </div>

      <button
        className={cn(
          "mt-[40px] w-[260px] h-[60px] rounded-[70px] py-[20px] px-[70px] flex flex-row justify-center items-center text-[#FFFFFF]",
          {
            "bg-[#8AA0AE]": !selectedSubscription,
            "bg-[#15415E]": selectedSubscription,
          },
        )}
        disabled={!selectedSubscription}
      >
        Continue
      </button>
    </div>
  );
};

export default BuyCredits;

const subscriptions = [
  {
    type: "single",
    title: "Single",
    subTitle: "Job Posting in an year",
    price: "$299",
  },
  {
    type: "monthly",
    title: "Monthly",
    subTitle: "3 Job Posting in 30-day",
    price: "$599",
  },
  {
    type: "annual",
    title: "Annual",
    subTitle: "",
    price: "",
  },
];
