import { useState, forwardRef, ChangeEvent } from "react";
import { cn } from "../../../../../util/cn";

interface Option {
  id: string;
  code: string;
  label: string;
}

interface RadioGroupProps {
  options: Option[];
  value?: string;
  name: string;
  className: string;
  disabled?: boolean;
  onChange?: (value: string) => void;
  onBlur?: (value: string) => void;
  required?: boolean;
  error?: string;
  touched?: boolean;
}

const RadioGroup_Emp = forwardRef(
  (
    {
      options,
      value = "",
      name,
      className,
      disabled = false,
      onChange,
      onBlur,
      required,
      error,
      touched,
    }: RadioGroupProps,
    ref: any,
  ) => {
    {
      /*  @ts-ignore */
    }
    //   const [selection, setSelection] = useState<string>(value);
    {/*  @ts-ignore */}
    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
      const { value, checked } = e.target;
      if (checked) {
        //   setSelection(value);
        if (onChange) {
          onChange(value); // Invoke the callback with the selected value
        }
      }
    };

    const handleBlur = (e: any) => {
      if (onBlur) {
        onBlur(e);
      }
    };

    return (
      <>
        <div
          id={name}
          className={cn(
            "grid grid-cols-4 md:grid-cols-2 sm:grid-cols-1",
            className,
          )}
        >
          {options.map((item) => (
            <div key={item.id} className="flex items-start">
              <input
                className={cn(
                  "h-5 w-5 border-instant-teams-blue-Main focus:ring-2 focus:ring-instant-teams-blue-Main text-instant-teams-blue-Main focus:text-instant-teams-blue-Main",
                  {
                    "cursor-pointer": !disabled,
                  },
                  {
                    "cursor-not-allowed": disabled,
                  },
                )}
                id={item.id}
                name={name}
                type="radio"
                value={item.code}
                onChange={handleChange}
                onBlur={handleBlur}
                checked={value === item.code}
                disabled={disabled}
                ref={ref}
              />
              <label
                className={cn(
                  "mb-1 px-2 text-sm font-semibold text-instant-teams-blue-Main",
                  {
                    "cursor-pointer": !disabled,
                  },
                  {
                    "cursor-not-allowed": disabled,
                  },
                )}
                htmlFor={item.id}
              >
                {item.label}
              </label>
            </div>
          ))}
        </div>
        {error && (
          <p className={"text-[0.8rem] text-left font-medium text-[red] mt-1"}>{error}</p>
        )}
      </>
    );
  },
);

RadioGroup_Emp.displayName = "RadioGroup";

export default RadioGroup_Emp;
