import React, { useState, useRef, useEffect } from 'react';
import { useAuth } from '../../../../../providers/auth'; 
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';

const ProfilePopup = () => {
    const userName = localStorage.getItem("username")
    const [isOpen, setIsOpen] = useState(false);
    const popupRef = useRef(null);
    const auth = useAuth();
    
    const navigate = useNavigate();

    const togglePopup = () => {
        setIsOpen(!isOpen);
    };

    const handleLogout = () => {
        // Perform logout logic
        auth.signout();
        navigate('/auth/signin');
    };

console.log(auth,"user data567")

    const handleProfile = () => {
        if (auth.userClaims.claims.type === "S") {
            navigate("/settings/seeker/profile")
        } else {
           navigate("/settings/newprofile"); 
        }
        
    };

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (popupRef.current && !popupRef.current.contains(event.target)) {
                setIsOpen(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [popupRef]);

    return (
        <div className="relative">
            <div className='px-2 cursor-pointer' onClick={togglePopup}>
                <img
                    src={auth?.user?.photoURL || '/images/default_user.png'}
                    alt='Profile'
                    className='w-10 h-10 rounded-full object-cover'
                />
            </div>
            {isOpen && (
                <div ref={popupRef} className="absolute right-0 mt-2 w-auto min-w-[250px] bg-white shadow-xl rounded-md pb-3 z-50">
                    {/* Arrow tip */}
                    <div
                        className="absolute w-0 h-0 border-l-8 border-r-8 border-b-8 border-transparent -translate-x-1 border-b-white"
                        style={{ top: '-8px', right: '16px' }}
                    ></div>
                    <div className="p-4">
                        <p className="font-bold py-2 text-instant-teams-blue-Main text-xl">{auth?.user?.displayName||userName}</p>
                        <div className="mt-2">
                        <div className='flex w-full justify-between items-center'>
                                <p
                                    className="w-full border-b-[1px] pb-5 pt-1 text-left text-lg text-gray-600 cursor-pointer"
                                    onClick={handleProfile}
                                >
                                    My Profile
                                </p>
                                <FontAwesomeIcon icon={faChevronRight} className="ml-[-20px] text-sm mb-4 text-gray-500" /> {/* Add FontAwesome icon */}
                            </div>


                            <p
                                className="w-full text-left cursor-pointer text-gray-600  mt-3"
                                onClick={handleLogout}
                            >
                                Logout
                            </p>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default ProfilePopup;
