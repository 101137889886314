import { useMemo } from "react";
import { useNavigate } from "react-router-dom";
import HomeCard from "../Component/HomeCard/HomeCard";
import { useAuth } from "../../../../../providers/auth";
import { useEmployer } from "../../../../../data/employer";
import Meta from "../../../../custom-ui/meta/Meta";

type Home_NewUIProps = {};

const Home_NewUI = (props: Home_NewUIProps) => {
  const { id } = useAuth();
  const navigate = useNavigate();
  const { user } = useAuth();
  const { role } = useAuth();

  const employerId = user?.claims?.employerId;
  const { data: employer } = useEmployer(employerId ?? "");

  const isShowCompanyProfileCard = useMemo(() => {
    return (
      employer &&
      employer?.displayName &&
      employer?.displayName !== "" &&
      employer?.profile?.url &&
      employer?.profile?.url !== "" &&
      employer?.profile?.intro &&
      employer?.profile?.intro !== ""
    );
  }, [employer]);

  return (
    <>
      <Meta title="Home" />

      <div className="flex flex-col">
        <p className="font-[gilroy-bold] text-[32px] text-[#15415E] font-[400] leading-[39.62px]">
          Welcome to Instant Teams
        </p>
        <div className="flex flex-row mt-[62px] gap-[30px]">
          {role?.id === "PRI" && !isShowCompanyProfileCard && (
            <HomeCard
              isExclamation={true}
              imagePath="/images/update-company-profile.png"
              title="Update Company Profile"
              description="Showcase what makes you unique and
              why top, remarkable talent should join your
              team.Keep it fresh, engaging, and
              authentic to attract the best candidates."
              onClick={() => navigate(`/company-profile`)}
            />
          )}

          <HomeCard
            isExclamation={false}
            imagePath="/images/post-a-job.png"
            title="Post a Job"
            description="Create a compelling snapshot of your
            open position. This isn't a full job
            description – it's your chance to grab
            attention and spark interest. Our unique,
            quick process guides you step-by-step to
            highlight key details that can help attract
            great candidates"
            onClick={() => navigate(`/jobs/new`)}
          />
        </div>
      </div>
    </>
  );
};

export default Home_NewUI;
