import { useState, useEffect, Fragment } from "react";
import { Controller, useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import { useLookup } from "../../../../../../data/lookup";
import { JobsFormSchemaStep4 } from "./JobFormSchemaStep4";
import { useNavigate } from "react-router-dom";
import {
    getIsSubmittingJobFirstTime,
    updateEntryForJobDetailsNewCollection,
    useJobDetailsNewCollection
} from "../../../../../../data/jobDetailsNewCollection";

import GreenUnderlineHeading from "../../../../../custom-ui/headings/heading";
import { isLockedJobNew } from "../../../../../../util/jobs/jobUtils";
import Input from "../../../../../custom-ui/old-ui/Input";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import Wysiwyg from "../../../../../custom-ui/old-ui/Wysiwyg";
import SelectGeneric from "../../../../../custom-ui/old-ui/SelectGeneric";
import DateGeneric from "../../../../../custom-ui/old-ui/DateGeneric";
import { JobDetailsNewCollection } from "../../../../../../dataTypes/JobDetailsNewCollection";
import RadioGroup from "../../../../../custom-ui/old-ui/RadioGroup";
import toast from "react-hot-toast";
import ToastAlert from "../../../../../custom-ui/toast-alert/ToastAlert";
import Checkbox from "../../../../../custom-ui/old-ui/Checkbox";
import InputCurrency from "../../../../../custom-ui/old-ui/InputCurrency";
import TextArea from "../../../../../custom-ui/old-ui/TextArea";
import { useMemo } from "react";
import Tooltip from "../../../../../custom-ui/tooltip/tooltip";
import SliderWithCheckbox from "../../../../../custom-ui/old-ui/SliderWithCheckbox";
import FloatingLabelInput from "../../Component-UI/FloatPlaceholderInputField";
import InputCurrency_Emp from "../../Component-UI/InputCurrency_Emp";
import Input_Emp from "../../Component-UI/Input_Emp";
import RadioGroup_Emp from "../../Component-UI/RadioGroup_Emp";

function JobInformation({ currStep, setCurrentStep, setFormvalues, formvalues }: any) {
    const params = useParams();
    const jobId = params.id ?? "";
    const navigate = useNavigate();

    const [isInitialized, setIsInitialized] = useState(false);
    const [formSchema, setFormSchema] = useState(JobsFormSchemaStep4(false));

    const { data: job } = useJobDetailsNewCollection(jobId);
    const { data: workSetting = [] } = useLookup("workSetting");
    const { data: workType = [] } = useLookup("workType");
    const { data: workTerm = [] } = useLookup("workTerm");
    const { data: payRateTypes = [] } = useLookup("payRateType");
    const { data: solutionTypes = [] } = useLookup("solutionTypeNew");
    const { data: aspectsOfJob = [] } = useLookup("aspectsOfJob");
    const { data: aboutThisJob = [] } = useLookup("aboutThisJob");
    const { data: mustHaves = [] } = useLookup("mustHaves");

    const aboutThisJobOptions = useMemo(() => {
        return aboutThisJob.map((obj: any) => {
            const labelSplitArray = obj.label.split("<--->");

            const labelLeft = labelSplitArray[0];
            const labelRight = labelSplitArray[1];

            return {
                ...obj,
                labelLeft,
                labelRight,
            };
        });
    }, [aboutThisJob]);

    const isFieldLocked = isLockedJobNew(job?.jobState);

    const lockedJobStatusToolTipText = useMemo(() => {
        let jobState = job?.jobState;

        if (["closed_filled", "closed_cancelled"].includes(jobState)) {
            jobState = "closed";
        } else if (jobState === "on_hold") {
            jobState = "on hold";
        } else {
            jobState = "live";
        }
        return jobState;
    }, [job]);

    const SECTION_LOCKED_MESSAGE = `This section cannot be changed while the job status is ${lockedJobStatusToolTipText}.`;

    type FormValues = z.infer<ReturnType<typeof JobsFormSchemaStep4>>;

    const form = useForm<FormValues>({
        resolver: zodResolver(formSchema),
        defaultValues: {
            // jobName: "",
            // solutionType: "",
            // startDate: "",
            // openings: "",
            // applicationFlow: "",
            // workSettings: "",
            // workType: "",
            // workTerm: "",

            // hoursPerWeekMin: "",
            // hoursPerWeekMax: "",

            payRateType: formvalues?.payRateType||"",
            salaryMin:formvalues?.salaryMin|| "",
            hourlyMin: formvalues?.hourlyMin||"",
            hourlyMax:formvalues?.hourlyMax|| "",
            isCommission: formvalues?.isCommission||false,
            commission:formvalues?.commission|| "",
            unpaidInternship: formvalues?.unpaidInternship||"",
            salaryMax: formvalues?.salaryMax||"",
            

        },
        reValidateMode: "onBlur",
    });

    const isSubmittingJobFirstTime = useMemo(
        () => getIsSubmittingJobFirstTime(job),
        [job],
    );

    const {
        handleSubmit,
        setValue,
        getValues,
        control,
        reset,
        watch,
        formState: { errors, isSubmitting },
    } = form;

    // Watch for the value of payRateType
    const payRateType = watch("payRateType");
    // Watch for the value of isCommission
    const isCommission = watch("isCommission");
    // Watch for the value of aspectsOfJob


    const onSubmit = async ({ ...values }: FormValues) => {
        console.log(values)

        setFormvalues({
            ...formvalues,
            "payRateType": values.payRateType,
            "salaryMin": values.salaryMin||"",
            "salaryMax": values.salaryMax || "",
            "hourlyMax": values.hourlyMax || "",
            "hourlyMin":values.hourlyMin||"",
            "isCommission": values.isCommission||"",
            "commission": values.commission||""

        })
        setCurrentStep(currStep + 1)

        // const {
        //     jobSummary,
        //     whatsInItForYou,
        //     dayInLife,
        //     mustHaves,

        //     aspectsOfJob = [],
        //     aspectsOfJobOther = "",
        //     isAspectsOfJobOther,

        //     aboutThisJob = [],

        //     mustHavesCheckboxes = [],

        //     hoursPerWeekMin,
        //     hoursPerWeekMax,
        //     payRateType,
        //     salaryMin,
        //     salaryMax,
        //     hourlyMin,
        //     hourlyMax,
        //     commission,
        //     unpaidInternship,
        //     ...restValues
        // } = values;

        // const jobDetailsNewCollectionData: any = {
        //     ...restValues,
        //     // jobName: title,
        //     companyId: job.companyId,
        //     creationDate: job.creationDate,
        //     jobState: job.jobState,

        //     // giving null placeholder values to the fields which have not been filled yet
        //     // applicationFlow: null,
        //     applyNowCounter: 0,
        //     clicksCounter: 0,
        //     closedFilledBy: "",
        //     closedReason: "",
        //     companyFullName: job.companyFullName,
        //     companyLogo: job.companyLogo,
        //     companyHQ: job.companyHQ,
        //     hoursPerWeek: {
        //         min: Number(hoursPerWeekMin),
        //         max: Number(hoursPerWeekMax),
        //     },
        //     instantTeamJobs: null,
        //     makeLiveDate: job.makeLiveDate,
        //     openings: Number(values.openings),
        //     payRate: {
        //         type: payRateType,
        //         ...(["salary", "hourly", "commission"].includes(payRateType) &&
        //             commission !== "" &&
        //             commission !== undefined
        //             ? {
        //                 commission: commission,
        //             }
        //             : {
        //                 commission: null,
        //             }),
        //         ...(payRateType === "unpaid_internship" &&
        //             unpaidInternship !== "" &&
        //             unpaidInternship !== undefined
        //             ? {
        //                 unpaidInternship: unpaidInternship,
        //             }
        //             : {
        //                 unpaidInternship: null,
        //             }),
        //         ...(payRateType === "salary" &&
        //             salaryMin !== "" &&
        //             salaryMax !== "" &&
        //             salaryMin !== undefined &&
        //             salaryMax !== undefined
        //             ? {
        //                 salary: {
        //                     min: Number(salaryMin),
        //                     max: Number(salaryMax),
        //                 },
        //             }
        //             : {
        //                 salary: null,
        //             }),
        //         ...(payRateType === "hourly" &&
        //             hourlyMin !== "" &&
        //             hourlyMax !== "" &&
        //             hourlyMin !== undefined &&
        //             hourlyMax !== undefined
        //             ? {
        //                 hourly: {
        //                     min: Number(hourlyMin),
        //                     max: Number(hourlyMax),
        //                 },
        //             }
        //             : {
        //                 hourly: null,
        //             }),
        //     },
        // };


    };

    const setIntialValuesToForm = (jobData: any) => {
        if (!jobData || (jobData && !Object.keys(jobData).length)) return;
        setValue("payRateType", jobData?.payRate?.type ?? "");
        setValue("jobName", jobData?.jobName ?? "");
        setValue("solutionType", job?.solutionType ?? "");
        setValue("startDate", job?.startDate?.toDate() ?? "");
        setValue("openings", jobData?.openings ?? "");
        setValue("applicationFlow", jobData?.applicationFlow ?? "");
        setValue("workSettings", jobData?.workSettings ?? "");
        setValue("workTerm", jobData?.workTerm ?? "");
        setValue("workType", jobData?.workType ?? "");
        setValue("hoursPerWeekMin", jobData?.hoursPerWeek?.min ?? "");
        setValue("hoursPerWeekMax", jobData?.hoursPerWeek?.max ?? "");
        setValue("aspectsOfJob", jobData?.jobDescription?.aspectsOfJob ?? []);
        setValue("jobSummary", jobData?.jobDescription?.jobSummary ?? "");
        setValue("whatsInItForYou", jobData?.jobDescription?.whatsInItForYou ?? "");
        setValue("dayInLife", jobData?.jobDescription?.dayInLife ?? "");

        if (
            jobData?.jobDescription?.dayInLife &&
            jobData?.jobDescription?.dayInLife.length
        ) {
            setValue("jobSummary", jobData?.jobDescription?.dayInLife ?? "");
        }

        setValue("mustHaves", jobData?.jobDescription?.mustHaves ?? "");

        setTimeout(() => {
            if (jobData?.payRate?.type === "salary") {
                setValue("salaryMin", String(jobData?.payRate?.salary?.min) ?? "");
                setValue("salaryMax", String(jobData?.payRate?.salary?.max) ?? "");
                setValue("commission", jobData?.payRate?.commission ?? null);
            } else if (jobData?.payRate?.type === "hourly") {
                setValue("hourlyMin", String(jobData?.payRate?.hourly?.min) ?? "");
                setValue("hourlyMax", String(jobData?.payRate?.hourly?.max) ?? "");
                setValue("commission", jobData?.payRate?.commission ?? null);
            } else if (jobData?.payRate?.type === "commission") {
                setValue("commission", jobData?.payRate?.commission ?? "");
            } else if (jobData?.payRate?.type === "unpaid_internship") {
                setValue("unpaidInternship", jobData?.payRate?.unpaidInternship ?? "");
            }

            setTimeout(() => {
                setValue(
                    "isCommission",
                    jobData?.payRate?.commission?.length ? true : false,
                );

                setTimeout(() => {
                    if (jobData?.payRate?.type === "salary") {
                        setValue("commission", jobData?.payRate?.commission ?? null);
                    } else if (jobData?.payRate?.type === "hourly") {
                        setValue("commission", jobData?.payRate?.commission ?? null);
                    }

                    setValue("aspectsOfJob", jobData?.jobDescription?.aspectsOfJob ?? []);

                    setValue("aboutThisJob", jobData?.jobDescription?.aboutThisJob ?? []);

                    setValue(
                        "mustHavesCheckboxes",
                        jobData?.jobDescription?.mustHavesCheckboxes ?? [],
                    );

                    if (jobData?.jobDescription?.aspectsOfJobOther?.length) {
                        setValue("isAspectsOfJobOther", true);

                        setTimeout(() => {
                            setValue(
                                "aspectsOfJobOther",
                                jobData?.jobDescription?.aspectsOfJobOther ?? "",
                            );
                        }, 100);
                    } else {
                        setValue("isAspectsOfJobOther", false);
                    }
                }, 100);
            }, 500);
        }, 1000);
    };

    const handleAddAboutThisJob = (
        code: string,
        labelLeft: string,
        labelRight: string,
        sliderValue: string,
        remove: boolean,
    ) => {

    };

    useEffect(() => {
        if (job && !isInitialized) {
            setIntialValuesToForm(job);
            setIsInitialized(true);
        }
    }, [job, isInitialized]);

    // Handle the statusId change and dynamically update the schema
    useEffect(() => {
        if (isSubmittingJobFirstTime) {
            const newSchema = JobsFormSchemaStep4(isSubmittingJobFirstTime);
            setFormSchema(newSchema);
            setIntialValuesToForm(job);
        } else {
            const newSchema = JobsFormSchemaStep4(false);
            setFormSchema(newSchema);
        }
    }, [isSubmittingJobFirstTime, reset]);

    // if (!job) return <></>;

    // const fieldValues = getValues();
    // console.log(errors, "============>errors");
    // console.log(fieldValues, "============>fieldValues");

    return (
        <>
            <form onSubmit={handleSubmit(onSubmit)}>
            <div className="w-[60vw] flex flex-col justify-center items-center ">
            <div className="w-[50vw]  ">
                        <div className="grid gap-1 px-10 grid-cols-1 md:grid-cols-1 mb-4 ">
                          
                            <h1 className="text-instant-teams-blue-Main text-[18px] py-3 pb-4 flex font-[400] mt-2">
                                Pay Rate<span className="text-red-500 text-base ml-1">*</span>
                            </h1>

                            <Controller
                                control={control}
                                name="payRateType"
                                render={(field) => (
                                    <RadioGroup_Emp
                                        {...field}
                                        options={payRateTypes}
                                        disabled={isFieldLocked}
                                        error={errors?.payRateType?.message}
                                        required
                                    />
                                )}
                            />

                            {payRateType === "salary" && (
                                <div className="flex mt-2 flex-col">
                                    <div className="flex w-[71%] mt-4">
                                        <Controller
                                            control={control}
                                            name="salaryMin"
                                            render={(field) => {
                                                return (
                                                    <InputCurrency_Emp
                                                        {...field}
                                                        display="Min"
                                                        placeholder="Min"
                                                        readOnly={isFieldLocked}
                                                        error={errors?.salaryMin?.message}
                                                        required
                                                    />
                                                );
                                            }}
                                        />

                                        <Controller
                                            control={control}
                                            name="salaryMax"
                                            render={(field) => {
                                                const { value, onChange, ...restField } = field;

                                                return (
                                                    <InputCurrency_Emp
                                                        {...field}
                                                        display="Max"
                                                        placeholder="Max"
                                                        readOnly={isFieldLocked}
                                                        error={errors?.salaryMax?.message}
                                                        required
                                                    />
                                                );
                                            }}
                                        />

                                    </div>
                                    <div className="flex   mt-2">

                                    </div>
                                    <div className="flex flex-col gap-[20px] mt-2">
                                        <Controller
                                            control={control}
                                            name="isCommission"
                                            render={(field) => (
                                                <Checkbox
                                                    {...field}
                                                    id="isCommissionForSalary"
                                                    label="Commission"
                                                    disabled={isFieldLocked}
                                                    error={errors?.commission?.message}
                                                    required
                                                />
                                            )}
                                        />
                                        {isCommission && (
                                            <div className="mb-2">
                                                {/* <Controller
                          control={control}
                          name="commission"
                          render={(field) => (
                            <TextArea
                              {...field}
                              display="Commission"
                              placeholder="Enter Commission"
                              readOnly={isFieldLocked}
                              error={errors?.commission?.message}
                              required
                            />
                          )}
                        /> */}

                                                <Controller
                                                    control={control}
                                                    name="commission"
                                                    render={(field) => (
                                                        <Wysiwyg
                                                            {...field}
                                                            rows={10}
                                                            display="Commission"
                                                            readOnly={isFieldLocked}
                                                            placeholder={"Enter Commission"}
                                                            directions=""
                                                            editorStyle={{ height: "240px" }}
                                                            error={errors?.commission?.message}
                                                            required
                                                        />
                                                    )}
                                                />
                                            </div>
                                        )}
                                    </div>
                                </div>
                            )}

                            {payRateType === "hourly" && (
                                <div className="flex mt-2 flex-col">
                                    <div className="flex w-[71%] mt-4">
                                        <Controller
                                            control={control}
                                            name="hourlyMin"
                                            render={(field) => {
                                                return (
                                                    <InputCurrency_Emp
                                                        {...field}
                                                        display="Min"
                                                        placeholder="Min"
                                                        readOnly={isFieldLocked}
                                                        error={errors?.hourlyMin?.message}
                                                        required
                                                    />
                                                );
                                            }}
                                        />
                                        <Controller
                                            control={control}
                                            name="hourlyMax"
                                            render={(field) => {
                                                return (
                                                    <InputCurrency_Emp
                                                        {...field}
                                                        display="Max"
                                                        placeholder="Max"
                                                        readOnly={isFieldLocked}
                                                        error={errors?.hourlyMax?.message}
                                                        required
                                                    />
                                                );
                                            }}
                                        />
                                    </div>
                                    <div className="flex flex-col gap-[20px] mt-4">
                                        <Controller
                                            control={control}
                                            name="isCommission"
                                            render={(field) => (
                                                <Checkbox
                                                    {...field}
                                                    id="isCommissionForHourly"
                                                    label="Commission"
                                                    disabled={isFieldLocked}
                                                    error={errors?.commission?.message}
                                                    required
                                                />
                                            )}
                                        />
                                        {isCommission && (
                                            <div className="mb-2">
                                                {/* <Controller
                          control={control}
                          name="commission"
                          render={(field) => (
                            <TextArea
                              {...field}
                              display="Commission"
                              placeholder="Enter Commission"
                              readOnly={isFieldLocked}
                              error={errors?.commission?.message}
                              required
                            />
                          )}
                        /> */}

                                                <Controller
                                                    control={control}
                                                    name="commission"
                                                    render={(field) => (
                                                        <Wysiwyg
                                                            {...field}
                                                            rows={10}
                                                            display="Commission"
                                                            readOnly={isFieldLocked}
                                                            placeholder={"Enter Commission"}
                                                            directions=""
                                                            editorStyle={{ height: "240px" }}
                                                            error={errors?.commission?.message}
                                                            required
                                                        />
                                                    )}
                                                />
                                            </div>
                                        )}
                                    </div>
                                </div>
                            )}

                            {payRateType === "commission" && (
                                <div className="mt-2">
                                    {/* <Controller
                    control={control}
                    name="commission"
                    render={(field) => (
                      <TextArea
                        {...field}
                        display="Commission"
                        placeholder="Enter Commission"
                        readOnly={isFieldLocked}
                        error={errors?.commission?.message}
                        required
                      />
                    )}
                  /> */}

                                    <Controller
                                        control={control}
                                        name="commission"
                                        render={(field) => (
                                            <Wysiwyg
                                                {...field}
                                                rows={10}
                                                display="Commission"
                                                readOnly={isFieldLocked}
                                                placeholder={"Enter Commission"}
                                                directions=""
                                                editorStyle={{ height: "240px" }}
                                                error={errors?.commission?.message}
                                                required
                                            />
                                        )}
                                    />
                                </div>
                            )}

                            {payRateType === "unpaid_internship" && (
                                <div className="mt-2">
                                    {/* <Controller
                    control={control}
                    name="unpaidInternship"
                    render={(field) => (
                      <TextArea
                        {...field}
                        display="Free Internship"
                        placeholder="Enter Free Internship"
                        readOnly={isFieldLocked}
                        error={errors?.unpaidInternship?.message}
                        required
                      />
                    )}
                  /> */}
                                </div>
                            )}



                            {/* job description Begins */}
                        </div>
                    </div>
                </div>

                <div className="mt-24 w-full flex flex-row justify-between ">
                            <button
                                type="button"
                                disabled={isSubmitting}
                                className="h-[50px] w-[210px] rounded-[70px] border-[1px] border-[#15415E] px-5 py-3 hover:border-[#1d5174] text-white bg-[#15415E] text-[16px] leading-[19.6px] font-[400]"
                                onClick={() => { reset(); navigate("/jobs") }}
                            >
                                Cancel
                            </button>

                            <div className="flex justify-center items-center">
                                <button

                                    onClick={() => setCurrentStep(currStep - 1)}
                                    className="h-[50px] w-[210px] mr-5 rounded-[70px] border-[1px] border-[#15415E] px-5 py-3 hover:border-[#1d5174] text-[#15415E] text-[16px] leading-[19.6px] font-[400]"
                                    >
                                    Back
                                </button>
                                <button
                                    type="submit"
                                    disabled={isSubmitting}
                                    className="h-[50px] w-[210px] rounded-[70px] bg-[#15415E] px-5 py-3 hover:bg-[#1d5174] text-[#FFFFFF] text-[16px] leading-[19.6px] font-[400]"
                                >
                                    {isSubmitting ? "Saving..." : "Next"}
                                </button>
                            </div>


                        </div>
            </form>
            <ToastAlert />
        </>
    );
}

export default JobInformation;
