import { useEffect, useState } from "react";
import { multiFactor } from "firebase/auth";
import { useAuth } from "../../../providers/auth";
import PhoneNumber from "../../custom-ui/input-fields/phoneNumber";
import EnrolledFactors from "./EnrolledFactors";
import MultiFactorEnrollment from "./MultiFactorEnrollment";
import MultiFactorEnrollment_empNewUI from "./MultifactorEnroll_empNewUI";
import { friendlyFirebaseError } from "../../../providers/firebase-errors/friendlyFirebaseError";
import { errorLogger } from "../../../providers/clientLogger";
import ButtonWrapper from "../../employer/details/New_Ui/Component-UI/Button";
import DialogWrapper from "../../custom-ui/dialog/Dialog";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faTrashCan } from '@fortawesome/free-solid-svg-icons';
import { useForm } from "react-hook-form";

function SettingsMFA_newUi({
  onStatus,
  buttonVariant,
  showChangeNumberButton = true,
}: {
  onStatus: Function;
  buttonVariant?: string;
  showChangeNumberButton?: boolean;
}) {
  const auth = useAuth();
  const { user } = auth;
  const { handleSubmit, register, errors, setValue, getValues, control } = useForm();
  const [triggerRefreshUser, setTriggerRefreshUser] = useState(true);
  const [openModal, setOpenModal] = useState(true);
  const toggleTrigger = () =>
    setTriggerRefreshUser((prevState: boolean) => !prevState);

  const [multiFactorUser, setMultiFactorUser] = useState();
  useEffect(() => {
    setMultiFactorUser(multiFactor(user?.auth?.currentUser));
  }, [triggerRefreshUser, user]);

  
  const { enrolledFactors = [] } = multiFactorUser ?? {};
  const enrolledPhoneNumber = enrolledFactors?.[0]?.phoneNumber ?? "";

  const unenroll = () => {
    multiFactorUser
      .unenroll(enrolledFactors[0])
      .then(() => {
        toggleTrigger();
      })
      .catch((error: any) => {
        onStatus({
          type: "error",
          code: error.code,
          callback: () => { },
          message: `${friendlyFirebaseError(error)}`,
        });
      });
  };

  return (
    <>
      {enrolledFactors[0] && (
        <div className="my-3 mt-5 w-full flex justify-center">

        
            <ButtonWrapper
              onClick={unenroll}
            >
              Change Number
            </ButtonWrapper>
        
        </div>
      )}
      {!enrolledFactors[0] &&openModal&&(
        <DialogWrapper title="" onClose={() => { }} >

          <>
          <div className="flex justify-between items-center  mb-5">
            <div>
              <h1 className="text-base font-bold text-instant-teams-blue-Main">
              Change Phone Number
              </h1>
            </div>
            <div
              className="flex  justify-center items-center w-7 h-7 bg-gray-200 rounded-full cursor-pointer"
                onClick={() => { setOpenModal(false); unenroll();showChangeNumberButton=true }}
            >
              <FontAwesomeIcon icon={faTimes} color="#565656" />
            </div>
          </div>
            <div className="mt-4 flex items-center">
              <MultiFactorEnrollment_empNewUI
                onStatus={onStatus}
                callback={toggleTrigger}
                buttonVariant={buttonVariant}
              />
            </div>
          </>
        </DialogWrapper>
      )}
    </>
  );
}

export default SettingsMFA_newUi;
