import { z } from "zod";

export const employerInfoSchema = z.object({
  companyName: z.string({ required_error: "Please enter Company Name" }),
  companyUrl: z
    .string({ required_error: "Please enter Company Website." })
    // .url({message: "Please enter Company Website."}),
    .nonempty("Please enter Company Website."),
  domain: z
    .string({ required_error: "Please enter Company Email Domain." })
    .nonempty("Please enter Company Email Domain.")
    .regex(
      /^[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
      "Please enter a valid Company Email Domain (e.g., example.com)",
    ),
  companySize: z.string(),
  jobsToFill: z.string(),
  hiringNeeds: z.string(),
});

export const employerUserSchema = z
  .object({
    email: z
      .string()
      // .email({ message: "Please enter a valid email address. Example: username@example.com." }),
      .refine((value: any) => /^[a-zA-Z0-9._%+-]+$/.test(value), {
        message:
          "Please enter a valid email address Prefix. Example: username@example.com.",
      }),
    name: z
      .string({ required_error: "Please enter a full name." })
      .nonempty("Please enter a full name."),
    title: z.string({ required_error: "Please enter a full name." }),
    phone: z.string().refine((value: any) => /^\+?[1-9]\d{9,14}$/.test(value), {
      message:
        "Please enter a valid phone number. The format should be [+][country code][number].",
    }),
    password: z
      .string()
      .min(
        8,
        "Password must be at least 8 characters long and contain at least one uppercase letter.",
      )
      .refine((value) => /[A-Z]/.test(value), {
        message: "Password must contain at least one uppercase letter",
      })
      .refine((value) => /[a-z]/.test(value), {
        message: "Password must contain at least one lowercase letter",
      })
      .refine((value) => /[0-9!@#$%^&*]/.test(value), {
        message:
          "Password must contain at least one number or special character",
      }),
    confirmPassword: z.string().min(1, "Password confirmation is required"),
    terms: z.literal(true, {
      errorMap: () => ({ message: "You must accept the terms & conditions." }),
    }),
  })
  .refine((data) => data?.password === data?.confirmPassword, {
    path: ["confirmPassword"], // Highlight the confirmPassword field
    message: "Passwords do not match",
  });

export const pwdSchema = z
  .string()
  .min(1, "Password is required")
  .refine((value) => value.length >= 8, {
    message: "Password at least 8 characters",
    path: ["minChar"],
  })
  .refine((value) => /[A-Z]/.test(value), {
    message: "Password must contain at least 1 capital letter",
    path: ["capital"],
  })
  .refine((value) => /[a-z]/.test(value), {
    message: "Password must contain at least 1 lowercase letter",
    path: ["lower"],
  })
  .refine((value) => /[0-9!@#$%^&*]/.test(value), {
    message: "Password must contain at least 1 number or special character",
    path: ["special"],
  });

export const PASSWORD_REQ = {
  minChar: "At least 8 characters",
  capital: "At least one capital letter",
  lower: "At least one lower case letter",
  special: "At least one number or symbol",
};

export const employerGeneralUserOnboardingSchema = z
  .object({
    email: z.string({ required_error: "Please enter Email" }),
    // .email({ message: "Please enter a valid email address. Example: username@example.com." }),
    // .refine((value: any) => /^[a-zA-Z0-9._%+-]+$/.test(value), {
    //   message:
    //     "Please enter a valid email address Prefix. Example: username@example.com.",
    // }),
    company: z.string({ required_error: "Please enter Company Name" }),

    name: z
      .string({ required_error: "Please enter a full name." })
      .nonempty("Please enter a full name."),
    title: z.string({ required_error: "Please enter a full name." }),
    phone: z.string().refine((value: any) => /^\+?[1-9]\d{9,14}$/.test(value), {
      message:
        "Please enter a valid phone number. The format should be [+][country code][number].",
    }),
    password: z
      .string()
      .min(
        8,
        "Password must be at least 8 characters long and contain at least one uppercase letter.",
      )
      .refine((value) => /[A-Z]/.test(value), {
        message: "Password must contain at least one uppercase letter",
      })
      .refine((value) => /[a-z]/.test(value), {
        message: "Password must contain at least one lowercase letter",
      })
      .refine((value) => /[0-9!@#$%^&*]/.test(value), {
        message:
          "Password must contain at least one number or special character",
      }),
    confirmPassword: z.string().min(1, "Password confirmation is required"),
    terms: z.literal(true, {
      errorMap: () => ({ message: "You must accept the terms & conditions." }),
    }),
  })
  .refine((data) => data?.password === data?.confirmPassword, {
    path: ["confirmPassword"], // Highlight the confirmPassword field
    message: "Passwords do not match",
  });

export const employerBillingSchema = z.object({
  email: z
    .string()
    .email({
      message:
        "Please enter a valid email address. Example: username@example.com.",
    }),
  name: z
    .string({ required_error: "Please enter a full name." })
    .nonempty("Please enter a full name."),
  title: z.string({ required_error: "Please enter Designation" }),
  phone: z.string().refine((value: any) => /^\+?[1-9]\d{9,14}$/.test(value), {
    message:
      "Please enter a valid phone number. The format should be [+][country code][number].",
  }),
});
