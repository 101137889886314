import { useState, useEffect, Fragment } from "react";
import { Controller, useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import { useLookup } from "../../../../../../data/lookup";
import { getIsSubmittingJobFirstTime } from "../../../../../../data/jobDetailsNewCollection";
import GreenUnderlineHeading from "../../../../../custom-ui/headings/heading";
import { isLockedJobNew } from "../../../../../../util/jobs/jobUtils";
import Input from "../../../../../custom-ui/old-ui/Input";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import { jobFormSchemaStep3 } from "./JobFormSchemaStep3";
import Wysiwyg from "../../../../../custom-ui/old-ui/Wysiwyg";
import SelectGeneric from "../../../../../custom-ui/old-ui/SelectGeneric";
import DateGeneric from "../../../../../custom-ui/old-ui/DateGeneric";
import { JobDetailsNewCollection } from "../../../../../../dataTypes/JobDetailsNewCollection";
import RadioGroup from "../../../../../custom-ui/old-ui/RadioGroup";
import toast from "react-hot-toast";
import ToastAlert from "../../../../../custom-ui/toast-alert/ToastAlert";
import Checkbox from "../../../../../custom-ui/old-ui/Checkbox";
import { useMemo } from "react";
import FloatingLabelInput from "../../Component-UI/FloatPlaceholderInputField";
import InputCurrency_Emp from "../../Component-UI/InputCurrency_Emp";
import Input_Emp from "../../Component-UI/Input_Emp";
import RadioGroup_Emp from "../../Component-UI/RadioGroup_Emp";
import { useJobDetailsNewCollection } from "../../../../../../data/jobDetailsNewCollection";
import { useNavigate } from "react-router-dom";



function AddJobStep3({ currStep, setCurrentStep, setFormvalues, formvalues,toggle }: any) {
    const params = useParams();
    const navigate = useNavigate()
    const jobId = params.id ?? "vUN2qyluYqwzeaEmduqU";

    const [isInitialized, setIsInitialized] = useState(false);
    const [formSchema, setFormSchema] = useState(jobFormSchemaStep3(false));

    const { data: job } = useJobDetailsNewCollection(jobId);
    const { data: workSetting = [] } = useLookup("workSetting");
    const { data: workType = [] } = useLookup("workType");
    const { data: workTerm = [] } = useLookup("workTerm");
    const { data: payRateTypes = [] } = useLookup("payRateType");
    // const { data: solutionTypes = [] } = useLookup("solutionTypeNew");
    const { data: aspectsOfJob = [] } = useLookup("aspectsOfJob");
    const { data: aboutThisJob = [] } = useLookup("aboutThisJob");
    const { data: mustHaves = [] } = useLookup("mustHaves");

    const aboutThisJobOptions = useMemo(() => {
        return aboutThisJob.map((obj: any) => {
            const labelSplitArray = obj.label.split("<--->");

            const labelLeft = labelSplitArray[0];
            const labelRight = labelSplitArray[1];

            return {
                ...obj,
                labelLeft,
                labelRight,
            };
        });
    }, [aboutThisJob]);

    const isFieldLocked = isLockedJobNew(job?.jobState);

    const lockedJobStatusToolTipText = useMemo(() => {
        let jobState = job?.jobState;

        if (["closed_filled", "closed_cancelled"].includes(jobState)) {
            jobState = "closed";
        } else if (jobState === "on_hold") {
            jobState = "on hold";
        } else {
            jobState = "live";
        }
        return jobState;
    }, [job]);

    const SECTION_LOCKED_MESSAGE = `This section cannot be changed while the job status is ${lockedJobStatusToolTipText}.`;

    type FormValues = z.infer<ReturnType<typeof jobFormSchemaStep3>>;

    const form = useForm<FormValues>({
        resolver: zodResolver(formSchema),
        defaultValues: {
            // jobName: "",
            // solutionType: "",
            // startDate: "",
            // openings: "",
            // applicationFlow: "",
            workSettings:formvalues?.workSettings ||"",
            workType: formvalues?.workType ||"",
            workTerm: formvalues?.workTerm ||"",

            hoursPerWeekMin:formvalues?.hoursPerWeekMin || "",
            hoursPerWeekMax:formvalues?.hoursPerWeekMax || "",

            // payRateType: "",
            // salaryMin: "",
            // salaryMax: "",
            // hourlyMin: "",
            // hourlyMax: "",
            // isCommission: false,
            // commission: "",
            // unpaidInternship: "",

        },
        reValidateMode: "onBlur",
    });

    const isSubmittingJobFirstTime = useMemo(
        () => getIsSubmittingJobFirstTime(job),
        [job],
    );

    const {
        handleSubmit,
        setValue,
        getValues,
        control,
        reset,
        watch,
        formState: { errors, isSubmitting },
    } = form;

    // Watch for the value of payRateType
    const payRateType = watch("payRateType");
    // Watch for the value of isCommission
    const isCommission = watch("isCommission");
    // Watch for the value of aspectsOfJob


    const onSubmit = async ({ ...values }: FormValues) => {
        setFormvalues({
            ...formvalues,
            "workSettings": values.workSettings,
            "workType": values.workType,
            "workTerm": values.workTerm,
            "hoursPerWeekMin": values.hoursPerWeekMin,
            "hoursPerWeekMax": values.hoursPerWeekMax
        })
        setCurrentStep(currStep + 1)
       
        //     jobSummary,
        //     whatsInItForYou,
        //     dayInLife,
        //     mustHaves,

        //     aspectsOfJob = [],
        //     aspectsOfJobOther = "",
        //     isAspectsOfJobOther,

        //     aboutThisJob = [],

        //     mustHavesCheckboxes = [],

        //     hoursPerWeekMin,
        //     hoursPerWeekMax,
        //     payRateType,
        //     salaryMin,
        //     salaryMax,
        //     hourlyMin,
        //     hourlyMax,
        //     commission,
        //     unpaidInternship,
        //     ...restValues
        // } = values;

        // const jobDetailsNewCollectionData: any = {
        //     ...restValues,
        //     // jobName: title,
        //     companyId: job.companyId,
        //     creationDate: job.creationDate,
        //     jobState: job.jobState,

        //     // giving null placeholder values to the fields which have not been filled yet
        //     // applicationFlow: null,
        //     applyNowCounter: 0,
        //     clicksCounter: 0,
        //     closedFilledBy: "",
        //     closedReason: "",
        //     companyFullName: job.companyFullName,
        //     companyLogo: job.companyLogo,
        //     companyHQ: job.companyHQ,
        //     hoursPerWeek: {
        //         min: Number(hoursPerWeekMin),
        //         max: Number(hoursPerWeekMax),
        //     },
        //     instantTeamJobs: null,
        //     makeLiveDate: job.makeLiveDate,
        //     openings: Number(values.openings),
        //     payRate: {
        //         type: payRateType,
        //         ...(["salary", "hourly", "commission"].includes(payRateType) &&
        //             commission !== "" &&
        //             commission !== undefined
        //             ? {
        //                 commission: commission,
        //             }
        //             : {
        //                 commission: null,
        //             }),
        //         ...(payRateType === "unpaid_internship" &&
        //             unpaidInternship !== "" &&
        //             unpaidInternship !== undefined
        //             ? {
        //                 unpaidInternship: unpaidInternship,
        //             }
        //             : {
        //                 unpaidInternship: null,
        //             }),
        //         ...(payRateType === "salary" &&
        //             salaryMin !== "" &&
        //             salaryMax !== "" &&
        //             salaryMin !== undefined &&
        //             salaryMax !== undefined
        //             ? {
        //                 salary: {
        //                     min: Number(salaryMin),
        //                     max: Number(salaryMax),
        //                 },
        //             }
        //             : {
        //                 salary: null,
        //             }),
        //         ...(payRateType === "hourly" &&
        //             hourlyMin !== "" &&
        //             hourlyMax !== "" &&
        //             hourlyMin !== undefined &&
        //             hourlyMax !== undefined
        //             ? {
        //                 hourly: {
        //                     min: Number(hourlyMin),
        //                     max: Number(hourlyMax),
        //                 },
        //             }
        //             : {
        //                 hourly: null,
        //             }),
        //     },
        // };

        // await updateEntryForJobDetailsNewCollection(
        //     jobId,
        //     jobDetailsNewCollectionData,
        // )
        //     .then((data) => {
        //         toast.success("Job (New) updated successfully");
        //     })
        //     .catch(() => {
        //         toast.error("There was an error updating Job (New)");
        //     });
    };

    const setIntialValuesToForm = (jobData: any) => {
        if (!jobData || (jobData && !Object.keys(jobData).length)) return;
        setValue("payRateType", jobData?.payRate?.type ?? "");
        setValue("jobName", jobData?.jobName ?? "");
        // setValue("solutionType", job?.solutionType ?? "");
        // setValue("startDate", job?.startDate?.toDate() ?? "");
        setValue("openings", jobData?.openings ?? "");
        setValue("applicationFlow", jobData?.applicationFlow ?? "");
        setValue("workSettings", jobData?.workSettings ?? "");
        setValue("workTerm", jobData?.workTerm ?? "");
        setValue("workType", jobData?.workType ?? "");
        setValue("hoursPerWeekMin", jobData?.hoursPerWeek?.min ?? "");
        setValue("hoursPerWeekMax", jobData?.hoursPerWeek?.max ?? "");
        setValue("aspectsOfJob", jobData?.jobDescription?.aspectsOfJob ?? []);
        setValue("jobSummary", jobData?.jobDescription?.jobSummary ?? "");
        setValue("whatsInItForYou", jobData?.jobDescription?.whatsInItForYou ?? "");
        setValue("dayInLife", jobData?.jobDescription?.dayInLife ?? "");

        if (
            jobData?.jobDescription?.dayInLife &&
            jobData?.jobDescription?.dayInLife.length
        ) {
            setValue("jobSummary", jobData?.jobDescription?.dayInLife ?? "");
        }

        setValue("mustHaves", jobData?.jobDescription?.mustHaves ?? "");

        setTimeout(() => {
            if (jobData?.payRate?.type === "salary") {
                setValue("salaryMin", String(jobData?.payRate?.salary?.min) ?? "");
                setValue("salaryMax", String(jobData?.payRate?.salary?.max) ?? "");
                setValue("commission", jobData?.payRate?.commission ?? null);
            } else if (jobData?.payRate?.type === "hourly") {
                setValue("hourlyMin", String(jobData?.payRate?.hourly?.min) ?? "");
                setValue("hourlyMax", String(jobData?.payRate?.hourly?.max) ?? "");
                setValue("commission", jobData?.payRate?.commission ?? null);
            } else if (jobData?.payRate?.type === "commission") {
                setValue("commission", jobData?.payRate?.commission ?? "");
            } else if (jobData?.payRate?.type === "unpaid_internship") {
                setValue("unpaidInternship", jobData?.payRate?.unpaidInternship ?? "");
            }

            setTimeout(() => {
                setValue(
                    "isCommission",
                    jobData?.payRate?.commission?.length ? true : false,
                );

                setTimeout(() => {
                    if (jobData?.payRate?.type === "salary") {
                        setValue("commission", jobData?.payRate?.commission ?? null);
                    } else if (jobData?.payRate?.type === "hourly") {
                        setValue("commission", jobData?.payRate?.commission ?? null);
                    }

                    setValue("aspectsOfJob", jobData?.jobDescription?.aspectsOfJob ?? []);

                    setValue("aboutThisJob", jobData?.jobDescription?.aboutThisJob ?? []);

                    setValue(
                        "mustHavesCheckboxes",
                        jobData?.jobDescription?.mustHavesCheckboxes ?? [],
                    );

                    if (jobData?.jobDescription?.aspectsOfJobOther?.length) {
                        setValue("isAspectsOfJobOther", true);

                        setTimeout(() => {
                            setValue(
                                "aspectsOfJobOther",
                                jobData?.jobDescription?.aspectsOfJobOther ?? "",
                            );
                        }, 100);
                    } else {
                        setValue("isAspectsOfJobOther", false);
                    }
                }, 100);
            }, 500);
        }, 1000);
    };

    const handleAddAboutThisJob = (
        code: string,
        labelLeft: string,
        labelRight: string,
        sliderValue: string,
        remove: boolean,
    ) => {

    };

    useEffect(() => {
        if (job && !isInitialized) {
            setIntialValuesToForm(job);
            setIsInitialized(true);
        }
    }, [job, isInitialized]);


    useEffect(() => {
        if (isSubmittingJobFirstTime) {
            const newSchema = jobFormSchemaStep3(isSubmittingJobFirstTime);
            setFormSchema(newSchema);
            setIntialValuesToForm(job);
        } else {
            const newSchema = jobFormSchemaStep3(false);
            setFormSchema(newSchema);
        }
    }, [isSubmittingJobFirstTime, reset]);

    // if (!job) return <></>;

    return (
        <>
            <form onSubmit={handleSubmit(onSubmit)}>
                <div className="w-[60vw] flex flex-col justify-center items-center ">
                    <div className="w-[50vw] flex justify-center  ">
                        <div className=" ml-20 text-center space-y-2">
                            <h1 className="text-instant-teams-blue-Main text-[18px] py-3 pb-4 flex font-[400] mt-2">
                                Work Settings <span className="text-red-500 text-base ml-1">*</span>
                            </h1>
                            <Controller
                                control={control}
                                name="workSettings"
                                render={(field) => (
                                    <RadioGroup_Emp
                                        {...field}
                                        options={workSetting}
                                        disabled={isFieldLocked}
                                        error={errors?.workSettings?.message}
                                        required
                                    />
                                )}
                            />
<div className="border-b-[2px] border-gray-100 h-2 my-2 "></div>
                            <h1 className="text-instant-teams-blue-Main text-[18px] py-3 pb-4 flex font-[400] mt-2">
                                Work Type <span className="text-red-500 text-base ml-1">*</span>
                            </h1>
                            <Controller
                                control={control}
                                name="workType"
                                render={(field) => (
                                    <RadioGroup_Emp
                                        {...field}
                                        options={workType}
                                        disabled={isFieldLocked}
                                        error={errors?.workType?.message}
                                        required
                                    />
                                )}
                            />
                            <div className="border-b-[2px] border-gray-100 h-2 my-2 "></div>
                            <h1 className="text-instant-teams-blue-Main text-[18px] py-3 pb-4 flex font-[400] mt-2">
                                Work Term<span className="text-red-500 text-base ml-1">*</span>
                            </h1>

                            <Controller
                                control={control}
                                name="workTerm"
                                render={(field) => (
                                    <RadioGroup_Emp
                                        {...field}
                                        options={workTerm}
                                        disabled={isFieldLocked}
                                        error={errors?.workTerm?.message}
                                        required
                                    />
                                )}
                            />
                            <div className="border-b-[2px] border-gray-100 h-2 my-2 mt-10 mb-10 "></div>
                            <h1 className="text-instant-teams-blue-Main text-[18px] py-3 pb-4 flex font-[400] mt-2">
                                Work Per Week (Hrs)<span className="text-red-500 text-base ml-1 ">*</span>
                            </h1>

                            <div className="flex w-[65%] mt-1">
                                <Controller
                                    control={control}
                                    name="hoursPerWeekMin"
                                    render={(field) => {
                                        const { value, onChange, ...restField } = field;

                                        return (
                                            <Input_Emp
                                                {...restField}
                                                value={value === "" ? "" : value}
                                                onChange={(e: any) => {
                                                    const inputValue = e.target.value;
                                                    const isValidInput = /^\d*\.?\d{0,1}$/.test(
                                                        inputValue,
                                                    );
                                                    if (!isValidInput) return;
                                                    onChange(inputValue);
                                                }}
                                                display="Min (Hrs)"
                                                placeholder="Min (Hrs)"
                                                readOnly={isFieldLocked}
                                                error={errors?.hoursPerWeekMin?.message}
                                                required
                                            />
                                        );
                                    }}
                                />
                                <Controller
                                    control={control}
                                    name="hoursPerWeekMax"
                                    render={(field) => {
                                        const { value, onChange, ...restField } = field;

                                        return (
                                            <Input_Emp
                                                {...restField}
                                                value={value === "" ? "" : value}
                                                onChange={(e: any) => {
                                                    const inputValue = e.target.value;
                                                    const isValidInput = /^\d*\.?\d{0,1}$/.test(
                                                        inputValue,
                                                    );
                                                    if (!isValidInput) return;
                                                    onChange(inputValue);
                                                }}
                                                display="Max (Hrs)"
                                                placeholder="Max (Hrs)"
                                                readOnly={isFieldLocked}
                                                error={errors?.hoursPerWeekMax?.message}
                                                required
                                            />
                                        );
                                    }}
                                />
                            </div>


                        </div>
                    </div>
                 
                </div>
                <div className="mt-20 w-full flex flex-row justify-between ">
                            <button
                                type="button"
                                disabled={isSubmitting}
                                className="h-[50px] w-[210px] rounded-[70px] border-[1px] border-[#15415E] px-5 py-3 hover:border-[#1d5174] text-white bg-[#15415E] text-[16px] leading-[19.6px] font-[400]"
                                onClick={() => { reset(); navigate("/jobs") }}
                            >
                                Cancel
                            </button>

                            <div className="flex justify-center items-center">
                                <button

                                    onClick={() => setCurrentStep(currStep - 1)}
                                    className="h-[50px] w-[210px] mr-5 rounded-[70px] border-[1px] border-[#15415E] px-5 py-3 hover:border-[#1d5174] text-[#15415E] text-[16px] leading-[19.6px] font-[400]"
                                    >
                                    Back
                                </button>
                                <button
                                    type="submit"
                                    disabled={isSubmitting}
                                    className="h-[50px] w-[210px] rounded-[70px] bg-[#15415E] px-5 py-3 hover:bg-[#1d5174] text-[#FFFFFF] text-[16px] leading-[19.6px] font-[400]"
                                >
                                    {isSubmitting ? "Saving..." : "Next"}
                                </button>
                            </div>


                        </div>
            </form>
            <ToastAlert />
        </>
    );
}

export default AddJobStep3;
