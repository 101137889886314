import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { useAuth } from "../../../providers/auth";
import ButtonWrapper from "../../employer/details/New_Ui/Component-UI/Button";
import InfoIcon from "../../dashboard/AdminMetricDashboard/InfoTextWithIcon";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import ToastAlert from "../../custom-ui/toast-alert/ToastAlert";
import toast from "react-hot-toast";

function SettingsPassword_newUI(props) {
  const auth = useAuth();
  const [pending, setPending] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [passMatched, setPassmatched] = useState(true)
  const [passwordValidation, setPasswordValidation] = useState({
    minLength: false,
    hasUppercase: false,
    hasLowercase: false,
    hasNumberOrSymbol: false,
  });

  const { register, handleSubmit, errors, reset, getValues, watch } = useForm();
  const validatePassword = (password) => {
    // Define validation criteria
    const validations = {
      minLength: password.length >= 8,
      hasUppercase: /[A-Z]/.test(password),
      hasLowercase: /[a-z]/.test(password),
      hasNumberOrSymbol: /[0-9!@#$%^&*]/.test(password),
    };
  
    // Update state for individual validations
    setPasswordValidation(validations);
  
    // Check if all criteria are met
    const allValid = Object.values(validations).every(Boolean);
  
    // Update pass matched state
    setPassmatched(allValid);
  };
  const onSubmit = (data) => {
    setPending(true);

    auth
      .updatePassword(data.pass)
      .then(() => {
        reset();
        props?.setOpenPassModal(false);
        toast.success("Your password has been updated")
        // props.onStatus({
        //   type: "success",
        //   message: "Your password has been updated",
        // });
      })
      .catch((error) => {
        if (error.code === "auth/requires-recent-login") {
          toast.error("Having problem to update your password please try again.")
          // props.onStatus({
          //   type: "requires-recent-login",
          //   callback: () => onSubmit({ pass: data.pass }),
          // });
        } else {
          toast.error("Having problem to update your password please try again.")
        }
      })
      .finally(() => {
        setPending(false);
      });
  };

  const password = watch("pass", "");

  React.useEffect(() => {
    validatePassword(password);
  }, [password]);

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="mb-6">
      {/* New Password Input */}
      <div className="">
        <div className="flex px-5 w-full items-center relative">
          <div className="w-full relative z-0">
            <input
              name="pass"
              type={showPassword ? "text" : "password"}
              ref={register({
                required: "Please enter a password",
              })}
              id="floating_password"
              className="block py-2.5 outline-none focus:ring-0 focus:outline-none px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 shadow-none border-gray-300 appearance-none dark:text-white dark:border-gray-600 peer"
              placeholder=" "
            />
            <label
              htmlFor="floating_password"
              className="absolute text-sm text-instant-teams-blue-Main dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 peer-focus:text-instant-teams-blue-Main peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto"
            >
              New Password
            </label>
            <hr className="bg-gray-500" />
          </div>
          <button
            type="button"
            className="absolute right-3 top-3 text-gray-500 focus:outline-none"
            onClick={() => setShowPassword(!showPassword)}
          >
            {showPassword ? <FontAwesomeIcon
              icon={faEye}
              color="#15415E"
              className="w-4 mx-10"
              style={{ marginTop: "-5px" }}
            /> : <FontAwesomeIcon
              icon={faEyeSlash}
              color="#15415E"
              className="w-4 mx-10"
              style={{ marginTop: "-5px" }}
            />}
          </button>
          <InfoIcon
            viewDown={true}
            singupFlow={true}
            tooltipText={`
                        <ul>
                            <li>At least 8 characters</li>
                            <li>At least one capital letter</li>
                            <li>At least one lowercase letter</li>
                            <li>At least one number or symbol</li>
                        </ul>`}
          />
        </div>
        {errors.pass && (
          <p className="mt-1 text-sm text-left text-red-600">
            {errors.pass.message}
          </p>
        )}
        {/* Password Strength Validation */}
        {(!passMatched) &&password&& <div className="mt-2 z-50 rounded-xl px-4 py-4 bg-gray-50 text-sm text-left">
            <p
              className={
                passwordValidation.minLength
                  ? "text-green-600"
                  : "text-red-600"
              }
            >
              At least 8 characters
            </p>
            <p
              className={
                passwordValidation.hasUppercase
                  ? "text-green-600"
                  : "text-red-600"
              }
            >
              At least one capital letter
            </p>
            <p
              className={
                passwordValidation.hasLowercase
                  ? "text-green-600"
                  : "text-red-600"
              }
            >
              At least one lowercase letter
            </p>
            <p
              className={
                passwordValidation.hasNumberOrSymbol
                  ? "text-green-600"
                  : "text-red-600"
              }
            >
              At least one number or symbol
            </p>
          </div>}
      </div>

      {/* Confirm Password Input */}
      <div className="mt-3">
        <div className="flex px-5 w-full items-center relative">
          <div className="w-full relative z-0">
            <input
              name="confirmPass"
              type={showConfirmPassword ? "text" : "password"}
              ref={register({
                required: "Please enter your new password again",
                validate: (value) => {
                  if (value === getValues().pass) {
                    return true;
                  } else {
                    return "This doesn't match your password";
                  }
                },
              })}
              id="floating_confirm_password"
              className="block py-2.5 outline-none focus:ring-0 focus:outline-none px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 shadow-none border-gray-300 appearance-none dark:text-white dark:border-gray-600 peer"
              placeholder=" "
            />
            <label
              htmlFor="floating_confirm_password"
              className="absolute text-sm text-instant-teams-blue-Main dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 peer-focus:text-instant-teams-blue-Main peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto"
            >
              Confirm New Password
            </label>
            <hr className="bg-gray-500" />
          </div>
          <button
            type="button"
            className="absolute right-3 top-3 text-gray-500 focus:outline-none"
            onClick={() => setShowConfirmPassword(!showConfirmPassword)}
          >
            {showConfirmPassword ? <FontAwesomeIcon
              icon={faEye}
              color="#15415E"
              className="w-4 mx-10"
              style={{ marginTop: "-5px" }}
            /> : <FontAwesomeIcon
              icon={faEyeSlash}
              color="#15415E"
              className="w-4 mx-10"
              style={{ marginTop: "-5px" }}
            />}
          </button>

          <InfoIcon
            viewDown={true}
            singupFlow={true}
            tooltipText={`
                        <ul>
                            <li>At least 8 characters</li>
                            <li>At least one capital letter</li>
                            <li>At least one lowercase letter</li>
                            <li>At least one number or symbol</li>
                        </ul>`}
          />


        </div>
        {errors.confirmPass && (
          <p className="mt-1 text-sm text-left text-red-600">
            {errors.confirmPass.message}
          </p>
        )}
      </div>

      {/* Submit Button */}
      <div className="w-full mt-8 flex justify-center items-center">
        <ButtonWrapper
          className="py-2 px-4 mt-9 w-full text-xl uppercase justify-center mx-auto text-white bg-instant-teams-blue-Main rounded-lg border-0 hover:bg-instant-teams-blue-D1 focus:outline-none"
          type="submit"
          disabled={pending}
        >
          {pending ? "..." : "Update Password"}
        </ButtonWrapper>
      </div>
    </form>
  );
}

export default SettingsPassword_newUI;
