/**
 * Using this component for Jobs (New) Status Management
 */

/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from "react";
import { isNil } from "lodash";
import StatusNoteNew from "../../../../custom-ui/input-fields/modals/StatusNoteNew";
import toast from "react-hot-toast";
import ToastAlert from "../../../../custom-ui/toast-alert/ToastAlert";
import useModal from "../../../../../hooks/useModal";
import { doc, getDoc } from "firebase/firestore";
import { db } from "../../../../../providers/database";
import StatusNote_EmpNewUi from "../../../../custom-ui/input-fields/modals/StatusNote_EmpNewUi";


const AutoSaveStatusSelector = ({
  status,
  collection,
  recId,
  jobData,
  required,
  name,
  display,
  placeholder,
  directions,
}) => {
  const [currentStatus, setCurrentStatus] = useState("");
  const [statusID, setStatusID] = useState(status);
  const [statusCollection, setStatusCollection] = useState("");
  const [mainCollection, setMainCollection] = useState(collection);

  useEffect(() => {
    if (mainCollection === "JobDetailsNewCollection") {
      setStatusCollection("JobState");
    }
  }, [mainCollection, statusCollection]);

  const { isShowing: isShowingNote, toggle: toggleNote } = useModal();

  const handleDone = (message, newStatus) => {
    toggleNote();
    if (message !== undefined) {
      toast.success(message);
    }
    if (newStatus !== undefined) {
      setStatusID(newStatus);
    }
  };

  useEffect(() => {
    setStatusID(status);
  }, [status]);

  useEffect(() => {
    if (
      !isNil(statusCollection) &&
      statusCollection !== "" &&
      !isNil(statusID) &&
      statusID !== ""
    ) {
      const activeStatusCollection = doc(db, statusCollection, statusID);
      async function fetchOptions(docRef, myStatusCollection) {
        await getDoc(docRef)
          .then(async (data) => {
            setCurrentStatus(data.data().label);
          })
          .catch((error) => {
            console.log(error);
          });
      }
      fetchOptions(activeStatusCollection, statusCollection);
    }
  }, [statusID, statusCollection]);

  return (
    <div id={name} className="relative w-full border-2 border-gray-500 mt-2  rounded-full  mb-4">
   
      <input
        type="text"
        name={name}
        placeholder={placeholder}
        value={currentStatus}
        onClick={() => {
          toggleNote();
        }}
        className="form-input border border-gray-500  flex justify-center  rounded-full py-2 px-4 text-lg"
        readOnly={true}
      />
      {isShowingNote && (
        <StatusNote_EmpNewUi
          onDone={handleDone}
          collection={collection}
          id={recId}
          jobData={jobData}
          name={name}
          display={display}
          placeholder={placeholder}
          status={status}
          required={required}
          directions={directions}
          statusCollection={statusCollection}
          minLength={10}
        />
      )}
      <ToastAlert />
    </div>
  );
};

export default AutoSaveStatusSelector;
