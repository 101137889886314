import { Fragment } from "react";
import { Transition, Dialog } from "@headlessui/react";
import { cn } from "../../../util/cn";
import { createPortal } from "react-dom";
import CrossSVG from "../icons/CrossSVG";

function DialogWrapper({
  children,
  title,
  size = "max-w-md",
  onClose,
  titleColor = "text-[#183852]",
  titlebgColor = "bg-red",
  displayCloseIcon = false,
  displayHeaderHorizontalLine = true,
}: {
  children: JSX.Element;
  title: string;
  size?: string;
  titleColor?: string;
  titlebgColor?: string;
  displayCloseIcon?: boolean;
  displayHeaderHorizontalLine?: boolean;
  onClose: () => void;
}) {
  return createPortal(
    <>
      <Transition appear={true} show={true}>
        <Dialog
          className="overflow-y-auto fixed inset-0 z-50"
          onClose={onClose}
        >
          <div className="px-4 min-h-screen text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75" />
            </Transition.Child>
            <span
              className="inline-block h-screen align-middle"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div
                className={cn(
                  "inline-block overflow-hidden p-6 my-8 w-full text-left align-middle bg-white rounded-2xl shadow-xl transition-all transform",
                  size,
                )}
              >
                {title && (
                  <>
                    <Dialog.Title
                      className={cn(
                        "flex flex-row justify-between items-center mb-4 font-[Gilroy-Bold] font-[400] text-[20px] leading-[24.76px]",
                        titleColor,
                        titlebgColor,
                      )}
                    >
                      <span>{title}</span>
                      {displayCloseIcon && (
                        <button
                          className="w-[48px] h-[48px] rounded-full bg-[#F8F9FA] text-[#183852] focus:outline-none hover:bg-slate-200 flex flex-row justify-center items-center"
                          onClick={() => onClose()}
                        >
                          <CrossSVG />
                        </button>
                      )}
                    </Dialog.Title>
                    {displayHeaderHorizontalLine && <hr />}
                  </>
                )}

                <div className="p-6 overflow-y-auto max-h-[70vh]">
                  {children}
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition>
    </>,
    document.body, // This renders the Dialog directly into the body
  );
}

export default DialogWrapper;
