import React, { useEffect, useState } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBookmark, faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons';
import { formatString } from '../../Components/util';
import { updateUserBookmarks, getUserBookmarks } from '../../../../../../data/user';
import SocialButtons from './SocialButtons';
import { useAllListingsJobForNewByCompanyId } from '../../../../../../data/listings';
import JobsCards from '../../Components/JobsCards/JobsCards';
import { useAuth } from '../../../../../../providers/auth';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons';
import { filterByLatestJobs } from '../../Components/util';
import { useGlobalState } from '../../../../../../providers/globalState/GlobalStateProvider';


const CompanyDetails = ({ companyDetailData }) => {
  const userData = useAuth();
  const { globalState, setGlobalState } = useGlobalState();
  const navigate = useNavigate()
  const [bookmarks, setBookmarks] = useState()



  const data = useAllListingsJobForNewByCompanyId(companyDetailData?.id, companyDetailData?.displayName)


  const [jobsData, setJobsData] = useState()

  useEffect(() => {
    setJobsData(filterByLatestJobs(data?.data))
  }, [data?.data, companyDetailData])
  const address = companyDetailData?.address?.standardizedAddress.split(",")

  return (
    <div
      style={{
        scrollbarWidth: 'thin',
        scrollbarColor: '#b3b3b3 #f0f0f0',
      }}
      className="w-full mx-auto bg-white  rounded-lg overflow-y-auto overflow-x-hidden h-[92%] xs:h-full p-6 mt-12">
      {/* Header Section */}
      {companyDetailData ? (
        <>
          <div className="flex xs:flex-col md:flex-col justify-between items-start">
            <div className="flex">
              <div className="mr-4">
                <img
                  src={companyDetailData?.logo||"/images/default-employer.jpg"}
                  alt="Company Logo"
                  className="w-16 h-16 rounded object-contain "
                />
              </div>
              <div>
                <h2 className="text-xl font-semibold text-instant-teams-blue-Main">
                  {companyDetailData?.displayName}
                  {/* {timeDifference?.includes("h ago") && <span className="text-red-500">🔥</span>} */}
                </h2>
                <p className='text-xs text-gray-500 font-thin'>Founded: {companyDetailData?.intel?.foundedYear}</p>
                {companyDetailData?.address?.standardizedAddress && <div className="text-gray-500  text-sm font-thin flex items-center">
                  <div className='border-b-2 text-gray-400 border-[#15415E] '>
                    <FontAwesomeIcon icon={faMapMarkerAlt} className=" text-[#15415E]" />
                  </div>
                  <p className="text-[#15415E]  ml-1 text-sm font-thin flex items-center">
                    <span>{address[1]},{address[3]}</span>
                  </p>


                </div>}
              </div>
            </div>
            <div className="flex items-center xs:mt-3 md:mt-3 space-x-4">


              {jobsData?.length > 0 && (
                <button
                  onClick={() => {
                    setGlobalState(
                      { ...globalState, searchKeyWords: companyDetailData.displayName }
                    );
                    navigate(`/jobs/${companyDetailData?.id}`);
                  }}
                  className="bg-instant-teams-blue-Main text-white px-8 py-2 rounded-full flex items-center"
                >
                  View Jobs
                  <FontAwesomeIcon className="ml-2" />
                </button>
              )}
            </div>
          </div>


          <div className='w-full mt-2 flex xs:flex-col md:flex-col justify-between xs:justify-start lg:items-center xl:items-center 2xl:items-center  xs:py-2'>

            {companyDetailData?.intel?.type && <div style={{ backgroundColor: "#15415E33" }} className="bg-gray-100 py-2 flex-col justify-center items-center px-10 rounded-lg ">
              <p className="text-gray-500 flex justify-center text-sm">Sector</p>
              <p className="font-semibold text-instant-teams-blue-Main flex justify-center">{formatString(companyDetailData?.intel?.category?.sector)}</p>
            </div>}

            <div>
              <SocialButtons socialData={companyDetailData?.profile} />
            </div>
          </div>


          {/* Job Description */}
          <div className="mt-6 border-t pt-6">
            <div>

              <p dangerouslySetInnerHTML={{ __html: companyDetailData?.intel?.description }} className="mt-2 text-gray-600 text-sm" />

            </div>
            <p className="text-gray-600 text-sm mt-2 leading-relaxed">
              {/* {jobDescription?.whatsInItForYou} */}
            </p>
            {companyDetailData?.profile?.overview && <div className='mt-5'>
              <h1 className='text-instant-teams-teal-Main text-lg'>
                Overview
              </h1>
            </div>}
            {companyDetailData?.profile?.overview && <p dangerouslySetInnerHTML={{ __html: companyDetailData.profile.overview }} className="mt-2 text-gray-600 text-sm">
            </p>}


            {companyDetailData?.profile?.mission && <div className='mt-5'>
              <h1 className='text-instant-teams-teal-Main text-lg'>
                Mission
              </h1>
            </div>}
            {companyDetailData?.profile?.mission && <p dangerouslySetInnerHTML={{ __html: companyDetailData?.profile?.mission }} className="mt-2 text-gray-600 text-sm">
            </p>}

            <div className='mt-5'>
              <h1 className='text-instant-teams-teal-Main'>

              </h1>
            </div>
            <p className="mt-2 text-gray-600 text-sm">
              {/* {jobDescription?.mustHaves} */}
            </p>
            <div className='my-2 mt-2 '>
              <div className='flex text-lg  text-instant-teams-blue-Main justify-between items-center'>
                {jobsData?.length > 0 && (
                  <>
                    <h1>
                      Jobs
                    </h1>
                    <h1 onClick={() => {
                      setGlobalState(
                        { ...globalState, searchKeyWords: companyDetailData.displayName }
                      );
                      navigate(`/jobs/${companyDetailData?.id}`);
                    }} className='cursor-pointer'>
                      {`  See All Jobs >>`}
                    </h1>
                  </>
                )}

              </div>
              {jobsData?.length > 0 && <div className='mt-2 rounded-md bg-gray-50 p-2 w-full'>
                {
                  jobsData?.length > 0 && jobsData?.slice(0, 5).map((data) => (
                    <div onClick={() => {
                      setGlobalState(
                        { ...globalState, searchKeyWords: data?.jobName, jobType: data.solutionType === "bpo" ? "bpo" : "" }
                      );
                      navigate(`/jobs/${companyDetailData?.id}`);
                    }} className=''>
                      <JobsCards key={data.id} selectedId={data.id} setBookmarks={setBookmarks} bookmarks={bookmarks} jobsData={data} userData={userData} />
                    </div>
                  ))
                }
              </div>
              }
            </div>
          </div>
        </>
      ) : (
        <div className='flex items-center justify-center  h-full  '><h1 className='text-2xl text-gray-400 '> Select a Company</h1></div>
      )}
    </div>
  );
};

export default CompanyDetails;
