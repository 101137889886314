import Meta from "../../components/custom-ui/meta/Meta";
import AcceptNewInvitation from "../../components/employer/New_UI/registration/EmployerInvite/AcceptNewInvitation";
// import AcceptNewInvitation from "../../components/invitations/AcceptNewInvitation";
import { checkForAuth } from "../../providers/auth";
function InvitationPageNew() {
  return (
    <div
    // className="pt-28"
    >
      <Meta title="Invitation" />
      <AcceptNewInvitation />
    </div>
  );
}
export default checkForAuth(InvitationPageNew);
