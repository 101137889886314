import { useEffect, useState } from "react";
import { updateUser } from "../../../../../data/user";
import { updateUserOnboardedStatus } from "../../../../../data/user";

// import MilitaryConnected from "./MilitaryConnected";
import ProgressDots from "../../../../custom-ui/progress-dots/ProgressDots";
// import Resume from "./Resume";
import SuccessCircleCheck from "../../../../custom-ui/success/SuccessCircleCheck";
// import WorkPreferences from "./WorkPreferences";
import AddressForm from "../../../../address/AddressForm";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../../../../providers/auth";
import { Invitation } from "../../../../../dataTypes/Invitation";
import { useForm } from "react-hook-form";
import { AuthStatus, Origin } from "../../../../../dataTypes/AuthOrigin";
import { createAuthOrigin } from "../../../../../data/authOrigin";
import { Timestamp } from "firebase/firestore";
import { errorLogger } from "../../../../../providers/clientLogger";
import EmployerRegistrationStepFour from "../EmployerRegistrationStepFour";
import PhoneInputWithCountry from "react-phone-number-input/react-hook-form";
import PwdCheck from "../../../../custom-ui/pwd-check/PwdCheck";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import InfoIcon from "../InfoIcon";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import {
  employerGeneralUserOnboardingSchema,
  PASSWORD_REQ,
  pwdSchema,
} from "../Schema/registrationSchema";
import { zodResolver } from "@hookform/resolvers/zod";
// import "../style.css";

function EmplyerOnboardingIndex({
  invitation,
  onDone,
  processingInvitation,
}: {
  invitation: Invitation;
  onDone: Function;
  processingInvitation: Function;
}) {
  const auth = useAuth();
  const userId = auth?.user?.id;
  const navigate = useNavigate();
  const [activeStep, setActiveStep] = useState(1);
  const totalSteps = 2;
  const nextStep = () => setActiveStep((prevState: number) => ++prevState);
  const [countDown, setCountDown] = useState(7);
  const [pending, setPending] = useState(false);
  const [formAlert, setFormAlert] = useState(null);
  const [passwordValue, setPasswordValue] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [showTooltip, setShowTooltip] = useState(false);
  const [showConfirmTooltip, setShowConfirmTooltip] = useState(false);
  const [employerInfo, setEmployerInfo] = useState({});

  useEffect(() => {
    if (totalSteps <= activeStep) {
      console.log(
        "Running update to onboarded status!!",
        totalSteps <= activeStep,
      );
      //set onboarding complete
      updateUserOnboardedStatus(userId, true)
        .then(() => {
          console.log("Done  onboarding");
        })
        .catch((error: any) => {
          console.error(error);
        });
    }
  }, [activeStep]);

  const {
    handleSubmit,
    register,
    errors,
    getValues,
    control,
    trigger,
    watch,
    formState: { dirtyFields },
  } = useForm({
    defaultValues: {
      email: invitation.email,
      company: invitation.company,
      // employerId: invitation.employerId,
      // type: invitation.type,
      title: invitation.title ?? "",
      name: invitation.name ?? "",
      // invitationId: invitation.id,
      phone: "",
      password: "",
      confirmPassword: "",
      // terms: false,
    },
    resolver: zodResolver(employerGeneralUserOnboardingSchema),
  });

  useEffect(() => {
    if (activeStep === 2 && countDown > 0) {
      const timer = setTimeout(() => setCountDown(countDown - 1), 1000);
      return () => clearTimeout(timer);
    }
    // if (countDown === 0) {
    //   navigate("/home");
    // }
  }, [activeStep, countDown, auth]);

  type EmployerRegistration = {
    email: string;
    company: string;
    title: string;
    password: string;
    employerId: string;
    type: string;
    name: string;
    invitationId: string;
    displayName?: string;
    phone: string;
    confirmPassword: string;
    terms: boolean;
  };

  // on successful account creation redirects to mfa enrollment.
  const onSubmit = (data: EmployerRegistration) => {
    setPending(true);
    processingInvitation(true);

    const signupEmployerData: any = {
      email: invitation.email,
      title: invitation.title,
      password: data.password,
      company: invitation.company,
      employerId: invitation.employerId,
      type: invitation.type,
      name: invitation.name,
      invitationId: invitation.id,
      phone: data.phone,
      terms: data.terms,
    };

    createAuthOrigin({
      email: data.email.toLowerCase(),
      origin: Origin.employerInvited,
      status: AuthStatus.start,
      lastUpdated: Timestamp.now(),
    })
      .then(() => {
        setEmployerInfo(signupEmployerData);
        return auth
          .signupNewEmployer(signupEmployerData)
          .then((response: any) => {
            // setEmployerInfo(signupEmployerData);
            nextStep();
          })
          .catch((error: Error) => {
            processingInvitation(false);
            setFormAlert(`There was a problem creating ${data.name}`);
            errorLogger(error.message, {
              component: "InviteOnlyRegistrationForm signUpEmployer()",
              flow: "Employer Registration",
              userId: "n/a",
            });
            throw error;
          })
          .finally(() => setPending(false));
      })
      .catch((error) => {
        processingInvitation(false);
        setFormAlert(`There was a problem creating ${data.name}`);
        errorLogger(error.message, {
          component: "InviteOnlyRegistrationForm createAuthOrigin()",
          flow: "Employer Registration",
          userId: "n/a",
        });
      })
      .finally(() => setPending(false));
  };

  const handleEmployerInfoDone = (data: any) => {
    onDone();
  };

  // const closeModal = () => {
  //   localStorage.clear();
  //   navigate("/auth/employer");
  // };
  const handlePasswordChange = async (e: any) => {
    setPasswordValue(e.target.value);
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const toggleTooltipVisibility = () => {
    setShowTooltip(!showTooltip);
  };

  const toggleConfirmTooltipVisibility = () => {
    setShowConfirmTooltip(!showConfirmTooltip);
  };

  const toggleConfirmPasswordVisibility = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  const watchPassword = watch("password");

  const renderActiveStep = (activeStep: number) => {
    switch (activeStep) {
      case 1:
        return (
          <>
            <div className="max-w-2xl mx-auto p-6  overflow-y-scroll no-scrollbar  bg-white">
              <form
                className="space-y-8"
                onSubmit={handleSubmit(onSubmit)}
                autoComplete="off"
              >
                {/* Hidden Fields */}
                <input
                  className="py-1 px-3 w-full leading-8 bg-white rounded border border-gray-300 outline-none focus:border-blue-500"
                  name="employerId"
                  type="hidden"
                  // ref={register({
                  //   required:
                  //     "We cannot find a company associated with your account.",
                  // })}
                />
                <input
                  className="py-1 px-3 w-full leading-8 bg-white rounded border border-gray-300 outline-none focus:border-blue-500"
                  name="invitationId"
                  type="hidden"
                  // ref={register({
                  //   required:
                  //     "We cannot find an invitation associated with your record.",
                  // })}
                />
                <input
                  className="py-1 px-3 w-full leading-8 bg-white rounded border border-gray-300 outline-none focus:border-blue-500"
                  name="type"
                  type="hidden"
                  // ref={register({
                  //   required: "There was a problem identifying your user.",
                  // })}
                />
                {/* Visible Fields */}
                <div className="relative mb-7">
                  <div className="flex items-center">
                    <input
                      // className={`peer mt-1 block bg-gray-200 w-full px-3 inp py-2 rounded-none sm:text-sm border border-gray-300 ${
                      //   errors.name ? "border-red-600" : ""
                      // }`}
                      readOnly
                      placeholder="Full Name"
                      name="name"
                      type="text"
                      ref={register({
                        required: "Please enter your full name.",
                      })}
                      className="peer mt-1 block w-full px-3 inp py-2 rounded-none sm:text-sm"
                      style={{ boxShadow: "none" }}
                    />
                    <label
                      htmlFor="name"
                      className="absolute -top-4 text-sm text-[#15415E] bg-white px-1 transition-all duration-200 peer-placeholder-shown:top-2 peer-placeholder-shown:text-[#15415E] peer-placeholder-shown:text-sm peer-focus:-top-4 peer-focus:text-[#15415E] peer-focus:text-sm"
                    >
                      Full Name <span className="text-red-600">*</span>
                    </label>
                    {errors.name && (
                      <p className="mt-1 text-sm text-left text-red-600">
                        {errors.name.message}
                      </p>
                    )}
                  </div>
                </div>

                <div className="relative mb-7">
                  <input
                    readOnly
                    name="email"
                    type="email"
                    ref={register({
                      required: "Please enter an email",
                    })}
                    className="peer mt-1 block w-full px-3 inp py-2 rounded-none sm:text-sm"
                    style={{ boxShadow: "none" }}
                  />

                  <label
                    htmlFor="email"
                    className="absolute -top-4 text-sm text-[#15415E] bg-white px-1 transition-all duration-200 peer-placeholder-shown:top-2 peer-placeholder-shown:text-[#15415E] peer-placeholder-shown:text-sm peer-focus:-top-4 peer-focus:text-[#15415E] peer-focus:text-sm"
                  >
                    Email <span className="text-red-600">*</span>
                  </label>
                  {errors.email && (
                    <p className="mt-1 text-sm text-left text-red-600">
                      {errors.email.message}
                    </p>
                  )}
                </div>

                <div className="relative mb-7">
                  <div className="flex items-center">
                    <input
                      // className={`peer mt-1 block bg-gray-200 w-full px-3 inp py-2 rounded-none sm:text-sm border border-gray-300 ${
                      //   errors.title ? "border-red-600" : ""
                      // }`}
                      readOnly
                      name="company"
                      type="text"
                      placeholder="Company"
                      ref={register({
                        required: "Please enter your company.",
                      })}
                      className="peer mt-1 block w-full px-3 inp py-2 rounded-none sm:text-sm"
                      style={{ boxShadow: "none" }}
                    />
                    <label
                      htmlFor="company"
                      className="absolute -top-4 text-sm text-[#15415E] bg-white px-1 transition-all duration-200 peer-placeholder-shown:top-2 peer-placeholder-shown:text-[#15415E] peer-placeholder-shown:text-sm peer-focus:-top-4 peer-focus:text-[#15415E] peer-focus:text-sm"
                    >
                      Company <span className="text-red-600">*</span>
                    </label>

                    {errors.company && (
                      <p className="mt-1 text-sm text-left text-red-600">
                        {errors.company.message}
                      </p>
                    )}
                  </div>
                </div>
                <div className="relative mb-7">
                  <div className="flex items-center">
                    <input
                      // className={`peer mt-1 block bg-gray-200 w-full px-3 inp py-2 rounded-none sm:text-sm border border-gray-300 ${
                      //   errors.title ? "border-red-600" : ""
                      // }`}
                      readOnly
                      name="title"
                      type="text"
                      placeholder="Job Title"
                      ref={register({
                        required: "Please enter your job title.",
                      })}
                      className="peer mt-1 block w-full px-3 inp py-2 rounded-none sm:text-sm"
                      style={{ boxShadow: "none" }}
                    />
                    <label
                      htmlFor="title"
                      className="absolute -top-4 text-sm text-[#15415E] bg-white px-1 transition-all duration-200 peer-placeholder-shown:top-2 peer-placeholder-shown:text-[#15415E] peer-placeholder-shown:text-sm peer-focus:-top-4 peer-focus:text-[#15415E] peer-focus:text-sm"
                    >
                      What is your title <span className="text-red-600">*</span>
                    </label>

                    {errors.title && (
                      <p className="mt-1 text-sm text-left text-red-600">
                        {errors.title.message}
                      </p>
                    )}
                  </div>
                </div>
                <div className="relative mb-7">
                  <PhoneInputWithCountry
                    className="peer mt-1 block w-full px-3 py-2 inp sm:text-sm border-none"
                    style={{ boxShadow: "none" }}
                    name="phone"
                    international
                    defaultCountry="US"
                    countryCallingCodeEditable={false}
                    control={control}
                    //onChange={async () => await trigger("phone")}
                    onBlur={async () => await trigger("phone")}
                    rules={{
                      required: "Phone number is required",
                      pattern: {
                        value: /^\+?[1-9]\d{1,14}$/,
                        message: "Invalid phone number",
                      },
                    }}
                  />
                  <label
                    htmlFor="phone"
                    className="absolute -top-4 text-sm text-[#15415E] bg-white px-1 transition-all duration-200 peer-placeholder-shown:top-2 peer-placeholder-shown:text-[#15415E] peer-placeholder-shown:text-sm peer-focus:-top-4 peer-focus:text-[#15415E] peer-focus:text-sm"
                  >
                    Phone Number <span className="mr-1 text-red-600">*</span>
                  </label>
                  {/* {dirtyFields.phone && errors.phone && ( */}
                  {errors.phone && (
                    <p className="mt-1 text-sm text-left text-red-600">
                      {errors.phone.message}
                    </p>
                  )}
                </div>

                <div className="relative mb-7">
                  <input
                    type={showPassword ? "text" : "password"} // Toggle input type
                    name="password"
                    placeholder=" "
                    ref={register}
                    onChange={handlePasswordChange}
                    className="peer mt-1 block w-full px-3 inp py-2 rounded-none sm:text-sm"
                    style={{ boxShadow: "none" }}
                  />
                  <label
                    htmlFor="password"
                    className="absolute -top-4 text-sm text-[#15415E] bg-white px-1 transition-all duration-200 peer-placeholder-shown:top-2 peer-placeholder-shown:text-[#15415E] peer-placeholder-shown:text-sm peer-focus:-top-4 peer-focus:text-[#15415E] peer-focus:text-sm"
                  >
                    Password <span className="text-red-600">*</span>
                  </label>

                  <div
                    className="absolute inset-y-0 right-3 flex items-center cursor-pointer"
                    onClick={togglePasswordVisibility}
                  >
                    {showPassword ? (
                      <FontAwesomeIcon
                        icon={faEye}
                        color="#15415E"
                        className="w-4"
                        style={{ marginTop: "-5px" }}
                      />
                    ) : (
                      <FontAwesomeIcon
                        icon={faEyeSlash}
                        color="#15415E"
                        className="w-4"
                        style={{ marginTop: "-5px" }}
                      />
                    )}
                  </div>

                  <div
                    className="absolute inset-y-0 right-10 flex items-center cursor-pointer"
                    onClick={toggleTooltipVisibility}
                    style={{ marginRight: "-60px", marginTop: "-5px" }}
                  >
                    <InfoIcon
                      viewDown={true}
                      singupFlow={true}
                      tooltipText={`
             <ul>
                <li>At least 8 characters</li>
                <li>At least one capital letter</li>
                <li>At least one lowercase letter</li>
                <li>At least one number or symbol</li>
              </ul>`}
                    />
                  </div>

                  {/* {dirtyFields.password && errors.password && ( */}
                  {errors.password && (
                    <p className="mt-1 text-sm text-left text-red-600">
                      {errors.password.message}
                    </p>
                  )}

                  {passwordValue && (
                    <PwdCheck
                      schema={pwdSchema}
                      listStyles="text-sm text-instant-teams-gray-D1"
                      value={watchPassword}
                      pwdReq={PASSWORD_REQ}
                    />
                  )}
                </div>
                <div className="relative mb-7">
                  <input
                    type={showConfirmPassword ? "text" : "password"}
                    name="confirmPassword"
                    placeholder=" "
                    ref={register}
                    //onChange={async () => await trigger("confirmPassword")}
                    onBlur={async () => await trigger("confirmPassword")}
                    className="peer mt-1 block w-full px-3 inp py-2 rounded-none sm:text-sm"
                    style={{ boxShadow: "none" }}
                  />
                  <label
                    htmlFor="confirmPassword"
                    className="absolute -top-4 text-sm text-[#15415E] bg-white px-1 transition-all duration-200 peer-placeholder-shown:top-2 peer-placeholder-shown:text-[#15415E] peer-placeholder-shown:text-sm peer-focus:-top-4 peer-focus:text-[#15415E] peer-focus:text-sm"
                  >
                    Confirm Password <span className="text-red-600">*</span>
                  </label>

                  <div
                    className="absolute inset-y-0 right-3 flex items-center cursor-pointer"
                    onClick={toggleConfirmPasswordVisibility}
                  >
                    {showConfirmPassword ? (
                      <FontAwesomeIcon
                        icon={faEye}
                        color="#15415E"
                        className="w-4"
                        style={{ marginTop: "-5px" }}
                      />
                    ) : (
                      <FontAwesomeIcon
                        icon={faEyeSlash}
                        color="#15415E"
                        className="w-4"
                        style={{ marginTop: "-5px" }}
                      />
                    )}
                  </div>

                  <div
                    className="absolute inset-y-0 right-10 flex items-center cursor-pointer"
                    onClick={toggleConfirmTooltipVisibility}
                    style={{ marginRight: "-60px", marginTop: "-5px" }}
                  >
                    <InfoIcon
                      viewDown={true}
                      singupFlow={true}
                      tooltipText={`
             <ul>
                <li>At least 8 characters</li>
                <li>At least one capital letter</li>
                <li>At least one lowercase letter</li>
                <li>At least one number or symbol</li>
              </ul>`}
                    />
                  </div>
                  {/* {dirtyFields.confirmPassword && errors.confirmPassword && ( */}
                  {errors.confirmPassword && (
                    <p className="mt-1 text-sm text-left text-red-600">
                      {errors.confirmPassword.message}
                    </p>
                  )}
                </div>
                {/* Terms and Conditions */}
                <div className="relative mb-7  items-start">
                  <div className="flex items-center h-5">
                    <input
                      type="checkbox"
                      name="terms"
                      ref={register({
                        required: "Please agree to our terms of service.",
                      })}
                      className="h-4 w-4 text-[#15415E] focus:ring-gray-500 border-gray-600 rounded"
                    />

                    <label
                      htmlFor="terms"
                      className="text-[#616161] ml-3 text-sm"
                    >
                      By creating an account, you agree to our{" "}
                      <a
                        href="https://instantteams.com/terms-and-conditions/"
                        target="_blank"
                        className="text-[#15415E] underline"
                        rel="noreferrer"
                      >
                        Terms
                      </a>{" "}
                      and have read and acknowledge our{" "}
                      <a
                        href="https://instantteams.com/privacypolicy/"
                        target="_blank"
                        className="text-[#15415E] underline"
                        rel="noreferrer"
                      >
                        Privacy Policy
                      </a>
                      .
                    </label>
                  </div>
                  {dirtyFields.terms && errors.terms && (
                    <p className="mt-1 text-sm text-left text-red-600">
                      {errors.terms.message}
                    </p>
                  )}
                </div>
                {/* Submit Button */}
                <div className="flex justify-center">
                  <button
                    type="submit"
                    className={`py-2 px-8 text-white font-medium rounded-2xl focus:outline-none focus:ring-2 bg-[#15415E]`}
                    style={{ width: "200px" }}
                    disabled={pending}
                  >
                    {pending ? (
                      <div className="flex items-center justify-center space-x-2">
                        <svg
                          className="animate-spin h-5 w-5 text-white"
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                        >
                          <circle
                            className="opacity-25"
                            cx="12"
                            cy="12"
                            r="10"
                            stroke="currentColor"
                            strokeWidth="4"
                          ></circle>
                          <path
                            className="opacity-75"
                            fill="currentColor"
                            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4z"
                          ></path>
                        </svg>
                      </div>
                    ) : (
                      "Sign Up"
                    )}
                  </button>
                </div>
              </form>
            </div>
          </>
        );
      case 2:
        return (
          <>
            <div className="max-w-2xl mx-auto p-6  overflow-y-scroll no-scrollbar  bg-white">
              <EmployerRegistrationStepFour
                onDone={handleEmployerInfoDone}
                employerInfo={employerInfo}
              />
            </div>
          </>
        );
    }
  };
  return (
    <>
      <div className="flex min-h-screen bg-white overflow-hidden">
        <div
          className="w-full h-screen bg-cover bg-center  relative"
          style={{
            backgroundImage: `url(${
              process.env.PUBLIC_URL + "/images/banner_bg.png"
            })`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
          }}
        ></div>

        <div className="fixed inset-0 flex items-center justify-center z-50">
          <div
            className={`transform transition-transform duration-300 ease-in-out bg-white rounded-2xl cursor-pointer shadow-lg p-8 w-3/6 h-full overflow-y-auto no-scrollbar ${
              true ? "translate-x-0 animate-slideIn" : "translate-x-full"
            }`}
            style={{
              position: "absolute",
              right: 0,
              top: "50%",
              transform: "translateY(-50%)",
            }}
          >
            {activeStep <= 2 && (
              <div
                className={`flex justify-between items-center w-full max-w-3xl mx-auto p-6 bg-white `}
              >
                {["User Information", "Phone Number Verification"].map(
                  (label, index) => (
                    <div
                      key={index}
                      className="flex flex-col items-center relative w-full"
                    >
                      <div
                        className={`w-7 h-7 rounded-full border-2 flex items-center justify-center z-10 ${
                          activeStep >= index + 1
                            ? "bg-[#15415E] border-[#15415E] text-white"
                            : "bg-[#D4DFE3] border-[#D4DFE3] text-white"
                        }`}
                      >
                        <span
                          style={{ fontSize: "12px" }}
                          className="font-normal"
                        >
                          {index + 1}
                        </span>
                      </div>
                      {activeStep === index + 1 && (
                        <span className="text-xs -mb-4 uppercase text-[#15415E] font-semibold whitespace-nowrap">
                          {label}
                        </span>
                      )}
                      {index !== totalSteps - 1 && (
                        <div
                          className={`absolute top-1/2 transform -translate-y-1/2 h-1 ${
                            activeStep >= index + 2
                              ? "bg-[#15415E]"
                              : "bg-[#D4DFE3]"
                          }`}
                          style={{
                            width: "100%",
                            left: "50%",
                            right: "-50%",
                            zIndex: 1,
                          }}
                        ></div>
                      )}
                    </div>
                  ),
                )}
              </div>
            )}
            {renderActiveStep(activeStep)}
          </div>
        </div>
      </div>
    </>
  );
}

export default EmplyerOnboardingIndex;
