import React, { useEffect, useState } from "react";
import useModal from "../../../hooks/useModal";
import DialogWrapper from "../../custom-ui/dialog/Dialog";
import PDFViewer from "../../pdf/pdfViewer";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { updateAutoSave } from "../../../data/utility";
import { useJobDescription } from "../../../data/jobDescriptions";
import { useEmployer } from "../../../data/employer";
import { useShareByTypeAndId } from "../../../data/share";
import { useAuth } from "../../../providers/auth";
import {
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
  TwitterIcon,
  FacebookIcon,
  LinkedinIcon,
} from "react-share";
import EditSocialMeta from "../../socials/EditSocailMeta";
import { getRoot } from "../../../util/urlHelper";
import { Timestamp } from "firebase/firestore";
import { createShare } from "../../../data/share";
import GreenUnderlineHeading from "../../custom-ui/headings/heading";
import {
  faPenToSquare,
  faEye,
  faRotate,
  faTrash,
  faPlusCircle,
  faCopy
} from "@fortawesome/pro-solid-svg-icons";
import { renderJobToStaticHTML } from "../../../util/static/renderStatic";
import JobListingShare from "../../socials/JobListingDetails";
import { useActiveJobPositions } from "../../../data/positions";
import { useJobDescriptionSkills } from "../../../data/jobDescriptions";
import { useEmployerData } from "../../../hooks/useApplicationEmployerData";
import { useListingJobByDocId } from "../../../data/listings";



const JobListingSocials = ({ id }: { id: string }) => {
  const [copied, setCopied] = useState(false);
  const { user } = useAuth();
  // const { data: job = {} } = useJobDescription(id);
  // const { employerId, employerData: employer } = useEmployerData({
  //   jobData: job,
  //   originalEmployerId: job?.employer,
  // });

  const { data: share = {} } = useShareByTypeAndId("job", id);
  // const { data: jobPositions } = useActiveJobPositions(id);
  // const { data: jobDescriptionSkills } = useJobDescriptionSkills(id);
  const { data }: any = useListingJobByDocId(id)


  const [shareId, setShareId] = useState(share[0]?.id ?? "");
  const [baseUrl, setBaseUrl] = useState(getRoot());
  const { isShowing, toggle } = useModal();

  useEffect(() => {
    setShareId(share[0]?.id ?? "");
  }, [share]);

  async function shareJob() {
    const shareInfo = {
      type: "job",
      typeId: data?.id,
      data: {
        employer: {
          displayName: data?.companyFullName,
          logo: data?.companyLogo,
          payRate: data?.payRate,
          workSettings: data?.workSettings,
          workType: data?.workType
        },
        job: {
          description: data?.jobDescription,
          title: data?.jobName
        }
      },
      who: user?.uid,
      active: true,
      createdAt: Timestamp.fromDate(new Date()),
    };

    if (shareId) {
      await updateAutoSave("Shares", shareId, shareInfo);
    } else {
      await createShare(shareInfo);
    }
    // toggle(); popup will come once you delete the social link and try to regenerate ---> write its  turned off 
  }

  async function removeShareJob() {
    await updateAutoSave("Shares", shareId, {
      active: false,
    });
  }

  async function renderShareJob() {
    const staticFile = await renderJobToStaticHTML(
      share,
    );
  }

  const SOCIALSWITCH = "true"
  // const SOCIALSWITCH = process.env.REACT_APP_SOCIAL_SHARE;

  const handleCopyClick = () => {
    const urlToCopy = `${baseUrl}social/${share[0].id}`;
    navigator.clipboard.writeText(urlToCopy).then(() => {
      setCopied(true);
      setTimeout(() => setCopied(false), 2000);
    }).catch(err => {
      console.error('Failed to copy: ', err);
    });
  };

  return (
    <div className="w-full ml-5">

      {SOCIALSWITCH !== undefined && SOCIALSWITCH === "true" && (
        <>
          {share && share.length && share[0].active ? (
            <div className="text-center mb-4">

              <>



                <button
                  className="text-instant-teams-blue-Main mr-4"
                  type="button"
                  onClick={handleCopyClick}
                >
                  <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                    <rect x="9" y="9" width="13" height="13" rx="2" ry="2"></rect>
                    <path d="M5 15H4a2 2 0 0 1-2-2V4a2 2 0 0 1 2-2h9a2 2 0 0 1 2 2v1"></path>
                  </svg>
                </button>



                <a
                  href={`${baseUrl}social/${share[0].id}`}
                  target="_blank"
                  rel="noreferrer"
                >
                  <button
                    className="text-instant-teams-blue-Main mr-4"
                    type="button"
                  >
                    <FontAwesomeIcon
                      icon={faEye as IconProp}
                      className="h-6 w-6"
                    />
                  </button>
                </a>
                <button
                  className="text-instant-teams-blue-Main mr-4"
                  type="button"
                  onClick={renderShareJob}
                >
                  <FontAwesomeIcon
                    icon={faRotate as IconProp}
                    className="h-6 w-6"
                  />
                </button>
                <button
                  className="text-instant-teams-blue-Main"
                  type="button"
                  onClick={removeShareJob}
                >
                  <FontAwesomeIcon
                    icon={faTrash as IconProp}
                    className="h-6 w-6"
                  />
                </button>

                {copied && (
                  <div className=" absolute mt-1 bg-instant-teams-blue-Main text-white px-2 py-1 rounded text-sm">
                    Social link copied to clipboard
                  </div>
                )}
              </>

              {/* <div className="mt-4">
                <GreenUnderlineHeading title={"Share To: "} noMargin={true}>
                  <div className='w-full grid grid-cols-3 gap-4'>
                    <FacebookShareButton
                      url={`${baseUrl}social/${share[0].id}`}
                      quote={share?.title || "New Job Announcement"}
                      hashtag={share?.hashtags || "#instantteams"}
                    >
                      <FacebookIcon size={28} round />
                    </FacebookShareButton>
                    <LinkedinShareButton
                      url={`${baseUrl}social/${share[0].id}`}
                      title={share?.title || "New Job Announcement"} 
                      summary={share?.description || "Look at this great Job on Instant Teams Marketplace"}
                      source="Instant Teams Marketplace"
                    >
                      <LinkedinIcon size={28} round />
                    </LinkedinShareButton>
                    <TwitterShareButton 
                      url={`${baseUrl}social/${share[0].id}`}
                      title={job?.title || "New Job Announcement"}
                      via="Instant Teams Marketplace"
                      hashtags={share?.hashtags || "#instant-teams"}
                    >
                      <TwitterIcon size={28} round />
                    </TwitterShareButton>            
                  </div>
                </GreenUnderlineHeading>
              </div> */}
            </div>
          ) : (

            <button
              className="text-instant-teams-blue-Main"
              type="button"
              onClick={shareJob}
            >
              <FontAwesomeIcon
                icon={faPlusCircle as IconProp}
                className="h-4 w-4"
              />
            </button>

          )}
        </>
      )}
      {isShowing && share[0] && (
        <DialogWrapper
          title="Share Details"
          onClose={toggle}
          size="w-1/3 h-auto mt-36"
        >
          <EditSocialMeta share={share[0]} />
        </DialogWrapper>
      )}
    </div>
  );
};

export default JobListingSocials;