/* eslint-disable jsx-a11y/iframe-has-title */
import React, { useState, useEffect } from "react";
import SettingsPassword from "./SettingsPassword";
import useModal from "../../../hooks/useModal";
import AddressForm from "../../address/AddressForm";
import SettingsMFA from "../../auth/multi-factor/SettingsMFA";
import Avatar from "./Avatar";
import Avatar_EmpNewUi from "./Avata_EmpNewui";
import toast from "react-hot-toast";
import ToastAlert from "../../custom-ui/toast-alert/ToastAlert";
import Tooltip from "../../custom-ui/tooltip/tooltip";
import PhoneNumber from "../../custom-ui/input-fields/phoneNumber";

import { useAuth } from "../../../providers/auth";
import { updateUser } from "../../../data/user";
import { useRole } from "../../../hooks/useRole";
import { Type } from "../../../data/dictionary/type";

import AutoSaveText from "../../custom-ui/input-fields/auto-save-field";
import AutoSaveSelectByLookup from "../../custom-ui/input-fields/auto-save-select-by-lookup";
import { useUser } from "../../../providers/database";
import GreenUnderlineHeading from "../../custom-ui/headings/heading";
import ButtonWrapper from "../../employer/details/New_Ui/Component-UI/Button";
import FloatingLabelInput from "../../FloatinngLabelInput/FloatinglabelInput";
import PhoneInputWithCountry from "react-phone-number-input/react-hook-form";
import { useForm } from "react-hook-form";
import SettingsMFA_newUi from "../../auth/multi-factor/SettingsMFA_newUi";
import DialogWrapper from "../../custom-ui/dialog/Dialog";
import SettingsPassword_newUI from "./SettingPassword_NewUI";
import EditProfileEmp_newUI from "./EditEmployerProfile";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faTrashCan } from '@fortawesome/free-solid-svg-icons';
import {
  reauthenticateWithCredential,
  EmailAuthProvider
} from 'firebase/auth'
import { deleteUserAccount } from "../../../data/user";


function SettingsGeneral_newUI(props) {
  const auth = useAuth();
  const { handleSubmit, register, errors, setValue, getValues, control } = useForm();
  const [openPassModal, setOpenPassModal] = useState(false)
  const [openEditProfModal, setOpenEditProfileModal] = useState(false)
  const [isChecked, setIsChecked] = useState(false);
  const [password, setPassword] = useState();
  const [error, setError] = useState(false);
  const [startCounter, setStartCoutner] = useState(false)
  const [counter, setCounter] = useState(5)

  const { isShowing: showDeleteAcMailConfirmation, toggle: toggleshowDeleteAcMailConfirmation } = useModal()
  const { isShowing: showDeleteAcModal, toggle: toggleDeleteAccModal } = useModal();
  const { isShowing: showAlert, toggle: toggleshowAlert } = useModal()
  const { isShowing: showProceedDeleteAcc, toggle: toggleShowProceedDeleteAccl } = useModal()

  useEffect(() => {
    if (auth?.user.phone) {
      setValue("mobilePhone", auth?.user.phone);
    }
  }, [auth?.user?.phone, setValue]);
  const memberDateOptions = { year: "numeric", month: "short" };

  const timeOptions = { hour: "2-digit", minute: "2-digit" };

  const { data: userData } = useUser(auth.user.uid);
  console.log(userData,"email data")

  const isInternalUser = auth?.user?.claims?.type === "A";
  const [avatar, setAvatar] = useState(auth?.user?.auth?.currentUser?.photoURL);
  // const [role, setRole] = useState("");
  const [type, setType] = useState("");
  const [mapSource, setMapSource] = useState(
    "https://maps.google.com/maps?q=35.856737, 10.606619&z=15&output=embed",
  );

  const [metadata, setMetadata] = useState({
    creationTime: "",
    lastSignInTime: "",
  });
  const { isShowing: showAvatarEditModal, toggle: toggleAvatarEditModal } =
    useModal();

  const today = new Date().toLocaleDateString();
  const lastTimeSignedInDate = new Date(
    auth.user.metadata.lastSignInTime,
  ).toLocaleDateString();

  const linkedInRegx = new RegExp(
    /(https?:\/\/(www.)|(www.))?linkedin.com\/(mwlite\/|m\/)?in\/[a-zA-Z0-9_.-]+\/?/gm,
  );
  const getRole = useRole();
  const role = getRole(auth.user.claims.role, true);

  // updates image as soon as it's available
  useEffect(() => {
    if (avatar === undefined || avatar == null) {
      setAvatar("/images/default_user.png");
    }
    setAvatar(userData?.photoURL);
  }, [userData]);


  useEffect(() => {
    if (startCounter) {
        const interval = setInterval(() => {
            if (counter > 0) {
                setCounter((prevCount) => prevCount - 1);

            } else {
                auth.signout();
                deleteUserAccount(userData?.email)
                toggleShowProceedDeleteAccl()
                clearInterval(interval);

            }
        }, 1000);

        return () => clearInterval(interval);
    }

}, [counter, startCounter]);
  useEffect(() => {
    setType(Type[auth.user.claims.type]);
    if (auth.user?.address) {
      setMapSource(
        "https://maps.google.com/maps?q=" +
        auth.user.address.latitude +
        ", " +
        auth.user.address.longitude +
        "&z=15&output=embed",
      );
    }
    if (today === lastTimeSignedInDate) {
      setMetadata({
        creationTime: new Date(
          auth.user.metadata.creationTime,
        ).toLocaleDateString(undefined, memberDateOptions),
        lastSignInTime: new Date(
          auth.user.metadata.lastSignInTime,
        ).toLocaleTimeString(undefined, timeOptions),
      });
    } else {
      setMetadata({
        creationTime: new Date(
          auth.user.metadata.creationTime,
        ).toLocaleDateString(undefined, memberDateOptions),
        lastSignInTime: new Date(
          auth.user.metadata.lastSignInTime,
        ).toLocaleDateString(),
      });
    }
  }, [auth]);

  const inputFieldClasses = `
    py-1 mt-0.5
    w-full leading-8 
    bg-white
    outline-none 
    border border-gray-300 
  `;


  const handleAuthentication = async () => {
    const credentials = EmailAuthProvider.credential(userData?.email, password);
    reauthenticateWithCredential(auth?.user, credentials).then(() => { })
        .catch((error) => {
            if (error.code == "auth/multi-factor-auth-required") {
                toggleshowAlert()
                toggleShowProceedDeleteAccl()
            } else if (error.code == "auth/too-many-requests") {
                setError("Too many request attempted. Try again later or reset password")
            }
            else {
                if (!password) {
                    setError("Enter a password")
                } else {
                    setError("Incorrect password")
                }

            }
        })
  }
  


  const handleDeleteAccount = () => {

    setStartCoutner(true)
    // auth.signout()
    return;
}
  return (
    <div className="mt-[-50px] ">
      <div className="  border-b-[1px] py-2 text-3xl border-instant-teams-teal-L3">
        <h1 className="text-instant-teams-blue-Main font-semibold" > My Profile </h1>
      </div>

      <div className="w-full h-full bg-gray-50  mt-5 grid grid-cols-3 space-x-4">
        <div className=" rounded-xl  bg-white col-span-2 p-6  space-x-4 ">
          <div  className="flex w-full ">
            <div   className="">
              <div className="relative w-40 h-40 cursor-pointer">
                <img
                  className="w-40 h-40 rounded-full object-cover"
                  src={avatar ?? "/images/default_user.png"}
                  alt="Profile"
                  onClick={()=> toggleAvatarEditModal()}
                />
                <img
                  src="/images/editImage.png"
                  alt="Edit"
                  className="absolute bottom-3  right-3 w-6"
                  onClick={()=> toggleAvatarEditModal()}
                />
                <div className=" mt-5 text-lg ">
                  <p className="text-gray-500 text-sm">Member since {metadata.creationTime}</p>
                </div>
                <div className=" mt-3 text-lg ">
                  <p className="text-gray-500 text-sm"> User Role: {role}</p>
                </div>


              </div>
           
            </div>
            <div className=" w-full ml-10">
              <div className=" flex justify-between ">
                <div>
                  <h1 className=" text-instant-teams-blue-Main text-[22px] font-semibold">
                    User Information
                  </h1>

                </div>

                <ButtonWrapper onClick={() => { setOpenEditProfileModal(true); }}>
                  Edit Profile
                </ButtonWrapper>



              </div>
              <div className="  mt-4 w-full">
                <p className="text-gray-400 text-[15px]">
                  Email
                </p>
                <p className="text-gray-400 mt-1 text-[15px]">
                  {auth?.user?.email || ""}
                </p>
                <hr className="bg-gray-700 my-4" />
              </div>
              <div className="  mt-4 w-full">
                <p className="text-gray-400 text-[15px]">
                  Full Name
                </p>
                <p className="text-gray-400 mt-1 text-[15px]">
                  {userData?.name || ""}
                </p>
                <hr className="bg-gray-700 my-4" />
              </div>
              <div className="  mt-4 w-full">
                <p className="text-gray-400 text-[15px]">
                  What is your title
                </p>
                <p className="text-gray-400 mt-1 text-[15px]">
                  {userData?.title || ""}
                </p>
                <hr className="bg-gray-700 my-4" />
              </div>

            </div>
          </div>

        </div>

        <div className="  bg-gray-50 rounded-xl  h-20 col-span-1">
          <div className="rounded-xl bg-white p-6">
            <h1 className="text-instant-teams-blue-Main text-[22px] font-semibold">
              Two Factor Authentication
            </h1>

            <h1 className="mt-4 text-gray-600 text-sm">
              Phone Number
            </h1>
            <PhoneInputWithCountry
              className="peer mt-1 block w-full px-3 py-2 inp sm:text-sm border-none"
              style={{ boxShadow: 'none' }}
              name="mobilePhone"
              international
              defaultCountry="US"
              disabled
              countryCallingCodeEditable={false}
              control={control}
              rules={{ required: true }}
              onChange={(code) => setValue("mobilePhone", code)}
            />
            <SettingsMFA_newUi onStatus={props.onStatus} />


          </div>

          <div className="mt-4  flex flex-col items-center justify-centerw-full">
            <button onClick={() => setOpenPassModal(true)} className='bg-instant-teams-blue-Main rounded-full py-3 px-16 text-white '>
              Change Password
            </button>

            <button className="border mt-4 border-[#15415E] text-[#15415E] px-3 py-1 rounded-full w-64 h-12" onClick={() => { toggleshowDeleteAcMailConfirmation(); setIsChecked(false) }}>
              Delete My Account    <FontAwesomeIcon icon={faTrashCan} className="ml-2" />
            </button>

          </div>

        </div>

      </div>
      <ToastAlert />

      {openPassModal && <DialogWrapper title="">
        <>
          <div className="flex justify-between items-center mt-3 mb-5">
            <div>
              <h1 className="text-lg text-instant-teams-blue-Main">
                Update Password
              </h1>
            </div>
            <div
              className="flex  justify-center items-center w-7 h-7 bg-gray-200 rounded-full cursor-pointer"
              onClick={() => setOpenPassModal(false)}
            >
              <FontAwesomeIcon icon={faTimes} color="#565656" />
            </div>
          </div>
          {auth?.user?.claims?.type !== "A" && (
            <SettingsPassword_newUI setOpenPassModal={setOpenPassModal} inputFieldClasses={inputFieldClasses} />
          )}
        </>

      </DialogWrapper>}

      {openEditProfModal && <DialogWrapper title="">
        <>
          <div className="flex justify-between items-center mt-3 mb-5">
            <div>
              <h1 className="text-lg text-instant-teams-blue-Main">
                Edit Profile
              </h1>
            </div>
            <div
              className="flex  justify-center items-center w-7 h-7 bg-gray-200 rounded-full cursor-pointer"
              onClick={() => setOpenEditProfileModal(false)}
            >
              <FontAwesomeIcon icon={faTimes} color="#565656" />
            </div>
          </div>
          < EditProfileEmp_newUI userData={userData} setOpenEditProfileModal={setOpenEditProfileModal} />
        </>

      </DialogWrapper>}

      {showDeleteAcMailConfirmation &&
        (
          <DialogWrapper
            title=""
            onClose={toggleshowDeleteAcMailConfirmation}
          >
            <>
              <div className="flex items-center text-gray-700 space-x-1 mb-4" >
                <i className="fa fa-trash text-3xl mr-2" aria-hidden="true"></i>
                <div className="flex items-center justify-center space-x-2 text-l" >
                  <div className="font-bold">PERMANENTLY DELETE MY ACCOUNT</div>
                </div>
              </div>

              <div className=" text-red mt-1">
                <h5 className=" text-sm text-gray-600 ">
                  Are you sure you want to permanently delete the account for
                </h5>
              </div>
              <div className=" text-red mt-1">
                <p className=" text-m text-gray-600  font-bold">
                  {userData.email}
                </p>
              </div>
              <div className="flex items-center  space-x-1 mb-1 mt-7 ">
                <input
                  type="checkbox"
                  id="myCheckbox"
                  checked={isChecked}
                  onChange={(event) => {
                    setIsChecked(event.target.checked)
                  }}
                  className="mr-2 outline-none focus:ring-0 rounded"

                />

                <p className="text-sm text-gray-500 ">I understand that I won't be able to recover my account.</p>
              </div>

              <div className="flex justify-around space-x-5 mt-10 mx=5 ">
                <button
                  className={`py-2 px-2 w-full text-white uppercase ${!isChecked
                    ? 'bg-red-300 cursor-not-allowed' // Change color and cursor when disabled
                    : 'bg-instant-teams-red-Main hover:bg-instant-teams-red-L1'
                    } rounded-lg`}
                  onClick={() => { toggleDeleteAccModal(); toggleshowDeleteAcMailConfirmation(); }}
                  disabled={!isChecked}
                >
                  Delete My Account
                </button>
                <button
                  className="py-2  px-2  bg-white uppercase border border-instant-teams-blue-Main hover:border-instant-teams-blue-D1 hover:text-white hover:bg-instant-teams-blue-D1 text-black w-full rounded-lg"
                  onClick={() => toggleshowDeleteAcMailConfirmation()}
                >
                  CANCEL
                </button>

              </div>

            </>

          </DialogWrapper>
        )
      }

{showDeleteAcModal &&
                <DialogWrapper
                    title=""
                    onClose={toggleDeleteAccModal}
                >
                    <>
                        <div className="flex items-center text-gray-700 justify-center space-x-2 mb-4" >
                            <i className="fa fa-trash text-3xl " aria-hidden="true"></i>
                        </div>
                        <div className="flex items-center justify-center space-x-2 text-m" >
                            <div className="font-bold">PERMANENTLY DELETE MY ACCOUNT</div>
                        </div>
                        <div className="text-center text-red mt-2 mb-2">
                            <h5 className="text-instant-teams-red-Main text-l font-bold">
                                Warning this is permanent and cannot be undone!
                            </h5>
                        </div>
                        <div className="text-gray-500 text-sm mt-3">
                            <p className="mt-2">* All of your applications will be immediately deleted.</p>
                            <p className="mt-2">* Any offers that were released to you will be revoked.</p>
                            <p className="mt-2">* You will no longer be able to log in to Instant Teams Marketplace.</p>
                            <p className="mt-2">* Please note that if you are using this email address on 12M+ community websites, you will no longer be able to access the 12M+ community using this account.</p>
                        </div>

                        <div className="flex justify-around space-x-10 mt-5  mx-5">
                            <button
                                className=" py-2 px-9 w-full text-white uppercase bg-instant-teams-red-Main hover:bg-instant-teams-red-L1 rounded-lg"
                                onClick={() => { toggleshowAlert(); toggleDeleteAccModal(); }}
                            >
                                Continue
                            </button>
                            <button
                                className="py-2  px-9  bg-white uppercase border border-instant-teams-blue-Main hover:border-instant-teams-blue-D1 hover:text-white hover:bg-instant-teams-blue-D1 text-black w-full rounded-lg"
                                onClick={() => toggleDeleteAccModal()}
                            >
                                CANCEL
                            </button>

                        </div>
                    </>

                </DialogWrapper>

      }
      
      {
                showAlert &&
                <DialogWrapper
                    title=""
                    onClose={toggleshowAlert}
                >
                    <>
                        <div className="flex items-center text-gray-700 justify-center space-x-2 mb-4" >
                            <i className="fa fa-trash text-3xl" aria-hidden="true"></i>
                        </div>
                        <div className="flex items-center justify-center space-x-2 mb-2 text-sm" >
                            <div className="font-bold">PERMANENTLY DELETE MY ACCOUNT</div>
                        </div>
                        <div className="text-gray-500 text-sm mt-3 px-2 ">

                            <p>Please enter the password</p>
                        </div>
                        <div>
                            <div>
                            </div>
                            {/* <SettingsMFA onStatus={props.onStatus}/> */}
                            <div className="px-2 mt-2">
                                <input
                                    className="py-1 px-3 w-full leading-8 bg-white rounded border border-gray-300 outline-none focus:border-blue-500 focus:ring-1"
                                    name="pass"
                                    type="password"
                                    placeholder="Password"
                                    value={password}
                                    onChange={(e) => setPassword(e.target.value)}
                                    onFocus={() => setError(false)}
                                />
                                {error && <p className="mt-1 text-sm text-instant-teams-red-Main">{error}</p>}

                                {/* {errors.pass && (
              <p className="mt-1 text-sm text-left text-red-600">
                {errors.pass.message}
              </p>
            )} */}
                            </div>
                        </div>
                        <div>
                            {/* <MultiFactorEnrollment onStatus={props.onStatus}   callback={()=>{}}
              buttonVariant={"buttonVariant"}/> */}

                        </div>


                        <div className="flex justify-around space-x-10 mt-5 mx-5 mt-8">
                            <button
                                className=" py-2 px-9 w-full text-white uppercase bg-instant-teams-red-Main hover:bg-instant-teams-red-L1 rounded-lg"
                                onClick={() => { handleAuthentication() }}
                            >
                                Continue
                            </button>
                            <button
                                className="py-2  px-9  bg-white uppercase border border-instant-teams-blue-Main hover:border-instant-teams-blue-D1 hover:text-white hover:bg-instant-teams-blue-D1 text-black w-full rounded-lg"
                                onClick={() => toggleshowAlert()}
                            >
                                Cancel
                            </button>

                        </div>
                    </>

                </DialogWrapper>

      }
      
      {
                showProceedDeleteAcc &&
                <DialogWrapper
                    title=""
                    onClose={toggleShowProceedDeleteAccl}
                >
                    <>

                        <div className="flex items-center justify-center space-x-2 mb-4" >
                            <i className="fa-thin fa-circle-check  text-3xl"></i>
                        </div>
                        <div className="text-center text-red  mb-3">
                            <h5 className="mb-1">Done </h5>
                            <h5 className="text-instant-teams-red-Main text-l font-bold">
                                Your account will be shortly deleted
                            </h5>
                        </div>
                        <div className="text-gray-500  text-sm mt-3 text-center">
                            <p className="mt-2">{`You will be redirected to employer portal within next  ${counter}  seconds.`}</p>
                        </div>

                        <div className="flex justify-around space-x-10 mt-5 mx-5">
                            <button
                                className=" py-2 px-9  bg-whit border border-instant-teams-blue-Main hover:border-instant-teams-blue-D1 hover:text-white hover:bg-instant-teams-blue-D1 text-black  rounded-lg"
                                onClick={() => handleDeleteAccount()}
                            >
                                Proceed
                            </button>
                            {/* <button
              className="py-2  px-9  bg-whit border border-instant-teams-blue-Main hover:border-instant-teams-blue-D1 hover:text-white hover:bg-instant-teams-blue-D1 text-black w-full rounded-lg"
              onClick={() => toggleShowProceedDeleteAccl()}
            >
              Cancel
            </button> */}

                        </div>
                    </>

                </DialogWrapper>

      }
      
      {showAvatarEditModal ? (
          <Avatar_EmpNewUi toggleModal={toggleAvatarEditModal} />
        ) : null}

    </div>
  );
}

export default SettingsGeneral_newUI;
