import React, { useState, useEffect } from "react";
import AddJobStep1 from "../Component/AddJobForm/AddJobStep1";
import AddJobStep2 from "../Component/AddJobForm/AddJobStep2";
import AddJobStep3 from "../Component/AddJobForm/AddJobStep3";
import AddJobStep4 from "../Component/AddJobForm/AddjobStep4";
import AddJobStep5 from "../Component/AddJobForm/AddJobStep5";
import { Controller, useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import { useLookup } from "../../../../../data/lookup";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";



const AddNewJobsNewUI = () => {
    const [currentStep, setCurrentStep] = useState(1);
    const [formvalues, setFormvalues] = useState()
    console.log(formvalues)
    const params = useParams();
    const jobId = params.id ?? ""

    const steps = [
        { id: 1, title: "Job Information" },
        { id: 2, title: "Job Flow" },
        { id: 3, title: "Work" },
        { id: 4, title: "Salary" },
        { id: 5, title: "Job Description" },
    ];

    const handleNext = () => {
        if (currentStep < steps.length) {
            setCurrentStep(currentStep + 1);
        }
    };

    const handlePrevious = () =>{
        if (currentStep < steps.length) {
            setCurrentStep(currentStep - 1);
        }
    }

    const handleSaveAndExit = () => {
        alert("Form saved! You can continue later.");
    };
    const renderStepContent = () => {
        switch (currentStep) {
            case 1:
                return (
                    <div className=" w-full flex justify-center items-center">
                        <AddJobStep1 currStep={currentStep} formvalues={formvalues} setFormvalues={setFormvalues} setCurrentStep={setCurrentStep} />
                    </div>
                );
            case 2:
                return (
                    <div className=" w-full flex justify-center items-center">
                     <h1> <AddJobStep2 currStep={currentStep} formvalues={formvalues} setFormvalues={setFormvalues} setCurrentStep={setCurrentStep} /></h1>
                    </div>
                );
            case 3:
                return (
                    <div className=" w-full flex justify-center items-center">
                      <AddJobStep3 currStep={currentStep} formvalues={formvalues} setFormvalues={setFormvalues} setCurrentStep={setCurrentStep}/>
                    </div>
                );
            case 4:
                return (
                    <div className=" w-full flex justify-center items-center">
                     <AddJobStep4 currStep={currentStep} formvalues={formvalues} setFormvalues={setFormvalues} setCurrentStep={setCurrentStep}/>
                    </div>
                );
            case 5:
                return (
                    <div className=" w-full flex justify-center items-center">
                        <AddJobStep5 currStep={currentStep} formvalues={formvalues} setFormvalues={setFormvalues} setCurrentStep={setCurrentStep} />
                    </div>
                );
            default:
                return null;
        }
    };

    return (
        <div className=" ">
            <div>
                <h1 className="text-2xl mb-6 font-bold text-instant-teams-blue-Main">
                    Post a Job
                </h1>
            </div>
            <div className=" bg-white min-h-[65vh] w-full flex flex-col items-center   rounded-xl  mx-auto p-8">

                <div className="flex w-[80%] justify-center items-center mb-8">
                    {steps.map((step, index) => (
                        <div
                            key={step.id}
                            className={`flex-1   flex items-center ${index < currentStep ? "text-blue-600" : "text-gray-400"
                                }`}
                        >
                            {/* Step Circle */}
                            <div
                                className={`w-8 h-8 rounded-full flex items-center justify-center ${index < currentStep
                                    ? "bg-instant-teams-blue-Main text-white"
                                    : "bg-gray-200"
                                    }`}
                            >
                                {step.id}
                            </div>
                            {/* Line between Steps */}
                            {index < steps.length - 1 && (
                                <div
                                    className={`flex-1 h-1 ${index + 1 < currentStep
                                        ? "bg-instant-teams-blue-Main"
                                        : "bg-gray-200"
                                        } mx-2`}
                                ></div>
                            )}
                        </div>
                    ))}
                 
                </div>

                {/* Step Title */}
              
                <h1 className="text-[18px] w-full ml-24 flex justify-start text-instant-teams-blue-Main font-bold mb-4">
                    {steps.find((step) => step.id === currentStep)?.title}
                </h1>
                {/* Form Content */}
                <div className="bg-white p-6">
                  {renderStepContent()}
                </div>
            </div>

 
        </div>
    );
};

export default AddNewJobsNewUI;
