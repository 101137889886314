import React from "react";

type TDeleteUserModal = {
  onCancel: () => void;
  onDelete: () => void;
};

const DeleteUserModal = ({ onCancel, onDelete }: TDeleteUserModal) => {
  return (
    <div className="flex flex-col">
      <p className="flex-1 text-[#15415E] font-[Gilroy-Medium] font-[400] text-[16px] leading-[19.41px]">
        Deleting this user is permanently and cannot be undone. Do you want to
        continue?
      </p>
      <div className="flex flex-row justify-center items-center gap-[15px] mt-[30px]">
        <button
          className="flex justify-center items-center h-[30px] w-[100px] rounded-[70px] border-[1px] border-[#15415E] py-[25px] px-[60px] hover:border-[#1d5174] hover:bg-slate-200 text-[#15415E] text-[16px] leading-[19.6px] font-[400]"
          onClick={onCancel}
        >
          Cancel
        </button>
        <button
          className="flex justify-center items-center h-[30px] w-[100px] py-[25px] px-[60px] rounded-[70px] bg-[#ED3535] text-[#FFFFFF] text-[16px] leading-[19.6px] font-[400]"
          onClick={onDelete}
        >
          Delete
        </button>
      </div>
    </div>
  );
};

export default DeleteUserModal;
