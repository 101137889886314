import { useState } from "react";
import BulletTextWrapper from "../../details/New_Ui/Component/CurrentPlan/BulletTextWrapper";
import { cn } from "../../../../util/cn";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import { updateEmployerNew } from "../../../../data/employer";
import toast from "react-hot-toast";
import { useEffect } from "react";

type TermsPageProps = {
  currentPlan: string;
  employerInfo: any
  onContinue: any;
};

const EmployerTermsPage = ({ currentPlan, employerInfo, onContinue }: TermsPageProps) => {
  const [currentPlanType, setCurrentPlanType] = useState({});
  const [pending, setPending] = useState(false);

  const {
    handleSubmit,
    register,
    formState: { errors, dirtyFields },
    trigger
  } = useForm({
    defaultValues: {
      signedBy: ""
    },
    resolver: zodResolver(
      z.object({
        signedBy: z
          .string({ required_error: "Please enter a full name." })
          .nonempty("Please enter a full name."),
        terms: z.literal(true, {
          errorMap: () => ({
            message: "You must accept the terms & conditions.",
          }),
        }),
      }),
    ),
  });

  let termsText =
    "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.";
  useEffect(() => {
    if (currentPlan === "single") {
      setCurrentPlanType({
        type: "single",
        title: "Single",
        posting: "Single",
        subTitle: "Job Posting in an year",
        price: "$299",
      });
    } else if (currentPlan === "monthly") {
      setCurrentPlanType({
        type: "monthly",
        title: "Monthly",
        posting: "Three (3)",
        subTitle: "3 Job Posting in 30-day",
        price: "$599",
      });
    } else if (currentPlan === "annual") {
      setCurrentPlanType({
        type: "annual",
        title: "Annual",
        posting: "",
        subTitle: "",
        price: "",
      });
    }
  }, [currentPlan])

  async function saveEmployerTerms(data: any) {
    setPending(true);
    await updateEmployerNew(employerInfo?.employerId ?? "gJE8tjW3RPplQ2oS4Ztd", {signedBy: data.signedBy})
      .then(() => {
        toast.success("Form Signed");
        onContinue(employerInfo)
      })
      .catch(() => {
        toast.error("There was an error updating Data");
      })
      .finally(() => setPending(false));
  }

  return (
    <div className="max-w-2xl mx-auto p-6  overflow-y-scroll no-scrollbar  bg-white">
      <div className="min-w-[686px] w-auto h-[250px] rounded-[15px] bg-gradient-to-r from-[#0B619A] to-[#4DAAE8] flex-1 flex flex-col text-[#FFFFFF] p-[20px]">
        <div className="flex flex-row justify-between items-center">
          <p className="font-[Gilroy-Bold] font-[400] text-[20px] leading-[24.76px]">
            {currentPlanType.posting}{" "}
            <span className="font-[Gilroy-Medium] font-[400] text-[20px] leading-[24.26px] ">
              Jobs posting per month
            </span>
          </p>
        </div>

        <div className="flex flex-col flex-1 gap-[10px] font-[Gilroy-Regular] font-[400] text-[12px] leading-[14.4px] tracking-wider mt-[20px]">
          <BulletTextWrapper>
            Includes {currentPlanType.posting} 30-day job postings
          </BulletTextWrapper>
          <BulletTextWrapper>
            Jobs must be used within each 30- day period
          </BulletTextWrapper>
          <BulletTextWrapper>
            Includes employer profile and logo
          </BulletTextWrapper>
          <BulletTextWrapper>
            Candidates are directed to your ATS
          </BulletTextWrapper>
          {currentPlanType.type !== "single" ? (
            <BulletTextWrapper>
              Automatic renewal and billing, cancel anytime
            </BulletTextWrapper>
          ) : (
            <div></div>
          )}
        </div>
        <p className="mt-[10px] mb-[10px] font-[Gilroy-Heavy] font-[400] text-[25px] leading-[14.4px] tracking-wider">
          {currentPlanType.price}
        </p>
        <p className="mt-[10px] mb-[30px] font-[Gilroy-Regular] font-[400] text-[12px] leading-[14.4px] tracking-wider">
          All packages include direct ATS apply, IT Marketplace badge for
          company website and analytics
        </p>
      </div>
      <h2 className="text-2xl font-bold text-left mt-10 mb-5 text-[#15415E]">
        Terms & Conditions
      </h2>
      <form
        className="space-y-8"
        onSubmit={handleSubmit(saveEmployerTerms)}
        autoComplete="off"
      >
        <div>
          <textarea
            readOnly={true}
            className={cn(
              "peer w-full h-36 p-4 text-gray-700 text-opacity-60 bg-[#F4F7FA] rounded-lg shadow-sm border border-transparent focus:border-[#15415E] focus:ring-0 placeholder-gray-400 resize-none",
              {
                "cursor-not-allowed": true,
              },
            )}
            value={termsText}
          />
        </div>
        {/* Terms and Conditions */}
        <div className="relative mb-7  items-start">
          <div className="flex items-center h-5">
            <input
              type="checkbox"
              name="terms"
              ref={register({
                required: "Please agree to our terms of service.",
              })}
              className="h-4 w-4 text-[#15415E] focus:ring-gray-500 border-gray-600 rounded"
            />

            <label htmlFor="terms" className="text-[#616161] ml-3 text-sm">
              By creating an account, you agree to our{" "}
              <a
                href="https://instantteams.com/terms-and-conditions/"
                target="_blank"
                className="text-[#15415E] underline"
                rel="noreferrer"
              >
                Terms
              </a>{" "}
              and have read and acknowledge our{" "}
              <a
                href="https://instantteams.com/privacypolicy/"
                target="_blank"
                className="text-[#15415E] underline"
                rel="noreferrer"
              >
                Privacy Policy
              </a>
              .
            </label>
          </div>
          {dirtyFields.terms && errors.terms && (
            <p className="mt-1 text-sm text-left text-red-600">
              {errors.terms.message}
            </p>
          )}
        </div>
        <div className="relative mb-7">
          <input
            type="text"
            name="signedBy"
            placeholder="" // Empty placeholder for floating effect
            ref={register({
              required: "Please enter your full name.",
            })}
            //onChange={async () => await trigger("signedBy")}
            onBlur={async () => await trigger("signedBy")}
            className="peer mt-1 block w-full px-3 inp py-2 rounded-none sm:text-sm"
            style={{ boxShadow: "none" }}
          />
          <label
            htmlFor="signedBy"
            className="absolute -top-4 text-sm text-[#15415E] bg-white px-1 transition-all duration-200 peer-placeholder-shown:top-2 peer-placeholder-shown:text-[#15415E] peer-placeholder-shown:text-sm peer-focus:-top-4 peer-focus:text-[#15415E] peer-focus:text-sm"
          >
            Signed By <span className="text-red-600">*</span>
          </label>
          {dirtyFields.signedBy && errors.signedBy && (
            <p className="mt-1 text-sm text-left text-red-600">
              {errors.signedBy.message}
            </p>
          )}
        </div>
        {/* Submit Button */}
        <div className="flex justify-center">
          <button
            type="submit"
            className={`py-2 px-8 text-white font-medium rounded-2xl focus:outline-none focus:ring-2 bg-[#15415E]`}
            style={{ width: "200px" }}
            disabled={pending}
          >
            {pending ? (
              <div className="flex items-center justify-center space-x-2">
                <svg
                  className="animate-spin h-5 w-5 text-white"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <circle
                    className="opacity-25"
                    cx="12"
                    cy="12"
                    r="10"
                    stroke="currentColor"
                    strokeWidth="4"
                  ></circle>
                  <path
                    className="opacity-75"
                    fill="currentColor"
                    d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4z"
                  ></path>
                </svg>
              </div>
            ) : (
              "Continue"
            )}
          </button>
        </div>
      </form>
    </div>
  );
};

export default EmployerTermsPage;
