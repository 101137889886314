import React from 'react';
import { ButtonHTMLAttributes } from 'react';
import { Children } from 'react';

const ButtonWrapper = ({ children, onClick }: { children: any, onClick: () => void }) => {
    return (
        <button onClick={onClick} className='bg-instant-teams-blue-Main rounded-full py-3 px-10 text-white '>
            {children}
        </button>
    );
}

export default ButtonWrapper;
