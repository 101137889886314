import { useMemo } from "react";
import { cn } from "../../../../../../util/cn";
import ExclamationCloudSVG from "../../../../../custom-ui/icons/ExclamationCloudSVG";
import moment from "moment";

type TCancelMonthlyPlanModalProps = {
  onCancel: () => void;
  onProceed: () => void;
  employer: any;
};

const CancelMonthlyPlanModal = ({
  onCancel,
  onProceed,
  employer,
}: TCancelMonthlyPlanModalProps) => {
  const anniversaryDate = useMemo(() => {
    if (employer?.lastCreditAdded?.seconds) {
      return moment(employer?.lastCreditAdded?.seconds, "X")
        .add(30, "d")
        .format("DD MMM YYYY");
    }

    return null;
  }, [employer]);

  return (
    <div className={cn("flex flex-col items-center overflow-hidden", {})}>
      <div className="flex flex-col gap-[20px] items-center">
        <ExclamationCloudSVG />

        <p className="w-[330px] font-[Gilroy-Bold] font-[400] text-[24px] leading-[29.71px] text-[#15415E] text-center">
          Are you sure you want to cancel your subscription?
        </p>

        <ul className="space-y-2 list-none text-[#616161] font-[Gilroy-Medium] font-[400] text-[16px] leading-[23px]">
          <li className="relative pl-5">
            <span className="absolute left-0 top-0">•</span>
            Your current plan benefits will remain active until{" "}
            {anniversaryDate} and no credits will be removed.
          </li>
          <li className="relative pl-5">
            <span className="absolute left-0 top-0">•</span>
            After {anniversaryDate}, you will not receive additional credits, &
            your subscription will not renew.
          </li>
          <li className="relative pl-5">
            <span className="absolute left-0 top-0">•</span>
            This action is irreversible.
          </li>
        </ul>
      </div>

      <div className="flex flex-row justify-center items-center gap-[15px] mt-[30px]">
        <button
          className="flex justify-center items-center h-[60px] w-[200px] rounded-[70px] border-[1px] border-[#15415E] py-[25px] px-[60px] hover:border-[#1d5174] hover:bg-slate-200 text-[#15415E] text-[20px] leading-[24.76px] font-[400] font-[Gilroy-Bold]"
          onClick={onCancel}
        >
          Cancel
        </button>
        <button
          className="flex justify-center items-center h-[60px] w-[200px] py-[25px] px-[60px] rounded-[70px] bg-[#ED3535] text-[#FFFFFF] text-[20px] leading-[24.76px] font-[400] font-[Gilroy-Bold]"
          onClick={onProceed}
        >
          Proceed
        </button>
      </div>
    </div>
  );
};

export default CancelMonthlyPlanModal;
